import { Typography, Box, Table, TableContainer, TableBody, Button, CircularProgress, IconButton, MenuItem, Tooltip, TablePagination, Paper, useTheme, Menu } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import TableHeader from '../../components/common/TableHeader'
import { exportAdmissionExamParticipation } from '../../utils/exam-utilities'
import noParticipationImg from '../../assets/no-participation.svg'
import BlockMessage from '../../components/common/BlockMessage'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Icons } from '../../utils/utilities'
import api from '../../service/api'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { Upload } from '@mui/icons-material'
import TryAgainMessageBlock from '../../components/common/TryAgainMessageBlock'
import warningImg from '../../assets/warning.svg'
import { useUserStore } from '../../store/user.store'
import ParticipationAttemptRow from '../../components/admission-exam/ParticipationAttemptRow'
import CustomSearchBar from '../../components/common/CustomSearchBar'

export default function AdmissionExamParticipation() {

    const { id } = useParams()
    const [page, setPage] = useState( 0 )
    const [exam, setExam] = useState( null )
    const [error, setError] = useState( null )
    const [loading, setLoading] = useState( true )
    const [sortState, setSortState] = useState( {} )
    const [rowsPerPage, setRowsPerPage] = useState( 25 )
    const [accessDenied, setAccessDenied] = useState( false )
    const [hasPermission, setHasPermission] = useState( true )
    const [participations, setParticipations] = useState( [] )
    const [attemptsMenuOpen, setAttemptsMenuOpen] = useState( false )
    const [filteredParticipations, setFilteredParticipations] = useState( [] )


    const navigate = useNavigate()
    const { palette, border, table } = useTheme()

    const UserStore = useUserStore()

    const attemptsMenuRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams( { selectedAttempt: "0", searchText: "" } )
    const selectedAttempt = searchParams.get( 'selectedAttempt' ) || "0"
    const searchText = searchParams.get( 'searchText' ) || ""

    const setSearchParamValue = ( key, val ) => {
        const newParams = new URLSearchParams( searchParams )
        newParams.set( key, val )
        setSearchParams( newParams, { replace: true } )
    }

    const handleAttemptChange = ( val = selectedAttempt, data = participations, changeValue = true ) => {
        if ( changeValue )
            setSearchParamValue( 'selectedAttempt', val )
        setAttemptsMenuOpen( false )
    }

    const sortByProperty = ( property ) => {
        let newFilteredParticipations = [...filteredParticipations]
        newFilteredParticipations.sort( ( a, b ) => {
            if ( typeof a[property] == "number" ) {
                if ( !sortState[property] )
                    return ( a[property] - b[property] )
                else
                    return ( b[property] - a[property] )
            } else {
                if ( !sortState[property] )
                    return ( ( a[property] < b[property] ) ? -1 : 0 )
                else
                    return ( ( a[property] > b[property] ) ? -1 : 0 )
            }
        } )
        setSortState( { ...sortState, [property]: sortState[property] ? false : true } )
        setFilteredParticipations( newFilteredParticipations )
    }

    const filterFunc = item => {
        if ( searchText?.trim() === "" )
            return true
        const searchTextRegExp = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        return item.student_id?.match( searchTextRegExp ) || item.student_name?.match( searchTextRegExp )
    }

    const removeFilters = () => {
        const newParams = new URLSearchParams( searchParams )
        newParams.set( 'searchText', "" )
        newParams.set( 'selectedAttempt', "0" )
        setSearchParams( newParams, { replace: true } )
    }

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage )
    }

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( parseInt( event.target.value, 10 ) )
        setPage( 0 )
    }

    const getData = useCallback( async () => {
        try {
            setLoading( true )
            const { data: participationData } = await api.admissionExams.fetchExamParticipation( id )
            setParticipations( participationData.participations )

            setExam( participationData.exam )
            setError( null )
        } catch ( err ) {
            console.log( err )
            if ( err?.response?.status === 403 || err?.response?.status === 401 )
                setAccessDenied( true )
            else
                setError( { code: err?.response?.status, message: err?.response?.data?.message || err?.response?.data || "Something went wrong, try again later!" } )
        }
        finally {
            setLoading( false )
        }
    }, [id] )

    useEffect( () => {
        if ( selectedAttempt === 'latest' ) {
            const groupByID = participations.reduce( ( dict, item ) => {
                if ( dict[item.student_id] ) {
                    if ( parseInt( dict[item.student_id].exam_participation_attempt ) < item.exam_participation_attempt )
                        dict[item.student_id] = item
                } else
                    dict[item.student_id] = item
                return dict
            }, {} )
            setFilteredParticipations( Object.values( groupByID ) )
        } else if ( selectedAttempt === 'best' ) {
            const groupByID = participations.reduce( ( dict, item ) => {
                if ( dict[item.student_id] ) {
                    if ( parseInt( dict[item.student_id].exam_participation_score ) < item.exam_participation_score )
                        dict[item.student_id] = item
                } else
                    dict[item.student_id] = item
                return dict
            }, {} )
            setFilteredParticipations( Object.values( groupByID ) )
        } else if ( +selectedAttempt !== 0 ) {
            const filteredList = participations.filter( p => String( p.exam_participation_attempt ) === String( selectedAttempt ) )
            setFilteredParticipations( filteredList )
        } else if ( +selectedAttempt === 0 ) {
            setFilteredParticipations( participations || [] )
        }
    }, [selectedAttempt, participations] )


    useEffect( () => {
        if ( UserStore.getUser.user_role === 'subadmin' && !UserStore.getUser.user_permissions.admission_exams ) {
            setHasPermission( false )
        }
    }, [UserStore] )

    useEffect( () => {
        getData()
    }, [getData] )


    const columns = [
        {
            name: <Box width="fit-content" display="flex" gap="10px" alignItems="center">
                Candidate ID <IconButton onClick={() => { sortByProperty( "student_id" ) }} sx={{ color: "inherit", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText" } }} size='small'>
                    {sortState.student_id ? <ArrowDownwardIcon sx={{ fontSize: "14px" }} /> : <ArrowUpwardIcon sx={{ fontSize: "14px" }} />}
                </IconButton>
            </Box>, color: "white", minWidth: "130px", align: "left"
        },
        {
            name: <Box width="fit-content" display="flex" gap="10px" alignItems="center">
                Candidate name <IconButton onClick={() => { sortByProperty( "student_name" ) }} sx={{ color: "inherit", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText" } }} size='small'>
                    {sortState.student_name ? <ArrowDownwardIcon sx={{ fontSize: "14px" }} /> : <ArrowUpwardIcon sx={{ fontSize: "14px" }} />}
                </IconButton>
            </Box>, color: "white", minWidth: "150px", align: "left"
        },
        { name: "attempt", color: "white", width: "10px", align: "right" },
        { background: "#f18f08", color: "white", name: "Status", minWidth: "150px" },
        {
            background: "#f18f08", minWidth: "80px", color: "white", name: <Box justifyContent="flex-start" display="flex" gap="10px" alignItems="center">
                Score <IconButton onClick={() => { sortByProperty( "exam_participation_score" ) }} sx={{ color: "inherit", width: "20px", height: "20px", "&:hover": { background: "#eee", color: "GrayText" } }} size='small'>
                    {sortState.exam_participation_score ? <ArrowDownwardIcon sx={{ fontSize: "14px" }} /> : <ArrowUpwardIcon sx={{ fontSize: "14px" }} />}
                </IconButton>
            </Box>, align: "right", padding: "10px 20px"
        },
        { name: "Violations", minWidth: "150px" },
        { name: "Remaining Time", minWidth: "180px" },
        { name: "", align: "right", padding: "10px 40px 10px 20px", styles: { right: "0", zIndex: 20, boxShadow: "-5px 0px 5px 0px #DBDBDBAB" } }
    ]

    return (
        hasPermission ? <Box minHeight="0" display="flex" flexDirection="column" padding="20px" overflow="auto" flexGrow={1} minWidth="0">


            {accessDenied && <BlockMessage actions={[{ label: "Back to Participations", action: () => navigate( `/faculty/participations` ) }]} type='error' message='You do not have access to view this exam information.' />}

            {/* {attemptForRemark && <SubmitOrEditRemarkDialog getData={getData} mode={remarkMode} attempt={attemptForRemark} setAttempt={setAttemptForRemark} anchor={remarkDialogAnchor} setAnchor={setRemarkDialogAnchor} />} */}

            {!accessDenied && !error &&
                <BreadcrumbList items={[
                    { link: "/admin/assessment", label: "Pre-Post assessments" },
                    { link: `/admin/assessment/${id}`, label: exam ? exam.exam_name : <CircularProgress sx={{ color: "textSecondary" }} size={12} /> },
                    "Participations"
                ]} />}

            {!accessDenied && <Paper sx={{ minHeight: !loading && "350px", flexGrow: !loading && !error && 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                {exam && <Box gap="20px" flexWrap="wrap" sx={{ background: `linear-gradient(233.69deg, #6A6790 -93.75%, #1F1E2A 145.73%)` }} padding="20px" display="flex" alignItems={{ lg: "center", md: "center", sm: "center", xs: "flex-start" }} justifyContent="space-between" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} >
                    <Box>
                        <Typography color="white" variant='h5'>{exam.exam_name} </Typography>
                        <Typography color="white" variant='body2'>Participations</Typography>
                    </Box>
                    {participations && participations.length > 0 && <Box display="flex" flexDirection={{ lg: "row", md: "row", sm: "row", xs: "column" }} gap="20px" width={{ lg: "fit-content", md: "fit-content", sm: "fit-content", xs: "100%" }} >
                        <CustomSearchBar width='330px' placeholder='Search students by AUID or name' style={{ marginRight: "0" }} value={searchText} onChange={e => setSearchParamValue( 'searchText', e.target.value )} />
                        <Button ref={attemptsMenuRef} onClick={() => setAttemptsMenuOpen( true )} endIcon={Icons.default.KeyboardArrowDownIcon} variant='contained' disableElevation sx={{ background: `linear-gradient(125.39deg, #139CFF -32.54%, #C54097 110.4%)`, minWidth: "170px" }} >
                            <Typography variant='subtitle2' color="white">{selectedAttempt === "0" ? "All attempts" : `Attempt: ${selectedAttempt}`}</Typography>
                        </Button>
                        <Menu anchorEl={attemptsMenuRef.current} open={attemptsMenuOpen} onClose={() => setAttemptsMenuOpen( false )}>
                            <MenuItem onClick={() => handleAttemptChange( "0" )} sx={{ fontSize: "14px" }} value={"0"}>All attempts</MenuItem>
                            <MenuItem onClick={() => handleAttemptChange( "best" )} sx={{ fontSize: "14px" }} value={"0"}>Best attempt</MenuItem>
                            <MenuItem onClick={() => handleAttemptChange( "latest" )} sx={{ fontSize: "14px" }} value="latest">Latest attempt</MenuItem>
                            {new Array( parseInt( Math.min( 10, exam.maximum_attempts ) ) ).fill( 1 ).map( ( e, index ) => (
                                <MenuItem onClick={() => handleAttemptChange( index + 1 )} sx={{ fontSize: "14px" }} key={index} value={index + 1}>Attempt - {index + 1}</MenuItem>
                            ) )}
                        </Menu>

                        <Tooltip title="Export participation data as MS Excel file">
                            <Button disableElevation onClick={() => exportAdmissionExamParticipation( filteredParticipations )} sx={{ minWidth: "170px", width: { sm: "150px", xs: "100%" } }} variant='contained' color='customThemeColor' endIcon={<Upload />}>
                                Export All
                            </Button>
                        </Tooltip>
                    </Box>}
                </Box>}
                {!loading && !error && participations && participations.length > 0 &&
                    <Box margin="20px" border={border[1]} borderRadius="5px" bgcolor={palette.contentBg} flexGrow={1} display="flex" flexDirection="column" minHeight="300px" overflow="hidden">
                        {filteredParticipations.filter( filterFunc ).length > 0 && <TableContainer className='custom-scrollbar' sx={{ flexGrow: 1, overflow: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Table stickyHeader>
                                <TableHeader columns={columns} />
                                <TableBody>
                                    {filteredParticipations.filter( filterFunc )
                                        .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                        .map( ( participation, index ) => {
                                            return (
                                                <ParticipationAttemptRow fetchParticipations={getData} exam={exam} participation={participation} key={participation.participation_id} index={index} columns={columns} />
                                            )
                                        } )}
                                </TableBody>
                            </Table>
                        </TableContainer >}
                        {filteredParticipations.filter( filterFunc ).length > 0 && <TablePagination
                            sx={{ background: table.headerBg, position: "sticky", bottom: 0, right: 0, left: 0, minHeight: "55px" }}
                            rowsPerPageOptions={[25, 50, 75, 100, 150]}
                            component="div"
                            className='no-scrollbar'
                            count={filteredParticipations.filter( filterFunc ).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />}
                        {filteredParticipations.filter( filterFunc ).length === 0 && <Box height="100%" flexGrow={1} padding="20px" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" display="flex" flexDirection="column" gap="10px" justifyContent={'center'} alignItems="center">
                            <img width="200px" src={noParticipationImg} alt="No participtions" />
                            <Typography padding="20px" variant='subtitle2' >No participants for applied filters!</Typography>
                            <Button startIcon={Icons.default.FilterAltOff} sx={{ textTransform: "capitalize" }} variant='outlined' color='error' onClick={removeFilters} >Remove filter</Button>
                        </Box>}
                    </Box>
                }
                {!loading && !error && participations.length === 0 && <Box height="100%" flexGrow={1} padding="20px" bgcolor={palette.contentBg} margin="10px" borderRadius="5px" display="flex" flexDirection="column" gap="10px" justifyContent={'center'} alignItems="center">
                    <img width="200px" src={noParticipationImg} alt="No participtions" />
                    <Typography padding="20px" variant='subtitle2' >No participants yet!</Typography>
                </Box>}
                {loading && !error && <DataLoadingSpinner waitingMessage="Loading exam participations..." />}
                {error && <TryAgainMessageBlock imageHeight='250px' err={error?.message} code={error.code} loading={loading} getData={getData} />}
            </Paper >}
        </Box > : <Box minHeight="0" display="flex" flexDirection="column" overflow="auto" flexGrow={1} minWidth="0">
            <Paper sx={{ display: "flex", justifyContent: "center", padding: "20px", gap: "20px", alignItems: "center" }}>
                <img src={warningImg} width="40px" alt="No permission" />
                <Typography variant='subtitle2' color="textSecondary">You doesn't have permission to access this page!</Typography>
            </Paper>
        </Box>
    )
}
