import { useOrganizationStore } from '../../store/organization.store'
import { useState, useEffect } from 'react'
import { Box, FormControl, MenuItem, Select, FormHelperText, Typography, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

const SelectInstitute = ( { onSelect, padding = "0 15px", variant = 'standard', styles = {}, marginType = "none", fullWidth = false } ) => {
    const OrganizationStore = useOrganizationStore()

    const [instituteCode, setInstituteCode] = useState( '0' )
    const [isLoading, setIsLoading] = useState( true )

    const handleChange = ( event ) => {
        setInstituteCode( event.target.value )
        onSelect( event.target.value )
    }

    useEffect( () => {
        const getOrganizations = async () => {
            await OrganizationStore.fetchOrganization()
            setIsLoading( false )
        }
        getOrganizations()
    }, [OrganizationStore] )

    return (
        <Box sx={{ padding: padding, ...styles }}>
            <FormControl fullWidth={fullWidth} margin={marginType}>
                {/* {variant && <InputLabel sx={{ fontSize: "16px" }}>Select Institute</InputLabel>} */}
                <Select size='small' disabled={isLoading} onChange={handleChange} sx={{ fontSize: "14px" }} variant={variant} value={instituteCode} >
                    <MenuItem sx={{ fontSize: "14px" }} value="0">{isLoading ? <Typography variant='body2'> <CircularProgress size={14} /> Loading institutes...</Typography> : "Select an institute"}</MenuItem>
                    {
                        OrganizationStore.getOrganization.map( ( obj, i ) => {
                            return <MenuItem sx={{ fontSize: "12px" }} value={obj.institute_name_short} key={'key-' + i}> {obj.institute_name} </MenuItem>
                        } )
                    }
                </Select>
                <FormHelperText sx={{ marginLeft: "0" }}>Select the instituteCode to check analytics...</FormHelperText>
            </FormControl>
        </Box>
    )
}

SelectInstitute.propTypes = {
    onSelect: PropTypes.func.isRequired,
}

export default SelectInstitute