import { Box, FormControlLabel, TextField, Typography, useTheme, Button, IconButton, Tooltip, CircularProgress, Checkbox, Collapse, Drawer } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomSwitch from '../../common/CustomSwitch'
import { DownloadOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { attachmentThumbnails, Icons } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import { useParams } from 'react-router-dom'
import fileDownload from 'js-file-download'
import DocumentViewer from '../../common/DocumentViewer'

const ChecklistItem = ( { item, studentData, getData } ) => {

    const { palette, border } = useTheme()
    const [values, setValues] = useState( {} )
    const [invalidFields, setInvalidFields] = useState( {} )
    const [downloading, setDownloading] = useState( false )
    const [loadingAttachment, setLoadingAttachment] = useState( false )
    const [resetting, setResetting] = useState( false )
    const [submitting, setSubmitting] = useState( false )
    const [completed, setCompleted] = useState( false )
    const [previewFile, setPreviewFile] = useState( null )
    const [previewingFile, setPreviewingFile] = useState( null )
    const [collapseState, setCollapseState] = useState( false )
    const [removingAttachment, setRemovingAttachment] = useState( false )
    const [noChangesMade, setNoChangesMade] = useState( true )

    const { student_auid } = useParams()

    const reset = async () => {
        setResetting( true )
        try {
            if ( studentData?.id ) {
                await api.resetChecklistData( { id: studentData?.id } )
                setInvalidFields( {} )
                await getData()
            } else {
                setValues( {} )
                setInvalidFields( {} )
                setCompleted( false )
            }
            setNoChangesMade( true )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while clearing the checklist data" )
        } finally {
            setResetting( false )
        }
    }

    const validateAndSubmit = async () => {
        setSubmitting( true )
        try {
            if ( item.input_required ) {
                if ( ( item.input_type === "boolean" && ( !values['textInput'] || values['textInput']?.trim() === "" ) ) || ( ["text", "number"].includes( item.input_type ) && ( !values['input'] || values['input']?.trim() === "" ) ) ) {
                    setInvalidFields( prev => {
                        return { ...prev, input: "Text input is required" }
                    } )
                    setSubmitting( false )
                    return toast( "Please fill all the required fields" )
                }
            }
            if ( item.attachment_required ) {
                if ( !values['attachment'] ) {
                    setInvalidFields( prev => {
                        return { ...prev, attachment: "Attachment is required" }
                    } )
                    setSubmitting( false )
                    return toast( "Please fill all the required fields" )
                }
            }
            if ( values.is_completed || completed ) {
                if ( item.input_type === "boolean" && values.input !== true ) {
                    setInvalidFields( prev => {
                        return { ...prev, switch: true }
                    } )
                    return toast( "Must check the switch to mark as completed!" )
                }
                if ( ["text", "number"].includes( item.input_type ) && ( !values.input || values.input?.trim() === "" ) ) {
                    setInvalidFields( prev => {
                        return { ...prev, input: "Text input is required" }
                    } )
                    return toast( "Must provide text input to mark as completed!" )
                }
            }
            const allData = { student_id: student_auid, checklist_id: item.id, rule_id: item.academic_rule_id, is_completed: completed, values: JSON.stringify( values ) }
            if ( values.attachment && typeof values.attachment !== 'string' ) {
                allData['attachment'] = values.attachment
            }
            const formData = new FormData()
            for ( const key in allData ) {
                if ( Object.hasOwnProperty.call( allData, key ) ) {
                    const val = allData[key]
                    formData.append( key, val )
                }
            }
            await api.submitChecklistStudentData( item.id, formData )
            await getData()
            setSubmitting( false )
            setInvalidFields( {} )
            toast( "Updated proctee data successfully" )
            setNoChangesMade( true )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while saving the student data" )
        } finally {
            setSubmitting( false )
        }
    }

    const removeAttachment = async ( value ) => {
        setRemovingAttachment( true )
        try {
            if ( typeof value === 'string' ) {
                const { data } = await api.removeStudentAttachment( { id: studentData.id, key: value } )
                if ( data.success )
                    setValues( prev => {
                        delete prev.attachment
                        return prev
                    } )
            }
        } catch ( err ) {
            toast( "Error occured while removing attachment!" )
        } finally {
            setRemovingAttachment( false )
        }
    }

    const downloadAttachment = async ( key ) => {
        if ( !downloading && key ) {
            try {
                setDownloading( true )
                const { data } = await api.downloadAcademicsStudentDataAttachment( key )
                fileDownload( data, key?.split( "/" )?.pop() )
            } catch ( err ) {
                console.log( err )
                const reader = new FileReader()
                reader.onload = function ( event ) {
                    const textDecoder = new TextDecoder()
                    const decodedText =
                        textDecoder.decode( event.target.result )
                    const jsonData = JSON.parse( decodedText )
                    toast( jsonData?.message || "Something went wrong while downloading the attachment!" )
                }
                reader.readAsArrayBuffer( err?.response?.data )
            } finally {
                setDownloading( false )
            }
        }
    }

    const viewAttachment = async ( key, name ) => {
        if ( key ) {
            try {
                setLoadingAttachment( true )
                const { data } = await api.downloadAcademicsStudentDataAttachment( key )
                const file = new File( [data], name )
                setPreviewFile( file )
                setPreviewingFile( name )
            } catch ( err ) {
                console.log( err )
            } finally {
                setLoadingAttachment( false )
            }
        }
    }

    useEffect( () => {
        setValues( studentData?.value || {} )
        setCompleted( studentData?.is_completed || false )
    }, [studentData] )

    return (
        <Box borderRadius="5px"
            // border={`2px dotted ${palette.primary.light}`}
            display="flex"
            flexDirection="column"
            maxWidth="700px"
        >
            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor={palette.cardBackground} flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={border[1]} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFile}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={() => { setPreviewFile( null ); setPreviewingFile( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box padding="20px" display="flex" flexDirection="column" overflow="auto" flexGrow={1} height="300px">
                        <DocumentViewer loadingText="Loading attachment..." setFile={setPreviewFile} file={previewFile} type={previewingFile?.split( "." ).pop()} />
                    </Box>
                </Box>
            </Drawer>

            <Box padding="15px 25px" bgcolor={collapseState ? palette.primary.light + "44" : palette.primary.light + "22"} display="flex" justifyContent="space-between" alignItems="center" gap="10px"  >
                <Box onClick={() => setCollapseState( !collapseState )} flexGrow={1} display="flex" alignItems="center" gap="10px">
                    <Typography variant='subtitle2'>{item.input_label}</Typography>
                    {item.is_required && <Typography color="customThemeColor.main" fontSize="10px" fontWeight="800">(REQUIRED)</Typography>}
                </Box>
                {['boolean'].includes( item.input_type ) && <Box>
                    <FormControlLabel onChange={( e ) => { setValues( { ...values, input: e.target.checked } ); setNoChangesMade( false ) }} label={<Typography variant='subtitle2'>Completed</Typography>} checked={values["input"] ? true : false} control={<CustomSwitch color='success' sx={{ border: invalidFields["switch"] && "1px solid red", borderRadius: "20px" }} />} />
                    {invalidFields["switch"] && <Typography color="error" fontSize="10px" fontWeight="bold" >Required</Typography>}
                </Box>}
                <Box marginRight="10px" width="30px" sx={{ aspectRatio: 1, cursor: "pointer" }} display="flex" alignItems="center" justifyContent="center" borderRadius="5px" height="30px" bgcolor={palette.greyedOut} onClick={() => setCollapseState( !collapseState )} >{collapseState ? <KeyboardArrowUp fontSize='small' /> : <KeyboardArrowDown fontSize='small' />}</Box>
            </Box>
            <Collapse in={collapseState}>
                <Box border={border[1]} borderTop="none" display="flex" flexDirection="column" gap="10px" padding="10px">
                    {item.has_attachment && <Box>
                        {!values['attachment'] && <Box borderRadius="5px" border={border[1]} borderColor={palette.errorMessage.dark} display="flex" gap="10px" alignItems="center" sx={{ color: palette.errorMessage.main }} padding="10px">
                            {Icons.default.Warning}
                            <Typography variant='subtitle2' color="errorMessage.dark">Student has not yet submitted the required document!</Typography>
                        </Box>}
                        {values['attachment'] && <Box>
                            <Box borderRadius="5px" padding="5px" border="2px dotted #d3d3d3" bgcolor={palette.greyedOut} display="flex" alignItems="center" gap="10px">
                                <Box marginTop="5px">
                                    <img style={{ objectFit: "fit" }} width="35px" src={attachmentThumbnails[typeof values['attachment'] === 'string' ?
                                        values['attachment']?.split( "." )?.pop() :
                                        values['attachment'].name?.split( "." )?.pop()
                                    ] || attachmentThumbnails["default"]} alt={values['attachment']} />
                                </Box>
                                <Typography fontStyle="italic" flexGrow="1" variant="subtitle2">
                                    {typeof values['attachment'] === 'string' ?
                                        values['attachment']?.split( "/" )?.pop() :
                                        values['attachment'].name
                                    }
                                </Typography>
                                {values['attachment'] && typeof values['attachment'] === 'string' && <Tooltip title="View">
                                    <IconButton disabled={loadingAttachment || downloading || removingAttachment} onClick={() => viewAttachment( values['attachment'], values['attachment']?.split( "/" )?.pop() )} color='secondary' size='small'>
                                        {loadingAttachment ? <CircularProgress size={14} /> : Icons.default.VisibilityIcon}
                                    </IconButton>
                                </Tooltip>}
                                {values['attachment'] && typeof values['attachment'] === 'string' && <Tooltip title="download">
                                    <IconButton disabled={loadingAttachment || downloading || removingAttachment} onClick={() => downloadAttachment( values['attachment'] )} color='secondary' size='small'>
                                        {downloading ? <CircularProgress size={14} /> : <DownloadOutlined />}
                                    </IconButton>
                                </Tooltip>}
                                {!completed && <Tooltip title="Remove this attachment">
                                    <IconButton disabled={removingAttachment || loadingAttachment || downloading} onClick={() => removeAttachment( values['attachment'] )} color="error" size="small">
                                        {removingAttachment ? <CircularProgress size={16} /> : Icons.default.CloseIcon}
                                    </IconButton>
                                </Tooltip>}
                            </Box>
                        </Box>}
                    </Box>}
                    {item.input_required && ['number', "text"].includes( item.input_type ) && <Box>
                        <TextField value={values['input'] || ""} onChange={( e ) => { setValues( { ...values, input: e.target.value } ); setNoChangesMade( false ) }} inputProps={{ sx: { padding: "0px", fontSize: "14px" } }} fullWidth multiline rows={2} placeholder='Answer goes here...' type={item.input_type} />
                        {invalidFields['input'] && <Typography color="error" fontSize="10px" fontWeight="bold" >{invalidFields['input']}</Typography>}
                    </Box>}
                    {item.input_required && !( ['number', "text"].includes( item.input_type ) ) && <Box>
                        <TextField value={values['textInput'] || ""} onChange={( e ) => { setValues( { ...values, textInput: e.target.value } ); setNoChangesMade( false ) }} inputProps={{ sx: { padding: "0px", fontSize: "14px" } }} fullWidth multiline rows={2} placeholder='Answer goes here...' type={item.input_type} />
                        {invalidFields['textInput'] && <Typography color="error" fontSize="10px" fontWeight="bold" >{invalidFields['input']}</Typography>}
                    </Box>}
                    <FormControlLabel onChange={( e ) => { setCompleted( !completed ); setNoChangesMade( false ) }} label="Mark as completed" checked={completed ? true : false} control={<Checkbox />} />
                    {( !noChangesMade || values['input'] || values['textInput'] ) && <Box display="flex" alignItems="center" gap="10px" marginTop="30px" marginBottom="10px">
                        {!noChangesMade && <Button disabled={submitting || resetting} startIcon={submitting && <CircularProgress size={14} />} onClick={validateAndSubmit} disableElevation variant="contained" sx={{ textTransform: "capitalize" }}>{submitting ? "Submitting..." : "Submit"}</Button>}
                        {( values['input'] || values['textInput'] || values['attachment'] || !noChangesMade ) && <Button disableElevation disabled={submitting || resetting} startIcon={resetting && <CircularProgress size={14} />} onClick={reset} color="error" variant="contained" sx={{ textTransform: "capitalize" }}>{resetting ? "Clearing data..." : "Clear"}</Button>}
                    </Box>}
                </Box>
            </Collapse>
        </Box>
    )
}

export default ChecklistItem
