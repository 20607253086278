import { Box, Button, Menu, MenuItem, Paper, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { Link } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { observer } from 'mobx-react'
import { ArrowDropDown } from '@mui/icons-material'

const Subjects = observer( () => {

    const [selectedYear, setSelectedYear] = useState( 1 )
    const [selectedSem, setSelectedSem] = useState( 1 )
    const [semesters, setSemesters] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [userRole, setUserRole] = useState( null )
    const [subjects, setSubjects] = useState( null )
    const [menuAnchor, setMenuAnchor] = useState( null )
    const [searchText, setSearchText] = useState( "" )

    const { palette, theme, shadows } = useTheme()
    const UserStore = useUserStore()


    const handleSemesterChange = val => {
        if ( UserStore.getUser.user_role === "STUDENT" ) {
            let year = Math.ceil( val / 2 )
            year = year === 0 ? 1 : year
            setSelectedYear( year )
        }
        setSelectedSem( val )
        setMenuAnchor( null )
    }

    useEffect( () => {
        const getData = async () => {
            await UserStore.fetchUserSubjects()
            try {
                setUserRole( UserStore.getUser.user_role )
                setSubjects( UserStore.getUserSubjects )
                if ( UserStore.getUser.user_role === "STUDENT" ) {
                    const years = Object.keys( UserStore.getUserSubjects )
                    setSelectedSem( UserStore.getUser.current_sem )
                    setSelectedYear( UserStore.getUser.current_year )
                    let sems = []
                    for ( let i = 0; i < years.length; i++ ) {
                        const year = years[i]
                        sems.push( ...Object.keys( UserStore.getUserSubjects[year] ) )
                    }
                    setSemesters( sems )
                } else {
                    let years = {}
                    let yearsList = []
                    for ( let i = UserStore.getUserSubjects.length - 1; i >= 0; i-- ) {
                        const sub = UserStore.getUserSubjects[i]
                        if ( years[sub.ac_year] )
                            continue
                        yearsList.push( sub.ac_year )
                        years[sub.ac_year] = true
                    }
                    setSemesters( yearsList )
                    setSelectedSem( yearsList.at( -1 ) )
                }
            } catch ( err ) {
                console.log( err )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [UserStore] )
    return (
        <Box padding="20px" display="flex" overflow="auto" flexDirection="column" flexGrow={1}>
            <BreadcrumbList items={["Courses", "All Subjects"]} />

            <Paper sx={{
                flexGrow: !loading ? 1 : "initial",
                minHeight: "400px",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                background: theme === 'dark' ? palette.common.bg : `linear-gradient(180deg, #FBF4E4 0%, #FFFFFF 100%)`
            }}>
                <Box padding="20px" display="flex" flexDirection="column" gap="20px"
                    sx={{
                        position: 'relative',
                        background: `url('/imgs/subjects-header-bg.jpg')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box height="100%" position="absolute" sx={{ zIndex: 0, inset: "0", background: `linear-gradient(46.37deg, #000000 -3.17%, rgba(0, 0, 0, 0) 150.03%)`, }}></Box>
                    <Box position="relative" sx={{ zIndex: 10 }} >
                        <Typography variant="h5" color="white">Your Subjects</Typography>
                        <Typography variant="subtitle2" color="white">Manage your subjects.</Typography>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        <CustomSearchBar value={searchText} placeholder='Search subjects...' border='1px solid #d3d3d3' onChange={e => setSearchText( e.target.value )} />
                        <Button onClick={e => setMenuAnchor( e.currentTarget )} disableElevation variant="contained" color="customThemeColor" sx={{ display: "flex", alignItems: "center", gap: "40px" }}  >
                            <Typography variant="subtitle2">{UserStore.getUser.user_role === "STUDENT" ? "Semester" : "Academic Year"} {selectedSem}</Typography>
                            <ArrowDropDown />
                        </Button>

                        <Menu open={Boolean( menuAnchor )} anchorEl={menuAnchor} onClose={() => setMenuAnchor( null )} >
                            {semesters.map( ( sem, i ) => (
                                <MenuItem
                                    onClick={() => handleSemesterChange( sem )}
                                    selected={sem === selectedSem}
                                    dense
                                    sx={{ fontSize: "14px" }}
                                    value={sem}
                                    key={sem}
                                >{UserStore.getUser.user_role === "STUDENT" ? "Semester" : "Academic Year"} {sem}</MenuItem>
                            ) )}
                        </Menu>
                    </Box>
                </Box>
                {loading && <DataLoadingSpinner waitingMessage="Loading subject please wait..." />}
                {
                    !loading && <Box flexGrow={1} className="custom-scrollbar" overflow="auto">

                        {userRole && userRole === "STUDENT" && subjects &&
                            UserStore.getUserSubjects[selectedYear] && UserStore.getUserSubjects[selectedYear][selectedSem] && UserStore.getUserSubjects[selectedYear][selectedSem].length &&
                            <Box
                                display="grid" overflow="hidden" padding="20px" gap="10px" gridTemplateColumns={{ lg: "repeat(3,1fr)", sm: "1fr 1fr", xs: "1fr" }}
                            >
                                {UserStore.getUserSubjects[selectedYear][selectedSem]
                                    .filter(
                                        ( s ) =>
                                            s.subject_name
                                                ?.toLowerCase()
                                                ?.includes( searchText.toLowerCase() ) ||
                                            s.subject_code
                                                ?.toLowerCase()
                                                ?.includes( searchText.toLowerCase() )
                                    )
                                    .map( ( sub ) => {
                                        return (
                                            <Link className='default-link' key={sub.subject_id} to={`/student/subject/${sub.subject_id}/home`} >
                                                <Box
                                                    overflow="hidden"
                                                    gap='10px'
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            boxShadow: shadows[1],
                                                            background: theme === 'dark' ? palette.grey[800] : palette.grey[100]
                                                        },
                                                    }}
                                                    padding='10px'
                                                    display="flex"
                                                    alignItems='center'
                                                    borderRadius="5px"
                                                    bgcolor={theme === 'dark' ? palette.greyedOut : palette.common.bg}
                                                >
                                                    <Typography padding="5px" borderRadius="5px" border={`1px solid #FF7039`} textTransform="uppercase" sx={{ width: "50px", aspectRatio: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        {sub.subject_name.split( " " ).map( c => c.length > 4 ? c[0] : "" ).join( "" )}
                                                    </Typography>
                                                    <Box minWidth="0" overflow="hidden">
                                                        <Typography variant='subtitle2' textTransform="capitalize">{sub.subject_name} ({sub.subject_code})</Typography>
                                                        <Typography fontSize="12px">{sub.subject_name_short}</Typography>
                                                    </Box>
                                                </Box>
                                            </Link>
                                        )
                                    } )}
                                {UserStore.getUserSubjects[selectedYear][selectedSem]
                                    .filter(
                                        ( s ) =>
                                            s.subject_name
                                                ?.toLowerCase()
                                                ?.includes( searchText.toLowerCase() ) ||
                                            s.subject_code
                                                ?.toLowerCase()
                                                ?.includes( searchText.toLowerCase() )
                                    ).length === 0 && <Typography height="100%" padding="0 20px" flexGrow={1} variant='subtitle2' display="flex" alignItems="center" justifyContent="center">No subject matches the search text: <strong><em>{searchText}</em></strong></Typography>}
                            </Box>

                        }
                        {UserStore.getUser.user_role !== 'STUDENT' &&
                            UserStore.getUserSubjects.length > 0 &&
                            <Box display="grid" padding="20px" gap="10px" gridTemplateColumns={{ lg: "repeat(3,1fr)", sm: "1fr 1fr", xs: "1fr" }} >
                                {( UserStore.getUserSubjects || [] )
                                    .filter(
                                        ( s ) => s.ac_year === selectedSem &&
                                            ( s.subject_name
                                                ?.toLowerCase()
                                                ?.includes( searchText.toLowerCase() ) ||
                                                s.subject_code
                                                    ?.toLowerCase()
                                                    ?.includes( searchText.toLowerCase() ) )
                                    )
                                    .map( ( sub ) => (
                                        <Link className='default-link' key={sub.subject_id} to={`/faculty/subject/${sub.subject_id}/home`}>
                                            <Box
                                                overflow="hidden"
                                                gap='10px'
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        boxShadow: shadows[1],
                                                        background: theme === 'dark' ? palette.grey[800] : palette.grey[100]
                                                    },
                                                }}
                                                padding='10px'
                                                display="flex"
                                                alignItems='center'
                                                borderRadius="5px"
                                                bgcolor={theme === 'dark' ? palette.greyedOut : palette.common.bg}
                                            >
                                                <Typography borderRadius="5px" border={`1px solid #FF7039`} textTransform="uppercase" sx={{ width: "50px", aspectRatio: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    {sub.subject_name.split( " " ).map( c => c.length > 4 ? c[0] : "" ).join( "" )}
                                                </Typography>
                                                <Box>
                                                    <Typography variant='subtitle2' textTransform="capitalize">{sub.subject_name} ({sub.subject_code})</Typography>
                                                    <Typography fontSize="12px">{sub.subject_name_short}</Typography>
                                                </Box>
                                            </Box>
                                        </Link>
                                    ) )}

                            </Box>}
                        {UserStore.getUser.user_role !== 'STUDENT' && UserStore.getUserSubjects
                            .filter(
                                ( s ) => s.ac_year === selectedSem &&
                                    ( s.subject_name
                                        ?.toLowerCase()
                                        ?.includes( searchText.toLowerCase() ) ||
                                        s.subject_code
                                            ?.toLowerCase()
                                            ?.includes( searchText.toLowerCase() ) )
                            ).length === 0 && <Typography padding="0 20px" flexGrow={1} variant='subtitle2' display="flex" gap="10px" alignItems="center" justifyContent="center">No subject matches the search text:   <strong><em>{searchText}</em></strong></Typography>}

                    </Box>
                }
            </Paper >
        </Box >
    )
} )

export default Subjects
