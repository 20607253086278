import { feedDefaultThumbs, getTimePassedFrom } from '../../utils/utilities'
import { Avatar, Box, Card, Paper, Skeleton, Typography, useTheme } from '@mui/material'
import { useSubjectStore } from '../../store/subject.store'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import api from '../../service/api'
import { useUserStore } from '../../store/user.store'
import { DownloadOutlined } from '@mui/icons-material'
import { useAssignmentStore } from '../../store/assignment.store'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'

const getActionStatement = ( feed ) => {
    switch ( feed.action ) {
        case "create": return <span>Created new <strong>{feed.type}</strong></span>
        case "addresource": return <span>Added new resources</span>
        case "post": return <span>Posted new <strong>{feed.type}</strong></span>
        case "commentpost": return <span>Commented on <strong>{feed.item_details?.discussion?.title || "discussion"}</strong></span>
        default: return "New post"
    }
}

const ImageElement = ( { src, alt } ) => {

    const [loading, setLoading] = useState( true )

    const handleImageLoad = e => {
        setLoading( false )
    }

    return <Box maxWidth="420px" width="100%" >
        {loading && <Skeleton variant='rectangular' width="100%" height={"200px"} />}
        <img src={src} width={!loading ? "100%" : "0"} height={loading ? "0" : "auto"} loading='lazy' onLoad={handleImageLoad} alt={alt} />
    </Box>
}


const SubjectFeeds = observer( () => {
    const [loading, setLoading] = useState( true )
    const [feeds, setFeeds] = useState( [] )

    const SubjectStore = useSubjectStore()
    const UserStore = useUserStore()
    const AssignmentStore = useAssignmentStore()

    const { palette } = useTheme()


    const getResourceLink = ( { type, id, action, item_details, subject, user_id } ) => {
        const user = UserStore.getUser.user_role === "STUDENT" ? 'student' : 'faculty'
        if ( UserStore.getUser.user_role === "FACULTY" && ['live session', "exam", 'quiz', "study material"].includes( type ) && UserStore.getUser.user_id !== user_id )
            return ""
        switch ( type ) {
            case "exam":
                return `/${user}/exams/${item_details.id}`

            case "quiz":
                if ( user === 'student' )
                    return `/student/quiz?quiz_id=${item_details.quiz}&instance_id=${item_details.id}`
                return `/faculty/subject/${subject.subject_id}/quiz/${item_details.quiz}`

            case "discussion":
                if ( action === "commentpost" )
                    return `/${user}/discussions/${item_details?.discussion?.id ? `${item_details?.discussion?.id}#${id}` : ""}`
                return `/${user}/discussions/${item_details.id}`

            case "study material":
                return `/${user}/material/${item_details.id}`

            case 'live session':
                return `/${user}/subject/${SubjectStore.getSubject.subject_id}/class-sessions/${item_details.id}`

            default:
                return `/${user}/subject/${SubjectStore.getSubject.subject_id}/assignments?searchText=&assignmentFilter=due`
        }
    }

    const downloadAssignmentAttachment = ( value ) => {
        AssignmentStore.downloadAssignment( value )
        toast( <span>Downloading <b style={{ color: palette.customThemeColor.main }}>{value.split( "." )[value.split( "." ).length - 2].replace( /%20/g, " " )}</b> please wait... </span>, { position: "bottom-left" } )
    }

    useEffect( () => {
        const getFeeds = async () => {
            try {
                const { data: { data } } = await api.fetchSubjectFeed( SubjectStore.getSubject.subject_id )
                let facultiesList = []
                for ( const feed of data ) {
                    facultiesList.push(
                        {
                            name: feed.user_data.name,
                            id: feed.id,
                            type: feed.type,
                            action: feed.action
                        }
                    )
                }
                setFeeds( data )
            } catch ( err ) {
                console.log( err?.response?.data?.message || "Couldn't fetch the subject feeds!" )
            } finally {
                setLoading( false )
            }
        }
        getFeeds()
    }, [SubjectStore] )

    return (
        <Box flexGrow={1} padding="20px" bgcolor="greyedOut" overflow="auto" display="grid" gridTemplateColumns={{ md: "auto 370px", xs: "1fr" }} alignItems="flex-start" gap="20px" >

            {!loading && <Box className="slim-custom-scrollbar" display="flex" height="100%" alignItems="center" flexDirection="column" overflow="auto" gap="10px" flexGrow={1}>
                {feeds.length > 0 && feeds.map( ( feed, index ) => {

                    const link = getResourceLink( feed )

                    return (
                        <Card id={`feed-${feed.id}`} key={feed.id} sx={{ flexShrink: 0, overflow: "hidden", width: "100%", padding: "0" }}>
                            <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap="10px" borderBottom={`1px solid ${palette.borderColor}`} padding="15px 20px" >
                                <Box display="flex" alignItems="center" gap="10px">
                                    <Avatar src={feed?.user_data?.user_profile_image_url}>{( feed.user_data.name || feed.user_data.employee_name )[0]}</Avatar>
                                    <Box>
                                        <Typography fontSize="16px" ><strong>{feed.user_data.user_id === UserStore.getUser.user_id ? "You" : feed.user_data.name || feed.user_data.employee_name}</strong>  </Typography>
                                        <Typography fontSize="12px" variant='body2' color="GrayText" >{getActionStatement( feed )}</Typography>
                                    </Box>
                                </Box>
                                <Typography variant='body2' fontSize="12px">{getTimePassedFrom( feed.created_at )}</Typography>

                            </Box>
                            <Link to={link} target={link && "_blank"} className='default-link'>
                                {( feed.item_details?.title || feed.item_details?.name || feed.item_details?.comment ) && <Box padding="10px">
                                    {feed.type !== "discussion" && <Typography sx={{ '&:hover': { textDecoration: "underline" } }} noWrap gutterBottom variant='subtitle2'>{feed.item_details?.title || feed.item_details?.name || ""}</Typography>}
                                    {feed.type === "discussion" && feed.action === "commentpost" && <Typography color="GrayText" noWrap gutterBottom variant='subtitle2'>Commenting to {feed.item_details?.discussion?.title || "discussion"}</Typography>}
                                    {feed.type === "discussion" && feed.action !== "commentpost" && <Typography sx={{ '&:hover': { textDecoration: "underline" } }} noWrap gutterBottom variant='h6'>{feed.item_details?.title || feed.item_details?.name || ""}</Typography>}
                                    {feed.type === "discussion" && feed.action !== "commentpost" && <Box sx={{ pointerEvents: "none !important" }} padding="10px" borderRadius="5px" bgcolor={palette.greyedOut} className='discussion-wysiwyg-container' >
                                        <Box dangerouslySetInnerHTML={{ __html: feed.item_details?.comment || feed.item_details?.content || "" }}></Box>
                                    </Box>}
                                    {feed.type === "discussion" && feed.action === "commentpost" && <Box sx={{ pointerEvents: "none !important" }} padding="10px" borderRadius="5px" bgcolor={palette.greyedOut} className='discussion-wysiwyg-container' >
                                        <Box dangerouslySetInnerHTML={{ __html: feed.item_details?.comment || feed.item_details?.content || "" }}></Box>
                                    </Box>}
                                    <Typography noWrap variant='body2' >{feed.item_details?.description}</Typography>
                                    {feed.item_details?.attachments?.length > 0 && <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center" marginY="10px">
                                        {feed.item_details?.attachments?.map( ( att, index ) => {
                                            return (
                                                <Box onClick={() => downloadAssignmentAttachment( att.location || att )} sx={{ '&:hover': { borderStyle: "dashed" }, cursor: "pointer" }} padding="5px 20px" borderRadius="20px" border={`1px solid ${palette.secondary.dark}`} bgcolor={palette.secondary.light + "22"} key={index}>
                                                    <Typography display="flex" gap="10px" flexWrap="wrap" alignItems="center" variant="subtitle2" color="secondary">{att.name || att?.split( "/" ).pop()?.split( "." ).slice( 0, -1 )?.join( "-" )?.replace( /%\d+/g, " " )} <DownloadOutlined fontSize="small" /> </Typography>
                                                </Box>
                                            )
                                        } )}
                                    </Box>}
                                    {feed.item_details?.topics?.length > 0 && <Box display="flex" gap="10px" flexWrap="wrap" alignItems="center" marginY="10px">
                                        {feed.item_details?.topics?.map( ( topic, index ) => {
                                            return (
                                                <Box sx={{ cursor: "pointer" }} padding="5px 20px" borderRadius="20px" border={`1px solid ${palette.secondary.dark}`} bgcolor={palette.secondary.light + "22"} key={index}>
                                                    <Typography display="flex" gap="10px" flexWrap="wrap" alignItems="center" variant="subtitle2" color="secondary">{topic.name} </Typography>
                                                </Box>
                                            )
                                        } )}
                                    </Box>}
                                </Box>}
                                {feed.type !== "discussion" && <Box margin="10px" display="flex" justifyContent="center" borderRadius="5px" overflow="hidden" >
                                    {/* <img loading='lazy' width="100%" height="auto" style={{ borderRadius: "5px" }} src={feed.thumbnail ? feed.thumbnail : feedDefaultThumbs[feed.type][Math.random() > 0.5 ? 0 : 1]} alt={feed.item_details?.name} /> */}
                                    <ImageElement src={feed.thumbnail ? feed.thumbnail : feedDefaultThumbs[feed.type][+feed.id % feedDefaultThumbs[feed.type].length]} alt={feed.item_details?.name} />
                                </Box>}
                            </Link>
                        </Card>
                    )
                } )}
                {!loading && feeds.length === 0 && <Card sx={{ flexGrow: 1, width: "100%", }} variant='subtitle2'>
                    <Typography padding="30px" variant="subtitle2" textAlign="center">No feeds to show</Typography>
                </Card>}

            </Box>}
            {loading &&
                <Paper sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1, height: "100%" }}>
                    <DataLoadingSpinner waitingMessage="Loading subject feeds..." />
                </Paper>
            }


            <Card sx={{ height: "100%", overflow: "auto", alignItems: loading ? "center" : "initial", flexGrow: 1, display: { md: "flex", xs: "none" }, flexDirection: "column", justifyContent: "center", width: "100%", maxWidth: "500px" }} >
                {!loading && feeds.length > 0 && <Box overflow="auto" display="flex" flexGrow={1} alignItems="center" justifyContent="center" flexDirection="column" className="slim-custom-scrollbar" position="relative" padding="20px">
                    <Typography position="sticky" top='0' width="100%" variant='subtitle2'>Posts</Typography>
                    <Box width="100%" flexGrow={1} className="slim-custom-scrollbar" display="flex" flexDirection="column" overflow="auto">
                        {feeds.length > 0 && feeds.map( feed => (
                            <a className="default-link" href={`#feed-${feed.id}`} key={feed.id}>
                                <Box width="100%" sx={{}} display="flex" gap="10px" padding="10px 0">
                                    <Avatar src={feed.user_data.user_profile_image_url}>{( feed.user_data.name || feed.user_data.employee_name )[0]}</Avatar>
                                    <Box>
                                        <Typography variant='subtitle2'>{feed.user_data.name || feed.user_data.employee_name}</Typography>
                                        <Typography variant='body2' fontSize="12px" color="textSecondary">{getActionStatement( feed )}</Typography>
                                    </Box>
                                </Box>
                            </a>
                        ) )}
                    </Box>
                </Box>}
                {!loading && feeds.length === 0 && <Card sx={{ flexGrow: 1, width: "100%", }} variant='subtitle2'>
                    <Typography padding="30px" variant="subtitle2" textAlign="center">No posts</Typography>
                </Card>}
                {loading && <DataLoadingSpinner waitingMessage="Loading feed list..." />}
            </Card>

        </Box>
    )
} )

export default SubjectFeeds
