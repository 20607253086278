import { Box, Button, Checkbox, CircularProgress, Dialog, FormControl, FormControlLabel, IconButton, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import { useParams } from 'react-router-dom'
import { Icons, leadingZeroFormatter, secondsToHMS, showDateInIndianDateFormat } from '../../../utils/utilities'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useUserStore } from '../../../store/user.store'
import { AccessTimeFilledOutlined, RefreshOutlined } from '@mui/icons-material'
import api from '../../../service/api'
import { toast } from 'react-toastify'

const CreateOrEditQuizInstanceDialog = observer( ( { state = false, setState, instance, mode = 'create' } ) => {

    const [isLoadingBatches, setIsLoadingBatches] = useState( false )
    const [academicYearId, setAcademicYearId] = useState( '-' )
    const [isFetchingClasses, setIsFetchingClasses] = useState( false )
    const [associateWithClass, setAssociateWithClass] = useState( true || Boolean( instance?.class_id ) || false )
    const [creating, setCreating] = useState( false )
    const [classes, setClasses] = useState( [] )
    const { id, subject_id } = useParams()
    const QuizStore = useQuizAndQuestionbankStore()
    const UserStore = useUserStore()

    const { palette, border } = useTheme()


    const initialValues = {
        start_date: instance ? new Date( instance.start_date ) : new Date(),
        start_time: instance?.start_time || '00:00',
        end_time: instance?.end_time || '00:00',
        access_group: instance?.access_group || '-',
        quiz_duration: instance?.quiz_duration || '',
        room_id: instance?.class_id || '',
    }

    const validationSchema = Yup.object().shape( {
        start_date: Yup.date().required( "Quiz date is required" ),
        access_group: Yup.mixed().required( "Please specify an access group" ).notOneOf( ["-"], "Please specify an access group" ),
        start_time: Yup.string().required( "Quiz start time is required" ).test( {
            message: "Please select valid meeting start date and time", name: "future_time", test: ( val, { parent } ) => {
                try {
                    const dateTime = new Date( parent.date ).toISOString().split( "T" )[0] + "T" + val
                    if ( new Date( dateTime ) > new Date() )
                        return true
                    return false
                } catch ( err ) {
                    return true
                }
            }
        } ),
        end_time: Yup.string().required( "Quiz end time is required" ).test( {
            message: "Please select valid end time", name: "valid_end_time", test: ( val, { parent } ) => {
                try {
                    const refDate = new Date().toISOString().split( "T" )[0]
                    const endTime = refDate + "T" + val
                    const startTime = refDate + "T" + parent.start_time
                    if ( !parent.start_time ) return false
                    if ( new Date( startTime ) < new Date( endTime ) )
                        return true
                    return false
                } catch ( err ) {
                    return true
                }
            }
        } ),
        quiz_duration: Yup.string().test( "CHECK_FOR_QUIZ_DURATION", "Please enter a valid duration. (format: HH:MM)", ( val, { parent } ) => {
            const splitted = val?.split( ":" )
            if ( !val ) return true
            if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 || +splitted[0] >= 24 ) )
                return false
            return true
        } ).test( "CHECK_FOR_CORRECT_QUIZ_DURATION", "Duration cannot exceed the set start and end time window", ( val, { parent } ) => {
            if ( val ) {
                const splitted = val?.split( ":" )
                const dateObject = new Date( parent.start_date )
                const dateStr = `${dateObject.getFullYear()}-${dateObject.getMonth()}-${dateObject.getDate()}`
                const selectedDuration = ( new Date( `${dateStr} ${parent.end_time}` ).getTime() - new Date( `${dateStr} ${parent.start_time}` ).getTime() ) / 1000
                if ( val && ( !val.match( /^\d\d:\d\d$/ ) || +splitted[1] > 60 ) ) {
                    return false
                } else {
                    if ( val === '00:00' )
                        return false
                    const timeInSeconds = ( +splitted[0] * 60 * 60 ) + splitted[1] * 60
                    if ( typeof selectedDuration === 'number' && selectedDuration >= timeInSeconds ) {
                        return true
                    }
                    else return false
                }
            } else
                return true
        }
        ),
        room_id: Yup.string( "To associate with a class, A class should be selected!" ).test( "FOR_ROOM_ID", "To associate with a class, A class should be selected!", val => {
            if ( !associateWithClass ) return true
            if ( val ) return true
            return false
        } ),
    } )

    const handleAcademicYearChange = async ( e ) => {
        setIsLoadingBatches( true )
        setAcademicYearId( e.target.value )
        await UserStore.fetchUserBatchesAndSection( e.target.value )
        setIsLoadingBatches( false )
    }

    const refreshYears = async () => {
        await UserStore.fetchUserAcademicYears( true )
    }

    const refreshBatches = async () => {
        if ( academicYearId !== "-" )
            await UserStore.fetchUserBatchesAndSection( academicYearId, true )
    }

    const handleTimeChange = ( name, val, setValue ) => {
        const time = new Date( val ).toLocaleTimeString( 'en-IN', { hourCycle: "h24", hour: "2-digit", minute: "2-digit", } )
        setValue( name, time )
    }


    const handleAccessGroupChange = ( val, setValue ) => {
        let access = ( UserStore.getUserBatchesAndSection['batches'] || [] ).find( i => i.batch_assignment_id === val )
        let id = ( UserStore.getUserBatchesAndSection['batches'] || [] ).find( i => i.batch_assignment_id === val )?.batch_assignment_id
        if ( !access ) {
            access = ( UserStore.getUserBatchesAndSection["sections"] || [] ).find( i => i.section_assignment_id + "-" + i.section_short_name === val )
            id = `${access.section_assignment_id}-${access.section_short_name}`
        }
        setValue( 'access_group', { ...access, id } )
    }

    const toggleClassAssociation = async ( e, date, setField ) => {
        const { checked } = e.target
        let reqDate = new Date( date )
        reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`
        if ( checked ) {
            setIsFetchingClasses( true )
            try {
                let { data: { data } } = await api.getClassesByDate( reqDate )
                setClasses( ( data || [] ).filter( room => room.subject_id === subject_id ) )
            } catch ( err ) {
                toast( "Error occured while fetching classes" )
            } finally {
                setIsFetchingClasses( false )
            }
        } else {
            setField( 'room_id', "" )
            setField( 'access_group', "-" )
            setClasses( [] )
        }
        setAssociateWithClass( checked )
    }

    const handleStartDateChange = async ( val, setFieldValue ) => {
        setFieldValue( 'start_date', val )
        setFieldValue( 'room_id', "" )
        if ( associateWithClass ) {
            const subjectId = subject_id || QuizStore.getQuiz?.details?.subject?.subject_id
            setIsFetchingClasses( true )
            try {
                let reqDate = new Date( val )
                reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`

                let { data: { data } } = await api.getClassesByDate( reqDate )
                setClasses( ( data || [] ).filter( room => room.subject_id === subjectId ) )
            } catch ( err ) {
                toast( "Error occured while fetching classes" )
            } finally {
                setIsFetchingClasses( false )
            }
        }
    }

    const handleRoomChange = ( e, setField ) => {
        setField( 'room_id', e.target.value )
        const room = classes.find( r => r.time_table_id === e.target.value )
        setField( 'end_time', room.eTime )
        setField( 'start_time', room.sTime )
        setField( 'access_group', room.batch_short_name !== "NA" ?
            { course_name_short: room.course_name_short, current_sem: room.current_sem, current_year: room.current_year, id: room.batch_assignment_id, batch_short_name: room.batch_short_name, batch_assignment_id: room.batch_assignment_id, branch_name: room.branch_name, branch_id: room.branch_id } :
            { course_branch_short_name: room.course_branch_short_name, current_sem: room.current_sem, current_year: room.current_year, id: `${room.section_assignment_id}-${room.section_short_name}`, section_assignment_id: room.section_assignment_id, section_short_name: room.section_short_name, branch_name: room.branch_name, branch_id: room.branch_id } )

    }

    const handleClose = ( resetForm ) => {
        resetForm()
        setState( false )
    }

    const createInstance = async ( values, { resetForm } ) => {
        setCreating( true )
        const success = await QuizStore.createQuizInstance( id, values )
        if ( success ) {
            handleClose( resetForm )
        }
        setCreating( false )
    }

    const rescheduleQuizInstance = async ( values, { resetForm } ) => {
        setCreating( true )
        const success = await QuizStore.rescheduleQuizInstance( id, instance.id, values )
        if ( success ) {
            handleClose( resetForm )
        }
        setCreating( false )
    }

    useEffect( () => {
        const getData = async () => {
            await UserStore.fetchUserAcademicYears()
            if ( instance?.class_id || subject_id ) {
                setIsFetchingClasses( true )
                const subjectId = subject_id || QuizStore.getQuiz?.details?.subject?.subject_id
                try {
                    let reqDate = new Date( instance?.start_date )
                    reqDate = `${reqDate.getFullYear()}-${leadingZeroFormatter.format( reqDate.getMonth() + 1 )}-${leadingZeroFormatter.format( reqDate.getDate() )}`

                    let { data: { data } } = await api.getClassesByDate( reqDate )
                    setClasses( ( data || [] ).filter( room => room.subject_id === subjectId ) )
                } catch ( err ) {
                    toast( "Error occured while fetching classes" )
                } finally {
                    setIsFetchingClasses( false )
                }
            }
        }
        getData()
    }, [UserStore, QuizStore, instance, subject_id] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "950px", background: palette.form.formCardBg } }} open={state}>
            <Formik onSubmit={mode === 'edit' ? rescheduleQuizInstance : createInstance} initialValues={initialValues} validationSchema={validationSchema}>
                {( { values, setFieldValue, resetForm } ) => {

                    let maxDuration
                    if ( values.end_time && values.start_time ) {
                        const dateObject = new Date( values.start_date )
                        const dateStr = `${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${dateObject.getDate()}`
                        const selectedDuration = ( new Date( `${dateStr} ${values.end_time}` ).getTime() - new Date( `${dateStr} ${values.start_time}` ).getTime() ) / 1000
                        maxDuration = secondsToHMS( selectedDuration )?.substring( 0, 5 )
                    }

                    return (
                        <Box >
                            <Box sx={{ zIndex: 5 }} display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="20px">
                                <Typography variant="h6" fontSize="18px">{mode === 'edit' ? "Edit Quiz Instance" : "Create Quiz instance"}</Typography>
                                <IconButton onClick={() => handleClose( resetForm )} size="small">{Icons.default.CloseIcon}</IconButton>
                            </Box>
                            <Form>
                                <Box margin="20px" marginTop="0" bgcolor={palette.form.formBg} border={border[1]} borderRadius="5px" overflow="auto" padding="0 20px 10px 20px">
                                    <FormControl margin="normal">
                                        <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Date</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                minDate={dayjs( new Date() )}
                                                disableToolbar
                                                variant="inline"
                                                format="DD-MM-YYYY"
                                                value={dayjs( values.start_date )}
                                                onChange={val => handleStartDateChange( val, setFieldValue )}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>

                                    {mode !== 'edit' && <Box display="flex" gap="10px" alignItems="center">
                                        {isFetchingClasses && <CircularProgress size={14} />}
                                        <FormControlLabel label="Associate with a class in time table" labelPlacement='end' checked={associateWithClass} onChange={e => toggleClassAssociation( e, values.start_date, setFieldValue )} control={<Checkbox />} />
                                    </Box>}

                                    {associateWithClass && <FormControl fullWidth margin='normal'>
                                        <Field
                                            size="small"
                                            as={Select}
                                            sx={{ color: !values.room_id && "#cbcbcb" }}
                                            value={values?.room_id || "-"}
                                            onChange={e => handleRoomChange( e, setFieldValue )}
                                            name="room_id"
                                            renderValue={val => {
                                                const cls = classes.find( r => r.time_table_id === val )
                                                const title = cls ? cls.batch_short_name !== "NA" ?
                                                    `${cls.batch_short_name}, Y${cls.current_year}, S${cls.current_sem} ${cls.batch_short_name}, ${cls.branch_name}` :
                                                    `${cls.course_branch_short_name}, Y${cls.current_year}, S${cls.current_sem}, Sec: ${cls.section_short_name}, ${cls.branch_name}` : ""
                                                return cls ? <Box fontSize="12px">
                                                    <Typography variant='subtitle2' fontSize="12px">{cls.subject_name_short}</Typography>
                                                    <em>{title}</em>
                                                </Box> :
                                                    <Typography sx={{ color: "#cbcbcb" }} fontSize="16px">Select Class</Typography>
                                            }}
                                        >
                                            <MenuItem value={"-"} sx={{ fontSize: "12px" }} dense disabled>{isFetchingClasses ? "Loading ..." : "Select class"}</MenuItem>
                                            {!isFetchingClasses &&
                                                classes.map( ( cls ) => {
                                                    const title = cls.batch_short_name !== "NA" ?
                                                        `${cls.batch_short_name}, Y${cls.current_year}, S${cls.current_sem} ${cls.batch_short_name}, ${cls.branch_name}` :
                                                        `${cls.course_branch_short_name}, Y${cls.current_year}, S${cls.current_sem}, Sec: ${cls.section_short_name}, ${cls.branch_name}`
                                                    return ( <MenuItem dense sx={{ fontSize: "12px" }} key={cls.time_table_id} value={cls.time_table_id}>
                                                        <Box>
                                                            <Typography variant='subtitle2' fontSize="12px">{cls.subject_name_short}</Typography>
                                                            <em>{title}</em>
                                                        </Box>
                                                    </MenuItem> )
                                                } )
                                            }
                                        </Field>
                                        {classes.length === 0 && <Typography fontSize="12px" color="textSecondary">No classes scheduled for <strong>{showDateInIndianDateFormat( values.start_date )}!</strong></Typography>}
                                        <Typography color="errorMessage.main" fontSize="12px"> <ErrorMessage name="room_id" /> </Typography>
                                    </FormControl>}

                                    <Box display="flex" flexWrap="wrap" gap="10px" alignItems="flex-start">
                                        <FormControl margin="normal">
                                            <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Start time</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    disableToolbar
                                                    value={dayjs( new Date( `2024-01-01 ${values.start_time}` ) )}
                                                    onChange={val => handleTimeChange( 'start_time', val, setFieldValue )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                        <FormControl margin="normal">
                                            <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>End time</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    sx={{ "width": "100%", fontSize: "14px", '& input': { padding: "10px" } }}
                                                    value={dayjs( new Date( `2024-01-01 ${values.end_time}` ) )}
                                                    onChange={val => handleTimeChange( 'end_time', val, setFieldValue )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Box>

                                    <Typography color="errorMessage.main" fontSize="12px"> <ErrorMessage name="start_date" /> </Typography>
                                    <Typography color="errorMessage.main" fontSize="12px"> <ErrorMessage name="start_time" /> </Typography>
                                    <Typography color="errorMessage.main" fontSize="12px"> <ErrorMessage name="end_time" /> </Typography>


                                    {( !associateWithClass && mode !== 'edit' ) && <Box marginTop="10px" display="flex" flexWrap="wrap" gap="10px" alignItems="flex-start">
                                        <FormControl margin='normal' sx={{ minWidth: "170px", width: "250px" }}>
                                            <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Select academic year</Typography>
                                            <Select size="small" MenuProps={{ slotProps: { paper: { elevation: 1 } } }} placeholder='Select an academic year' name="academicYear" value={academicYearId} onChange={handleAcademicYearChange}>
                                                <MenuItem sx={{ fontSize: "14px" }} key={"default"} value={'-'}>
                                                    Select an academic year
                                                </MenuItem>
                                                {UserStore.getUserAcademicYears.map( ( batchYear, k ) => {
                                                    return ( <MenuItem sx={{ fontSize: "14px" }} key={batchYear.ac_year_id} value={batchYear.ac_year_id}>
                                                        {batchYear.ac_year}
                                                    </MenuItem> )
                                                } )}
                                            </Select>
                                            {<Typography onClick={refreshYears} sx={{ cursor: "pointer", '&:hover': { textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color="secondary" fontWeight="500"><RefreshOutlined fontSize='16px' />Refresh academic years</Typography>}
                                        </FormControl>
                                        <FormControl margin='normal' sx={{ minWidth: "170px", width: "250px" }}>
                                            <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Select Batch / Section *</Typography>
                                            <Field
                                                size="small"
                                                MenuProps={{ slotProps: { paper: { elevation: 1 } } }}
                                                as={Select}
                                                sx={{ color: values.access_group === "-" && palette.form.placeholder }}
                                                placeholder="Select acces group"
                                                disabled={isLoadingBatches || ( UserStore.getUserBatchesAndSection["batches"]?.length === 0 && UserStore.getUserBatchesAndSection["sections"].length === 0 )}
                                                name="access_group"
                                                onChange={( e ) => handleAccessGroupChange( e.target.value, setFieldValue )}
                                                value={
                                                    values.access_group === "-" ? "-" :
                                                        values?.access_group?.batch_assignment_id ? values?.access_group?.batch_assignment_id : values?.access_group?.section_assignment_id ? values?.access_group?.section_assignment_id + "-" + values?.access_group?.section_short_name : ""
                                                }
                                            // values?.access_group?.batch_assignment_id ? values?.access_group?.batch_assignment_id : values?.access_group?.section_assignment_id ? values?.access_group?.section_assignment_id + "-" + values?.access_group?.section_short_name : "" : ""
                                            >
                                                <MenuItem value={'-'} disabled key="key" color='textSecondary' sx={{ fontSize: "14px" }}>
                                                    {isLoadingBatches ?
                                                        <Typography display="flex" alignItems="center" gap="10px" variant='subtitle2'> <CircularProgress size={14} /> Loading batches...</Typography> :
                                                        "Select batch"}
                                                </MenuItem>
                                                {UserStore.getUserBatchesAndSection["batches"]?.length !== 0 && <Typography paddingLeft="10px" color="secondary">Batches</Typography>}
                                                {
                                                    UserStore.getUserBatchesAndSection["batches"]?.map( ( batch, k ) => {
                                                        return ( <MenuItem sx={{ fontSize: "14px" }} key={batch.batch_assignment_id} value={batch.batch_assignment_id}>
                                                            {batch.batch_name + "-" + batch.course_name_short + " Y" + batch.current_year + " S" + batch.current_sem + " " + batch.batch_short_name}
                                                        </MenuItem> )
                                                    } )
                                                }
                                                {UserStore.getUserBatchesAndSection["sections"]?.length !== 0 && <Typography paddingLeft="10px" color="secondary">Sections</Typography>}
                                                {
                                                    UserStore.getUserBatchesAndSection["sections"]?.map( ( batch, k ) => {
                                                        return ( <MenuItem sx={{ fontSize: "14px" }} key={batch.section_assignment_id} value={batch.section_assignment_id + "-" + batch.section_short_name}>
                                                            {batch.course_branch_short_name + "-" + batch.course_short_name + " Y" + batch.current_year + " S" + batch.current_sem + " (" + batch.section_short_name + ")"}
                                                        </MenuItem> )
                                                    } )
                                                }
                                            </Field>
                                            {academicYearId !== "-" && <Typography onClick={refreshBatches} sx={{ cursor: "pointer", '&:hover': { cursor: "not-allowed", textDecoration: "underline" } }} display="flex" gap="5px" alignItems="center" fontSize="14px" color={"secondary"} fontWeight="500"><RefreshOutlined fontSize='16px' /> Refresh batches</Typography>}
                                            {/* {isLoadingBatches && <Typography display="flex" alignItems="center" gap="10px" variant='subtitle2' color="textSecondary"> <CircularProgress size={14} /> Loading batches...</Typography>} */}
                                        </FormControl>
                                    </Box>}
                                    <Typography fontSize="12px" color="errorMessage.main"> <ErrorMessage name="access_group" /> </Typography>

                                    <FormControl margin="normal" fullWidth>
                                        <Typography variant="subtitle2" gutterBottom color={palette.labelColor}>Quiz duration  {values.end_time && values.start_time && `(Max: ${maxDuration})`}</Typography>
                                        <Field size="small" as={TextField} placeholder="For 2 hrs quiz set value as 02:00" type="text" name="quiz_duration" />
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='quiz_duration' /></Typography>
                                    </FormControl>
                                    <Box justifyContent="flex-start" marginTop="60px" display="flex" gap="10px" flexWrap="wrap">
                                        <Button disabled={creating} type="submit" startIcon={creating ? <CircularProgress size={14} /> : mode === 'edit' ? <AccessTimeFilledOutlined /> : Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation >{mode === 'edit' ? "Reschedule" : "Create"} Instance</Button>
                                        <Button onClick={() => handleClose( resetForm )} disabled={creating} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation color="error" >Cancel</Button>
                                    </Box>
                                </Box>

                            </Form>
                        </Box>
                    )
                }}
            </Formik>
        </Dialog>
    )
} )

export default CreateOrEditQuizInstanceDialog
