import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import CustomSearchBar from '../../../components/common/CustomSearchBar'
import noQuestionBanksImg from '../../../assets/no-questions.svg'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Icons, showDateInIndianDateFormat } from '../../../utils/utilities'
import React, { useCallback, useEffect, useState } from 'react'
import AddQuestionBank from '../../../components/exams-components/quiz-questionbank/AddQuestionBank'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import { observer } from 'mobx-react'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import { useSubjectStore } from '../../../store/subject.store'
import TableHeader from '../../../components/common/TableHeader'

const columns = [
    { id: 1, name: 'TITLE', padding: "10px 20px 10px 40px" },
    { id: 2, name: 'SUBJECT & CODE' },
    { id: 3, name: 'CREATED on', minWidth: "120px" },
    { id: 4, name: 'QUESTIONS COUNT', align: 'center', minWidth: "150px" },
    { id: 5, name: 'QUIZ ASSOCIATION COUNT', align: 'center', padding: "10px 40px 10px 20px", minWidth: "180px" },
]

const QuestionBanks = observer( ( { fromMainPage = false } ) => {

    const [addMode, setAddMode] = useState( false )
    const [loading, setLoading] = useState( true )

    const [searchParams, setSearchParams] = useSearchParams( { searchText: "" } )
    const searchText = searchParams.get( 'searchText' )

    const { palette, table, border } = useTheme()

    const { subject_id } = useParams()

    const QuizStore = useQuizAndQuestionbankStore()
    const SubjectStore = useSubjectStore()

    const setSearchParamValue = ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }

    const getData = useCallback( async () => {
        await QuizStore.fetchQuestionBanks( subject_id )
        setLoading( false )
    }, [QuizStore, subject_id] )

    const filterFunc = q => {
        const matchRegExp = new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' )
        return q.name?.match( matchRegExp ) || q?.subject?.subject_name?.match( matchRegExp ) || q?.subject?.subject_code?.match( matchRegExp )
    }

    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Box padding={fromMainPage && "20px"} display="flex" flexGrow={1} overflow="auto" flexDirection="column">

            <AddQuestionBank state={addMode} subject={SubjectStore?.getSubject} setState={setAddMode} />

            <Paper elevation={0} sx={{ overflow: "hidden", minHeight: "400px", flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box sx={{ background: fromMainPage && `linear-gradient(322.12deg, #F3C400 -21.29%, #E25A26 124.22%)` }} display="flex" alignItems="center" gap="10px" padding={fromMainPage ? "20px" : "10px 20px"}>
                    <Box flexGrow="1" display="flex" alignItems="center" justifyContent="space-between" gap="20px">
                        <Box>
                            <Typography variant={fromMainPage ? 'h5' : "h6"} color={fromMainPage ? 'white' : 'primaryDark.main'} fontSize={!fromMainPage && "18px"}>Manage {SubjectStore?.getSubject?.subject_name} question banks</Typography>
                            {fromMainPage && <Typography variant="body2" color={fromMainPage ? 'white' : 'textSecondary'}>Manage your quizzes here</Typography>}
                        </Box>
                        <Box display="flex" alignItems="center" gap="10px">
                            <CustomSearchBar width='300px' border={`1px solid ${palette.borderColor}`} value={searchText} onChange={e => setSearchParamValue( 'searchText', e.target.value )} placeholder='Type question bank or subject' />
                            <Button variant="contained" disableElevation onClick={() => setAddMode( true )} startIcon={Icons.default.AddIcon} sx={{ textTransform: "capitalize", height: "40px" }}>Create</Button>
                        </Box>
                    </Box>
                </Box>
                {!loading && QuizStore.getQuestionBanks.length > 0 && <Box marginX="20px" marginTop={fromMainPage && "20px"} marginBottom="20px" borderRadius="5px" className='custom-scrollbar' flexGrow={1} border={border[1]} display="flex" flexDirection='column' overflow="auto">
                    {QuizStore.getQuestionBanks.filter( filterFunc ).length > 0 && <TableContainer className='custom-scrollbar' sx={{ minWidth: "750px", flexGrow: 1, overflow: "auto", borderRadius: "5px" }}>
                        <Table stickyHeader>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {QuizStore.getQuestionBanks.filter( filterFunc ).map( bank => (
                                    <TableRow
                                        sx={{
                                            position: "relative",
                                            '&::after': {
                                                content: `''`,
                                                background: "#d3d3d3",
                                                position: "absolute",
                                                height: "1px",
                                                width: "calc(100% - 40px)",
                                                bottom: 0,
                                                left: "50%",
                                                transform: "translateX(-50%)"
                                            },

                                            "&:hover": {
                                                background: palette.rowHover
                                            },
                                            cursor: "pointer"
                                        }} key={bank.id}>
                                        <TableCell sx={{ border: "none", padding: "0", color: table.color }} >
                                            <Link to={`${bank.id}`} className='default-link'>
                                                <Box padding={columns[0].padding || "10px 20px"}><strong>{bank.name} </strong></Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ border: "none", padding: "0", color: table.color }}>
                                            <Link to={`${bank.id}`} className='default-link' >
                                                <Box padding="10px 20px">{bank.subject ? <strong>{bank.subject?.subject_name} <br /> <small>{bank.subject?.subject_code}</small></strong> : <Typography fontSize="12px" fontWeight="500" color="errorMessage.main">[Corrupted]: Subject not specified, delete and create anew</Typography>}</Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ border: "none", padding: "0", color: table.color }}>
                                            <Link to={`${bank.id}`} className='default-link' >
                                                <Box padding="10px 20px"><strong>{showDateInIndianDateFormat( bank.created_at )}</strong></Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell align='center' sx={{ border: "none", padding: "0", color: table.color }}>
                                            <Link to={`${bank.id}`} className='default-link' >
                                                <Box padding={columns[3].padding || "10px 20px"}><strong>{bank.question_count}</strong></Box>
                                            </Link>
                                        </TableCell>
                                        <TableCell align='center' sx={{ border: "none", padding: "0", color: table.color }}>
                                            <Link to={`${bank.id}`} className='default-link' >
                                                <Box padding={columns[3].padding || "10px 20px"}><strong>{bank.quiz_association_count}</strong></Box>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>}

                    {QuizStore.getQuestionBanks.filter( filterFunc ).length === 0 && <Box flexGrow={1} display="flex" justifyContent="center" padding="20px" alignItems="center" flexDirection="column" gap="20px" bgcolor={palette.contentBg}>
                        <img src={noQuestionBanksImg} width="200px" alt="No questions" />
                        <Typography variant="subtitle2">No match found for <em><strong>'{searchText}'</strong></em>!</Typography>
                        <Button onClick={() => setSearchParamValue( 'searchText', '' )} startIcon={Icons.default.FilterAltOff} variant="outlined" color="error" sx={{ textTransform: "capitalize" }}>Remove filter</Button>
                    </Box>}
                </Box>}
                {!loading && QuizStore.getQuestionBanks.length === 0 && <Box display="flex" padding="20px" alignItems="center" flexDirection="column" gap="10px" justifyContent="center" flexGrow={1} bgcolor={palette.contentBg}>
                    <img src={noQuestionBanksImg} width="200px" alt="No questions" />
                    <Typography variant="subtitle2">No question banks!</Typography>
                    <Button onClick={() => setAddMode( true )} startIcon={Icons.default.AddIcon} variant="outlined" sx={{ textTransform: "capitalize" }}>Add new question bank</Button>
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading your question banks..." />}
            </Paper>
        </Box>
    )
}
)

export default QuestionBanks