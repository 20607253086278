import { Box, Button, IconButton, Paper, Typography, useTheme, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Tooltip, Dialog } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuizAndQuestionbankStore } from '../../../store/quiz.store'
import { observer } from 'mobx-react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Icons, flatColorsAndBackgrounds, latexDelimiters, leadingZeroFormatter, showDateInIndianDateFormat } from '../../../utils/utilities'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import QuestionImportFileInputDialog from '../../../components/exams-components/QuestionImportFileInputDialog'
import QuestionsPreviewDialog from '../../../components/exams-components/QuestionsPreviewDialog'
import CreateQuizInstanceDialog from '../../../components/exams-components/quiz-questionbank/CreateQuizInstanceDialog'
import { toast } from 'react-toastify'
import { useSubjectStore } from '../../../store/subject.store'
import api from '../../../service/api'
import ConfirmDialog from '../../../components/common/ConfirmDialog'
import InstanceBox from '../../../components/exams-components/quiz-questionbank/InstanceBox'
import { exportQuizReport } from '../../../utils/exam-utilities'
import Latex from 'react-latex-next'

const scoresColumns = [
    { name: 'NAME', align: "left", padding: "10px 20px 10px 40px" },
    { name: 'STUDENT ID', align: "left" },
    { name: 'MAX MARKS', align: "center" },
    { name: 'SCORED MARKS', align: "right", padding: "10px 40px 10px 20px" },
]

const ScoreViewer = ( { scoreDetails, setScoreDetails, loadingScoreDetails } ) => {

    const headerRef = useRef()


    const { palette, border } = useTheme()
    const [headerHeight, setHeaderHeight] = useState( '65px' )

    useEffect( () => {
        if ( scoreDetails && headerRef.current )
            setHeaderHeight( `${headerRef?.current?.offSetHeight || 65}px` )
    }, [scoreDetails] )

    return (
        <Dialog open={Boolean( scoreDetails )} PaperProps={{ className: 'slim-custom-scrollbar', sx: { width: "60%", maxWidth: "600px" } }} >
            <Box bgcolor={palette.background.paper} flexGrow={1} display="flex" flexDirection="column">
                <Box ref={headerRef} position="sticky" top="0" display="flex" alignItems="center" justifyContent="space-between" borderBottom={border[1]} padding="10px 20px" bgcolor={palette.background.paper}>
                    <Box maxHeight="45px">
                        <Typography variant="h6" fontSize="16px">{scoreDetails?.instance}</Typography>
                        <Typography variant="subtitle2" fontSize="12px">{scoreDetails?.student_details?.name}</Typography>
                    </Box>
                    <IconButton size="small" onClick={() => setScoreDetails( null )}>
                        {Icons.default.CancelIcon}
                    </IconButton>
                </Box>
                <Box >
                    <Box bgcolor={palette.contentBg} position="sticky" top={headerHeight} padding="10px 20px" display="flex" alignItems="center" gap="30px">
                        <Typography variant="h6">OVERALL SCORE</Typography>
                        <Typography variant="h6"> <strong>{scoreDetails?.answers?.total_score} / {scoreDetails?.answers?.max_score}</strong></Typography>
                    </Box>
                    <Box display="flex" gap="20px" alignItems="center" borderBottom={border[1]} padding="20px">
                        <Box display="flex" gap="10px" alignItems="center">
                            <Box sx={{ width: "15px", borderRadius: "50%", height: "15px", border: `2px solid ${palette.success.dark}` }}></Box>
                            <Typography variant='subtitle2' fontSize="12px" >Correct option</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center">
                            <Box sx={{ width: "15px", borderRadius: "50%", bgcolor: palette.success.dark, height: "15px", border: `2px solid ${palette.success.dark}` }}></Box>
                            <Typography variant='subtitle2' fontSize="12px" >Answered, correctly</Typography>
                        </Box>
                        <Box display="flex" gap="10px" alignItems="center">
                            <Box sx={{ width: "15px", borderRadius: "50%", bgcolor: palette.error.light, height: "15px", border: `2px solid ${palette.error.dark}` }}></Box>
                            <Typography variant='subtitle2' fontSize="12px" >Answered, but incorrect</Typography>
                        </Box>
                    </Box>

                    <Box display="flex" padding="0 20px 20px 20px" marginTop="30px" flexDirection="column" gap="20px">
                        {Object.keys( scoreDetails?.answers?.scores || {} ).map( ( quesId, index ) => {
                            const ques = scoreDetails?.answers?.scores[quesId]
                            return <Box borderBottom={border[1]} paddingBottom="20px" key={index}>
                                {ques.given < 0 ? <Typography padding="5px 10px" marginBottom="20px" borderRadius="5px" width="fit-content" bgcolor={palette.errorMessage.light + 22} variant='subtitle2' color="errorMessage.main"><em>Not Answered</em></Typography> :
                                    <Typography padding="5px 10px" marginBottom="20px" borderRadius="5px" width="fit-content" bgcolor={palette.primaryDark.light + 22} variant='subtitle2' color="primaryDark.main"><em>Answered</em></Typography>}
                                <Box color={palette.contrstText} className='questionbank-wysiwyg-container' fontWeight="bold" marginTop="-5px" fontSize="14px">
                                    <Latex delimiters={latexDelimiters}>{ques.question_text}</Latex>
                                </Box>
                                <Typography variant='body2' color="textSecondary"><em>{ques.selection_type}</em></Typography>
                                <Box marginTop="10px" display="flex" flexDirection="column" gap="10px">
                                    {ques.options.map( ( option, optionIndex ) => {
                                        const isCorrect = ques.selection_type === "single" ? +ques.correct_answer === optionIndex : ques.options_status[optionIndex]
                                        const hasAnswered = ques.selection_type === "single" ? +ques.given === optionIndex : ques.given.includes( optionIndex )
                                        return (
                                            <Box
                                                padding="10px"
                                                border={border[1]}
                                                borderRadius="5px"
                                                bgcolor={hasAnswered ? ( hasAnswered && isCorrect ) ? "success.dark" : "error.light" : "none"}
                                                borderColor={isCorrect ? "success.dark" : hasAnswered ? "errorMessage.dark" : palette.borderColor}
                                                sx={{ borderWidth: isCorrect || hasAnswered ? "2px" : "1px" }}
                                                display="flex"
                                                marginBottom="2px"
                                                alignItems="flex-start"
                                                gap="10px"
                                                key={optionIndex}
                                            >
                                                <Typography padding="2px 2px 0px 2px" variant="subtitle2" fontSize="12px" sx={{ aspectRatio: 1 / 1 }} bgcolor={palette.greyedOut} border={`1px solid ${palette.borderColor}`} display="flex" alignItems="center" justifyContent="center" width='20px' borderRadius="50%"
                                                    fontWeight={( isCorrect || hasAnswered ) ? "bolder" : "regular"} color={isCorrect ? "success" : hasAnswered ? "error" : palette.common.font}>{optionIndex + 1}</Typography>
                                                <Box variant="subtitle2" className='questionbank-wysiwyg-container' color={( hasAnswered ) ? "white" : palette.common.font}>
                                                    <Latex delimiters={latexDelimiters}>{option.option_text}</Latex>
                                                </Box>
                                            </Box>
                                        )
                                    } )}
                                </Box>
                            </Box>
                        } )}
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

const Quiz = observer( ( { fromComponent = 'subject' } ) => {

    const [scores, setScores] = useState( [] )
    const [loading, setLoading] = useState( true )
    const [showTopics, setShowTopics] = useState( false )
    const [deleteDialogState, setDeleteDialogState] = useState( false )
    const [deleting, setDeleting] = useState( false )
    const [isImporting, setIsImporting] = useState( false )
    const [loadingScores, setLoadingScores] = useState( false )
    const [loadingScoreDetails, setLoadingScoreDetails] = useState( false )
    const [scoreDetails, setScoreDetails] = useState( null )
    const [selectedInstanceDetails, setSelectedInstanceDetails] = useState( null )
    const [questionsPreview, setQuestionsPreview] = useState( null )
    const [questionsImportDialogState, setQuestionsImportDialogState] = useState( false )
    const [createQuizInstanceDialogState, setCreateQuizInstanceDialogState] = useState( false )

    const [searchParams, setSearchParams] = useSearchParams( { mode: "instances", selectedQuizInstance: "", searchText: "" } )
    const mode = searchParams.get( "mode" )
    const searchText = searchParams.get( "searchText" )
    const selectedQuizInstance = searchParams.get( "selectedQuizInstance" )

    const { id, subject_id } = useParams()
    const navigate = useNavigate()
    const { palette, border, table } = useTheme()

    const QuizStore = useQuizAndQuestionbankStore()
    const SubjectStore = useSubjectStore()


    const setSearchParamValue = useCallback( ( key, val ) => {
        setSearchParams( prev => {
            prev.set( key, val )
            return prev
        }, { replace: true } )
    }, [setSearchParams] )

    const fetchScoreDetails = async ( attemptDetails ) => {
        setLoadingScoreDetails( attemptDetails.attempt_id )
        try {
            let instanceTitle = ''
            if ( selectedInstanceDetails?.access_group?.section_assignment_id ) {
                instanceTitle = `${selectedInstanceDetails.access_group.course_branch_short_name ? selectedInstanceDetails.access_group.course_branch_short_name + "," : ""} ${selectedInstanceDetails.access_group?.current_year ? `year: ${selectedInstanceDetails.access_group?.current_year},` : ""} ${selectedInstanceDetails.access_group.current_sem ? `Sem: ${selectedInstanceDetails.access_group.current_sem},` : ""} Sec: ${selectedInstanceDetails.access_group.section_short_name}`
            } else
                instanceTitle = `${selectedInstanceDetails.access_group.course_name_short ? selectedInstanceDetails.access_group.course_name_short + "," : ""} ${selectedInstanceDetails.access_group?.current_year ? `year: ${selectedInstanceDetails.access_group?.current_year},` : ""} ${selectedInstanceDetails.access_group.current_sem ? `Sem: ${selectedInstanceDetails.access_group.current_sem},` : ""} Batch: ${selectedInstanceDetails.access_group.batch_short_name}`

            const { data: { data } } = await api.quiz.fetchAttemptScore( attemptDetails.quiz_id, attemptDetails.quiz_instance_id, attemptDetails.attempt_id )
            setScoreDetails( { answers: data, student_details: attemptDetails.student_details, instance: instanceTitle } )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong whie fetching scores!" )
        } finally {
            setLoadingScoreDetails( false )
        }
    }

    const viewScores = useCallback( async ( instance ) => {
        setSearchParamValue( 'selectedQuizInstance', instance.id )
        setLoadingScores( true )
        try {
            const { data: { scores } } = await api.quiz.fetchQuizInstanceScores( id, instance.id )
            setScores( scores )
            setSelectedInstanceDetails( instance )
            setSearchParamValue( 'mode', 'scores' )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "Something went wrong while fetching scores!" )
        } finally {
            setLoadingScores( false )
        }
    }, [id, setSearchParamValue] )

    const handleResultsExport = () => {
        console.log( "Hello" )
        exportQuizReport( scores, QuizStore.getQuiz?.details?.title, selectedInstanceDetails?.start_date )
    }

    const backToInstances = () => {
        setSearchParamValue( 'mode', "instances" )
        setSearchParamValue( 'selectedQuizInstance', "" )

    }

    const deleteQuiz = async () => {
        setDeleting( true )
        try {
            await api.quiz.deleteQuiz( id )
            navigate( subject_id ? `/faculty/subject/${SubjectStore.getSubject.subject_id}/quiz` : `/faculty/quizzes` )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while deleting the quiz!" )
        } finally {
            setDeleting( false )
        }
    }

    const getData = useCallback( async () => {
        const errorCode = await QuizStore.fetchQuiz( id )
        if ( errorCode )
            navigate( `/faculty/subject/${SubjectStore.getSubject.subject_id}/quiz` )
        setLoading( false )
    }, [QuizStore, id, SubjectStore, navigate] )


    useEffect( () => {
        getData()
    }, [getData] )

    return (
        <Box padding={fromComponent !== 'subject' && "20px"} flexGrow={1} display="flex" flexDirection="column" overflow="auto">

            <QuestionImportFileInputDialog setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} isImporting={isImporting} importDialogStatus={questionsImportDialogState} setImportDialogStatus={setQuestionsImportDialogState} />
            <QuestionsPreviewDialog setIsImporting={setIsImporting} getQuestions={() => { }} examId={id} parent="quiz" setQuestionsPreview={setQuestionsPreview} questionsPreview={questionsPreview} />
            <CreateQuizInstanceDialog state={createQuizInstanceDialogState} setState={setCreateQuizInstanceDialogState} />
            <ConfirmDialog message="All the associated instances and their data will be deleted!" actionName="Yes! delete it" confirmAction={deleteQuiz} waiting={deleting} status={deleteDialogState} cancelAction={() => setDeleteDialogState( false )} />
            <ScoreViewer scoreDetails={scoreDetails} setScoreDetails={setScoreDetails} loadingScoreDetails={loadingScoreDetails} />


            <Paper elevation={0} sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                {!loading && <Box display="flex" flexWrap="wrap" borderBottom="1px solid #d3d3d3" alignItems="center" justifyContent="space-between" gap="20px" padding={fromComponent === 'subject' ? "10px 20px" : "20px"} >
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap="20px">
                        {<IconButton onClick={() => navigate( subject_id ? `/faculty/subject/${SubjectStore.getSubject.subject_id}/quiz` : `/faculty/quizzes` )} size="small">
                            {Icons.default.ArrowBack}
                        </IconButton>}
                        <Box display="flex" alignItems="flex-end" flexWrap="wrap" gap="10px">
                            <Typography sx={{ '& small': { fontSize: "14px" } }} display="flex" flexDirection="column" color='primaryDark.main' variant={"h6"} fontSize={fromComponent === 'subject' && "18px"}>
                                Quiz - {QuizStore.getQuiz?.details?.title}
                                <small>{QuizStore.getQuiz?.details?.subject?.subject_name}</small>
                            </Typography>
                        </Box>
                        <Box flexGrow={1} overflow="auto" alignItems="center" className="slim-custom-scrollbar" display="flex" flexWrap="wrap" gap="10px">
                            <Link className='default-link' to={`/faculty/subject/${SubjectStore?.getSubject?.subject_id}/questionbank/${QuizStore.getQuiz.details?.question_bank}`}>
                                <Typography variant='subtitle2' padding="5px 10px" borderRadius="5px" bgcolor="#464E5B" color="white">{QuizStore.getQuiz.details?.question_bank_name}</Typography>
                            </Link>
                            {showTopics && QuizStore.getQuiz.details?.question_bank_topics?.map( ( topic, index ) => {
                                const { color, bg } = flatColorsAndBackgrounds[index % flatColorsAndBackgrounds.length]
                                return (
                                    <Tooltip key={topic.id} title={`${topic.question_count} questions`}>
                                        <Box padding="5px 10px" display="flex" gap="10px" borderRadius="5px" bgcolor={bg} color="white">
                                            <Typography variant='subtitle2' color={color}>{topic?.name}</Typography>
                                            <Typography variant='subtitle2' border={`0.5px solid ${color}`} borderRadius="5px" padding="0 5px" bgcolor="white" color={color}>{leadingZeroFormatter.format( topic.question_count )}</Typography>
                                        </Box>
                                    </Tooltip>
                                )
                            } )}
                            <Typography variant='body2' fontSize="12px" sx={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setShowTopics( !showTopics )}>{showTopics ? "Hide topics" : "View topics"}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="10px">
                        {/* <Button onClick={() => setCreateQuizInstanceDialogState( true )} variant='contained' startIcon={Icons.default.AddIcon} disableElevation sx={{ textTransform: "capitalize", width: "max-content" }} size="small" color="primary">
                            Create instance
                        </Button> */}
                        <Button variant='contained' onClick={() => setDeleteDialogState( true )} startIcon={Icons.default.DeleteIcon} disableElevation sx={{ textTransform: "capitalize", width: "max-content" }} size="small" color="error">
                            delete
                        </Button>
                    </Box>
                </Box>}
                {!loading && <Box margin="20px" border={border[1]} borderRadius="5px" bgcolor={palette.contentBg}>
                    {mode === "instances" && QuizStore.getQuiz && <Box overflow="hidden" bgcolor={palette.background.paper} borderRadius="5px">
                        <Typography color={table.headerColor} bgcolor={table.headerBg} variant='subtitle1' padding="20px">QUIZ INSTANCES</Typography>
                        <Box display="grid" gridTemplateColumns={{ lg: "1fr 1fr 1fr", md: "1fr 1fr 1fr", sm: "1fr 1fr", xs: "1fr" }} padding="20px" gap="10px" borderRadius="5px">
                            {QuizStore.getQuiz?.instances?.map( ( instance, index ) => {

                                let title = ''
                                if ( instance?.access_group?.section_assignment_id ) {
                                    title = `${instance.access_group.course_branch_short_name ? instance.access_group.course_branch_short_name + "," : ""} ${instance.access_group?.current_year ? `year: ${instance.access_group?.current_year},` : ""} ${instance.access_group.current_sem ? `Sem: ${instance.access_group.current_sem},` : ""} Sec: ${instance.access_group.section_short_name}`
                                } else
                                    title = `${instance.access_group.course_name_short ? instance.access_group.course_name_short + "," : ""} ${instance.access_group?.current_year ? `year: ${instance.access_group?.current_year},` : ""} ${instance.access_group.current_sem ? `Sem: ${instance.access_group.current_sem},` : ""} Batch: ${instance.access_group.batch_short_name}`


                                return <InstanceBox isSelected={+selectedQuizInstance === instance.id} key={index} refetchQuizInstances={getData} title={title} viewScores={viewScores} instance={instance} />
                            } )}
                            {QuizStore.getQuiz?.instances?.length === 0 && <Typography variant="subtitle2" color="GrayText">No Quiz Instances</Typography>}
                        </Box>
                    </Box>}
                    {!loading && mode === "scores" && QuizStore.getQuiz && <Box overflow="hidden" bgcolor={palette.background.paper} borderRadius="5px">
                        <Box display="flex" padding="10px 20px" bgcolor={table.headerBg} color={table.headerColor} flexWrap="wrap" gap="20px" justifyContent="space-between" alignItems="center">
                            <IconButton size="small" onClick={backToInstances}>{Icons.default.ArrowBack}</IconButton>
                            <Box flexGrow={1} >
                                {selectedInstanceDetails?.access_group?.batch_assignment_id && <Typography variant='subtitle1' >  SCORES - <em>{selectedInstanceDetails.access_group.course_name_short}, year: {selectedInstanceDetails.access_group.current_year}, sem: {selectedInstanceDetails.access_group.current_sem}, {selectedInstanceDetails.access_group.batch_short_name}</em><small>,  On: {showDateInIndianDateFormat( selectedInstanceDetails?.start_date )}</small>  </Typography>}
                                {selectedInstanceDetails?.access_group?.section_assignment_id && <Typography variant='subtitle1' >  SCORES -  <em>{selectedInstanceDetails.access_group.course_branch_short_name}, year: {selectedInstanceDetails.access_group.current_year}, Section: {selectedInstanceDetails.access_group.section_short_name}</em><small>,  On: {showDateInIndianDateFormat( selectedInstanceDetails?.start_date )}</small> </Typography>}
                            </Box>
                            <Button onClick={handleResultsExport} disableElevation variant='contained' startIcon={Icons.default.UploadFileIcon} color="secondary" sx={{ textTransform: "capitalize" }} >Export as excel</Button>
                        </Box>
                        <Box padding="20px">
                            {loadingScores && <DataLoadingSpinner waitingMessage="Loading scores..." />}
                            {!loadingScores && scores.length > 0 && <Box overflow="hidden" bgcolor={palette.contentBg} border={border[1]} borderRadius="5px" >
                                <TableContainer className='custom-scrollbar' sx={{ minWidth: "750px", maxHeight: "350px", flexGrow: 1, overflow: "auto", borderRadius: "5px" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {scoresColumns.map( column => (
                                                    <TableCell align={column.align || 'left'} sx={{ padding: column.padding || "10px 20px", bgcolor: table.headerBg, color: table.headerColor }} key={column.name}>{column.name}</TableCell>
                                                ) )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {scores.filter( s => s?.student_details?.name?.match( new RegExp( searchText?.replace( /[.*+?^${}()|[\]\\]/g, '\\$&' ), 'i' ) ) ).map( score => (
                                                <TableRow
                                                    onClick={() => fetchScoreDetails( score )}
                                                    sx={{
                                                        position: "relative",
                                                        '&::after': {
                                                            content: `''`,
                                                            background: "#d3d3d3",
                                                            position: "absolute",
                                                            height: "1px",
                                                            width: "calc(100% - 40px)",
                                                            bottom: 0,
                                                            left: "50%",
                                                            transform: "translateX(-50%)"
                                                        },

                                                        "&:hover": {
                                                            background: palette.rowHover
                                                        },
                                                        cursor: "pointer"
                                                    }} key={score.id}>
                                                    <TableCell align={scoresColumns[0].align} sx={{ border: "none", padding: scoresColumns[0].padding || "10px 20px", color: table.color }} >
                                                        <strong>{score?.student_details?.name} </strong>
                                                        {loadingScoreDetails === score.attempt_id && <DataLoadingSpinner color='secondary' waitingMessage="Loading details..." padding='0' size={14} />}
                                                    </TableCell>
                                                    <TableCell align={scoresColumns[1].align} sx={{ border: "none", padding: "10px 20px", color: table.color }}>
                                                        <strong>{score.student_id}</strong>
                                                    </TableCell>
                                                    <TableCell align={scoresColumns[2].align} sx={{ border: "none", padding: "10px 20px", color: table.color }}>
                                                        <strong> {score.max_score}</strong>
                                                    </TableCell>
                                                    <TableCell align={scoresColumns[3].align} sx={{ border: "none", padding: scoresColumns[3].padding || "10px 20px", color: table.color }}>
                                                        <strong>{score.total_score}</strong>
                                                    </TableCell>
                                                </TableRow>
                                            ) )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>}
                            {!loadingScores && scores.length === 0 && <Typography variant='subtitle2'  >
                                There are no participations for this quiz!
                            </Typography>}
                        </Box>
                    </Box>}
                </Box>}
                {loading && <DataLoadingSpinner waitingMessage="Loading your quiz..." />}
            </Paper >
        </Box >
    )
} )

export default Quiz
