import { Box, Button, CircularProgress, IconButton, MenuItem, Select, TableCell, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Icons } from '../../utils/utilities'
import api from '../../service/api'
import { toast } from 'react-toastify'
import ConfirmDialog from '../common/ConfirmDialog'

const ParticipationAttemptRow = ( { fetchParticipations, exam, participation, columns } ) => {

    const [deleting, setDeleting] = useState( false )
    const [confirmDialogState, setConfirmDialogState] = useState( false )
    const [updatingFaultsMade, setUpdatingFaultsMade] = useState( false )
    const [updatingRemainingTime, setUpdatingRemainingTime] = useState( false )
    const [updatingAttemptStatus, setUpdatingAttemptStatus] = useState( false )
    const [faultsCountValue, setFaultsCountValue] = useState( participation.faults_made )
    const [remainingTimeValue, setRemainingTimeValue] = useState( participation.remaining_time || 0 )

    const { table, palette, border } = useTheme()

    const { id } = useParams()

    const updateExamAttemptDetail = async ( val, column ) => {
        if ( column === 'faults_made' )
            setUpdatingFaultsMade( participation.faults_made )
        else if ( column === 'remaining_time' )
            setUpdatingRemainingTime( participation.remaining_time )
        else
            setUpdatingAttemptStatus( true )
        try {
            const { data } = await api.admissionExams.updateParticipationAttempt( id, participation.participation_id, { column, value: val } )
            participation[column] = val
            toast( data.message )
        } catch ( err ) {
            if ( column === 'faults_made' )
                setFaultsCountValue( participation.faults_made )
            else if ( column === 'remaining_time' )
                setFaultsCountValue( participation.remaining_time )
            console.log( err )
            toast( err?.response?.data?.message || err?.response?.data || "Something went wrong while updating the participation, please try again." )
        } finally {
            setUpdatingAttemptStatus( false )
            setUpdatingFaultsMade( false )
            setUpdatingRemainingTime( false )
        }
    }

    const deleteParticipation = async () => {
        setDeleting( true )
        try {
            const { data } = await api.admissionExams.deleteAdmissionExamParticipation( id, participation.participation_id )
            setConfirmDialogState( false )
            await fetchParticipations()
            toast( data.message )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || err?.response?.data || "Something went wrong while deleting the participation, please try again." )
        } finally {
            setDeleting( false )
        }
    }



    return (
        <TableRow className='table-row' sx={{ backgroundColor: "white", cursor: "pointer", "&:hover": { background: palette.rowHover } }}>

            <ConfirmDialog
                actionName="Yes! delete this attempt"
                status={confirmDialogState}
                confirmAction={deleteParticipation}
                cancelAction={() => setConfirmDialogState( false )}
                message={<p> <strong>{participation.student_name}</strong> ({participation.student_id}), attempt number: {participation.exam_participation_attempt} will be deleted permanently.</p>}
                waiting={deleting}
            />


            <TableCell sx={{ color: table.color, fontSize: "12px", minWidth: "100px", padding: "0" }}>
                <Box padding={columns[0].padding || "10px 20px"}>
                    <strong>{participation.student_id}</strong></Box>
            </TableCell>

            <TableCell sx={{ color: table.color, fontSize: "12px", minWidth: "100px", padding: "0" }}>
                <Box padding={columns[1].padding || "10px 20px"}>
                    <strong>{participation.student_name}</strong></Box>
            </TableCell>

            <TableCell align='center' sx={{ color: table.color, fontSize: "12px", minWidth: "80px", padding: "0" }}>
                <Box padding={columns[2].padding || "10px 20px"}>
                    <strong>{participation.exam_participation_attempt}</strong></Box>
            </TableCell>

            <TableCell sx={{ color: table.color, padding: "0" }}>
                <Tooltip placement='top' title='Before updating status make sure you have updated other required fields as other fields can only be updated while the participation is in "Completed" status'>
                    <Box padding={columns[3].padding || "10px 20px"}>
                        <Select sx={{ fontSize: "12px" }} disabled={updatingAttemptStatus} onChange={e => updateExamAttemptDetail( e.target.value, 'exam_participation_status' )} value={participation.exam_participation_status} size="small">
                            <MenuItem sx={{ fontSize: "12px" }} dense value="Not started" disabled>Not Started</MenuItem>
                            <MenuItem sx={{ fontSize: "12px" }} dense value="In progress" disabled={participation.exam_participation_status === 'Not started'} >In Progress</MenuItem>
                            <MenuItem sx={{ fontSize: "12px" }} dense value="Completed" disabled>Completed</MenuItem>
                        </Select>
                        {updatingAttemptStatus && <Typography display="flex" gap="5px" alignItems="center" fontSize="8px"> <CircularProgress size={8} /> updating...  </Typography>}
                    </Box>
                </Tooltip>
            </TableCell>

            <TableCell align='left' sx={{ color: table.color, padding: 0, minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >
                <Box padding={columns[4].padding || "10px 20px"}>{participation.exam_participation_score ? participation.exam_participation_score : "0"} out of {participation.total_marks}</Box>
            </TableCell>

            <TableCell align='left' sx={{ color: table.color, padding: 0, minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >
                <Tooltip title={"Completed" !== participation.exam_participation_status ? `Cannot update an IN PROGRESS participation, or the participation which is NOT STARTED yet!` : ""} >
                    <Box padding={columns[5].padding || "10px 20px"}>
                        <TextField disabled={"Completed" !== participation.exam_participation_status} onBlur={e => updateExamAttemptDetail( e.target.value, 'faults_made' )} value={faultsCountValue} inputProps={{ style: { fontSize: "12px", width: "60px" }, min: 0 }} onChange={e => setFaultsCountValue( e.target.value )} InputProps={{}} type='number' size='small' sx={{ fontSize: "14px" }} />
                        {updatingFaultsMade && <Typography display="flex" gap="5px" alignItems="center" fontSize="8px"> <CircularProgress size={8} /> updating...  </Typography>}
                    </Box>
                </Tooltip>
            </TableCell>


            <TableCell align='left' sx={{ color: table.color, padding: 0, minHeight: "100%", minWidth: "200px", gap: "5px", fontWeight: "bold" }} >
                <Tooltip title={"Completed" !== participation.exam_participation_status ? `Cannot update an IN PROGRESS participation, or the participation which is NOT STARTED yet!` : ""} >
                    <Box padding={columns[6].padding || "10px 20px"}>
                        <TextField disabled={"Completed" !== participation.exam_participation_status} onBlur={e => updateExamAttemptDetail( e.target.value, 'remaining_time' )} value={remainingTimeValue} inputProps={{ style: { fontSize: "12px", width: "60px" }, min: 0, step: 300 }} onChange={e => setRemainingTimeValue( e.target.value )} InputProps={{}} type='number' size='small' sx={{ fontSize: "14px" }} />
                        {updatingRemainingTime && <Typography display="flex" gap="5px" alignItems="center" fontSize="8px"> <CircularProgress size={8} /> updating...  </Typography>}
                    </Box>
                </Tooltip>
            </TableCell>

            <TableCell align='right' className='shadow-on-left-border' sx={{ color: table.color, minWidth: "100px", padding: columns[7].padding || "10px 20px", position: "sticky", borderBottom: border[1], right: "0", zIndex: "10", backgroundColor: "inherit" }}>
                <Box display="flex" gap="10px" justifyContent="flex-end" alignItems="center">
                    <Link to={`/admin/assessment/${id}/evaluate/${participation.participation_id}`} className="default-link">
                        <Button size="small" variant='outlined' onClick={e => { }} color="secondary" sx={{ textTransform: "capitalize" }}>Evaluate</Button>
                    </Link>
                    <IconButton onClick={() => setConfirmDialogState( true )} color='errorMessage'>
                        {Icons.default.DeleteIconOutlined}
                    </IconButton>
                </Box>
            </TableCell>

        </TableRow>
    )
}

export default ParticipationAttemptRow
