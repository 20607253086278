import { FormControl, Box, TextField, Typography, Button, Radio, CircularProgress, Checkbox, Paper, useTheme, Select, MenuItem, IconButton } from '@mui/material'
import { formatDateTimeForInputField } from '../../../utils/exam-utilities'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'
import AudioInput from '../../../components/exams-components/AudioInput'
import BreadcrumbList from '../../../components/common/BreadcrumbList'
import { useSettingsStore } from '../../../store/settings.store'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useExamStore } from '../../../store/exam.store'
import noExamsImg from '../../../assets/noexams.svg'
import React, { useEffect, useState } from 'react'
import { Icons, latexDelimiters } from '../../../utils/utilities'
import { CheckBox, Mic, RadioButtonChecked } from '@mui/icons-material'
import { toast } from 'react-toastify'
import api from '../../../service/api'
import * as Yup from 'yup'
import CustomWysiwyg from '../../../components/common/CustomWysiwyg'
import Latex from 'react-latex-next'


const AddQuestions = () => {
    const [audioRecorderStatus, setAudioRecorderStatus] = useState( false )
    const [selectedAudioFiles, setSelectedAudioFiles] = useState( [] )
    const [options, setOptions] = useState( [{ text: "", status: false }] )
    const [plainAnswers, setPlainAnswers] = useState( [''] )
    const [inProgress, setInProgress] = useState( true )
    const [isAdding, setIsAdding] = useState( false )
    const [exam, setExam] = useState( null )

    const SettingsStore = useSettingsStore()
    const ExamStore = useExamStore()
    const navigate = useNavigate()
    const { id } = useParams()

    const { palette, border, theme } = useTheme()

    const initialValues = {
        question: "",
        questionUrl: "",
        hint: "",
        marks: 1,
        questionType: "MCQ",
        selectionType: "single",
        answers: [{ text: "", status: false }],
        correctAnswer: ''
    }

    const validationSchema = Yup.object().shape( {
        question: Yup.string().required( "This field is required" ),
        marks: Yup.number().required( "This field is required" ).min( 1, "Minimum marks should be 1" ),
        questionUrl: Yup.string().notRequired().matches( /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/, "Please provide a valid URL" ),
        answers: Yup.mixed().required( "Answer is required!" )
            .test( {
                name: "MCQ_ANSWERS", message: "Provide options for MCQ type questions", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' ) {
                        if ( context.parent.answers ) {
                            if ( Object.keys( context.parent.answers ).length < 2 ) return false
                            else return true
                        }
                    } else return true
                }
            } )
            .test( {
                name: "MULTIPLE_CORRECT_ANSWER", message: "Please select multiple correct answers", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' && context.parent.selectionType !== 'single' ) {
                        if ( !Array.isArray( value ) )
                            return false
                        if ( value.filter( o => o.status ).length < 2 )
                            return false
                        return true
                    } else return true
                }
            } )
            .test( {
                name: "EMPTY_ANSWERS", message: "Please fill all the options fileds!", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' ) {
                        if ( context.parent.answers ) {
                            for ( const ans of plainAnswers ) {
                                if ( !ans || ans.trim() === '' ) {
                                    return false
                                }
                            }
                            return true
                        }
                    } else return true
                }
            } ),
        correctAnswer: Yup.mixed()
            .test( {
                name: "CORRECT_ANSWER", message: "Select the correct answer", test: ( value, context ) => {
                    if ( context.parent.questionType === 'MCQ' && context.parent.selectionType === 'single' ) {
                        if ( context.parent.answers?.filter( o => o.status === true )?.length === 0 )
                            return false
                        return true
                    } else return true
                }
            } )
    } )

    const addAnswerField = ( setFieldValue ) => {
        const newOptions = [...options, { text: "", status: false }]
        setOptions( newOptions )
        setPlainAnswers( prev => [...prev, ""] )
        setFieldValue( 'answers', newOptions )
    }

    const handleSelectionTypeChange = ( val, setFieldValue ) => {
        setFieldValue( 'selectionType', val )
        if ( val === 'single' ) {
            setOptions( prev => {
                const newOptionsValue = [...prev]
                for ( let i = 0; i < newOptionsValue.length; i++ ) {
                    const option = newOptionsValue[i]
                    option.status = false
                }
                setFieldValue( 'answers', newOptionsValue )
                return newOptionsValue
            } )
        }
    }

    const setNewAnswers = ( optionId, answer, text, setFieldValue ) => {
        setOptions( prev => {
            const newOptionsValue = [...prev]
            newOptionsValue[optionId].text = answer
            setFieldValue( 'answers', newOptionsValue )
            return newOptionsValue
        } )
        setPlainAnswers( prev => {
            const newPlainValue = [...prev]
            newPlainValue[optionId] = text
            return newPlainValue
        } )
    }

    const handleCorrectAnswerSelection = ( val, index, setFieldValue, type ) => {
        setOptions( prev => {
            const newOptionsValue = [...prev]
            for ( let i = 0; i < newOptionsValue.length; i++ ) {
                const option = newOptionsValue[i]
                if ( index === i ) {
                    option.status = val
                }
                if ( type === 'single' && index !== i )
                    option.status = false
            }
            setFieldValue( 'correctAnswer', `option-${index}` )
            setFieldValue( 'answers', newOptionsValue )
            return newOptionsValue
        } )
    }

    const removeOption = ( index, setFieldValue ) => {
        setOptions( prev => {
            let newOptionsValue = [...prev]
            newOptionsValue.splice( index, 1 )
            setFieldValue( 'answers', newOptionsValue )
            return newOptionsValue
        } )
        setPlainAnswers( prev => {
            const newPlainValue = [...prev]
            newPlainValue.splice( index, 1 )
            return newPlainValue
        } )
    }

    const submit = async ( values, props ) => {
        setIsAdding( true )
        try {
            const formData = new FormData()
            for ( const name in values ) {
                if ( typeof values[name] === 'object' )
                    formData.append( name, JSON.stringify( values[name] ) )
                else
                    formData.append( name, values[name] )
            }
            if ( selectedAudioFiles.length > 0 ) {
                for ( let j = 0; j < selectedAudioFiles.length; j++ ) {
                    const item = selectedAudioFiles[j]
                    formData.append( 'audioattachment[]', item, item.name )
                }
            }
            const res = await api.addQuestion( id, formData )
            if ( res.status === 200 ) {
                props.resetForm()
                toast.success( "Question added" )
                setPlainAnswers( [] )
                setOptions( [{ text: "", status: false }] )
                setSelectedAudioFiles( [] )
            }
        } catch ( e ) {
            // toast( e.response.data )
            console.log( e )
        } finally {
            setIsAdding( false )
        }
    }

    useEffect( () => {
        const getExam = async () => {
            try {
                await ExamStore.fetchExam( id )
                if ( new Date( formatDateTimeForInputField( ExamStore.getExam.exam.exam_start_date, ExamStore.getExam.exam.exam_start_time ) ) < new Date() ) {
                    toast.error( "Exam already started! can't add questions now" )
                    navigate( `/faculty/exams/${id}` )
                } else if ( ExamStore.getExam.exam.exam_status === 'Published' ) {
                    toast.error( "Exam already published! can't add questions now" )
                    navigate( `/faculty/exams/${id}` )
                }
                else setExam( ExamStore.getExam.exam )
            }
            catch ( e ) {
                if ( e.response && e.response.data && "Network Error" === e.response.data ) {
                    navigate( '/networkerror' )
                }
                toast.error( ( e.response && e.response.data ) ? e.response.data : "Something went wrong! try again later" )
            } finally {
                setInProgress( false )
            }
        }
        getExam()
    }, [id, navigate, ExamStore] )

    return (
        <Box flexGrow={1} minWidth="0" overflow="auto" sx={{ padding: "20px" }}>

            {exam && <BreadcrumbList items={[
                { label: "Exams", link: `/faculty/exams` },
                { label: exam ? exam.exam_name : <CircularProgress size={12} />, link: `/faculty/exams/${id}` },
                "Add question"
            ]} />}

            <Paper sx={{ overflow: "hidden" }}>
                {exam && !inProgress &&
                    <Box padding="20px" sx={{ background: ` linear-gradient(233.69deg, #6A6790 -93.75%, #1F1E2A 145.73%)` }} >
                        <Typography color="white" textTransform="capitalize" variant='h5'>{exam.exam_name}</Typography>
                        <Typography color="white" variant='body2' >Add questions and options</Typography>
                    </Box>
                }
                {!exam && !inProgress && <Box display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center" padding="20px">
                    <img src={noExamsImg} width="150px" alt="No Exams" />
                    <Typography variant='subtitle2' color="textSecondary">The exam you are looking for does not exists!</Typography>
                    <Button startIcon={Icons.default.ArrowBack} sx={{ textTransform: "capitalize", marginTop: "10px" }} variant='outlined' size="small" onClick={() => navigate( `/faculty/exams`, { replace: true } )}>Back to exam</Button>
                </Box>}
                {exam && !inProgress && <>
                    <Box margin="20px" padding="20px" border={border[1]} bgcolor={palette.contentBg} borderRadius="5px">
                        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={submit}>
                            {
                                ( { values, setFieldValue, errors } ) => {
                                    return (
                                        <Form autoComplete='off' style={{ display: exam ? "block" : "none" }}>
                                            <FormControl fullWidth margin="normal" sx={{ maxWidth: "900px" }} >
                                                <Typography variant='subtitle2' color={palette.labelColor} gutterBottom >What's the question? *</Typography>
                                                <Box borderColor={palette.primary.main} bgcolor={theme === 'dark' ? "#232323" : "#fff"}>
                                                    <CustomWysiwyg key="question-text" placeholder="Write question here..." onChange={val => setFieldValue( 'question', val )} value={values.question} />
                                                    {values.question?.match( /\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} borderRadius="10px">
                                                        <Latex delimiters={latexDelimiters} >{values.question}</Latex>
                                                    </Box>}
                                                </Box>
                                                <Typography sx={{ color: "errorMessage.main" }}><ErrorMessage name='question' /></Typography>
                                            </FormControl>
                                            {SettingsStore.getUseSettings['exam_question_audio_attachment_enable'] && <Box>
                                                {!audioRecorderStatus && <Typography color="primaryDark.main" onClick={() => setAudioRecorderStatus( !audioRecorderStatus )} variant='subtitle2' sx={{ cursor: "pointer", fontSize: "12px", display: "flex", alignItems: "center", gap: "10px", textTransform: "capitalize" }} > <Mic sx={{ fontSize: "14px" }} /> Add an audio hint?</Typography>}
                                                {audioRecorderStatus &&
                                                    <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom >Question audio attachment</Typography>
                                                        <AudioInput supportedAudioFormats={SettingsStore.getUseSettings['question_audio_attachment_suported_formats']} maxSize={SettingsStore.getUseSettings['exam_question_audio_attachment_max_size']} container="bordered-box" setRecordedCardStatus={setAudioRecorderStatus} selectedAudioFiles={selectedAudioFiles} setSelectedAudioFiles={setSelectedAudioFiles} maxLength={SettingsStore.getUseSettings['exam_question_audio_attachment_max_length']} />
                                                    </FormControl>
                                                }
                                            </Box>}
                                            <FormControl fullWidth margin="normal" sx={{ maxWidth: "900px" }} >
                                                <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Other hint?</Typography>
                                                <Field size="small" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} name="hint" />
                                            </FormControl>
                                            <FormControl fullWidth margin="normal" sx={{ maxWidth: "900px" }} >
                                                <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>How many points for this question? *</Typography>
                                                <Field size="small" as={TextField} onWheel={( e ) => e.target.blur()} InputLabelProps={{ sx: { fontSize: "14px !important" } }} type="number" name="marks" />
                                                <Typography sx={{ color: "errorMessage.main" }}><ErrorMessage name='marks' /></Typography>
                                            </FormControl>

                                            <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                                <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Select question type</Typography>
                                                <Field as={Select} size='small' onChange={e => handleSelectionTypeChange( e.target.value, setFieldValue )} name="selectionType">
                                                    <MenuItem value="single">
                                                        <Box gap="10px" alignItems="center" display="flex">
                                                            <RadioButtonChecked sx={{ color: "inherit" }} />
                                                            <Typography>MCQ (Single selection)</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                    <MenuItem value="multiple">
                                                        <Box gap="10px" alignItems="center" display="flex">
                                                            <CheckBox sx={{ color: "inherit" }} />
                                                            <Typography>MCQ (Multiple selection)</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                </Field>
                                            </FormControl>


                                            <Box maxWidth="900px" display="flex" marginTop="10px" flexDirection="column" gap="20px">
                                                <Typography color={palette.labelColor} variant='subtitle2'>{values.questionType !== 'MCQ' ? "Specify key answer for evaluation *" : "What are the answer choices? *"}</Typography>
                                                {values.answers.map( ( optionId, index ) => {

                                                    return ( <Box key={index}>
                                                        <Box display="flex" gap="5px" alignItems="flex-start">
                                                            <Box textAlign="right">
                                                                {values.selectionType === 'single' ? <Radio sx={{ color: errors.correctAnswer && palette.errorMessage.main }} checked={options[index].status === true} onChange={( e ) => handleCorrectAnswerSelection( e.target.checked, index, setFieldValue, 'single' )} />
                                                                    : <Checkbox sx={{ color: errors.correctAnswer && palette.errorMessage.main }} onChange={( e ) => handleCorrectAnswerSelection( e.target.checked, index, setFieldValue, 'multiple' )} checked={options[index].status === true} />}
                                                            </Box>
                                                            <Box flexGrow={1} bgcolor={theme !== 'dark' && palette.form.inputBg}>
                                                                <CustomWysiwyg
                                                                    key={index}
                                                                    placeholder="Option goes here..."
                                                                    height='45px'
                                                                    autoFocus
                                                                    value={options[index].text || ""}
                                                                    onChange={( val, textVal ) => {
                                                                        setNewAnswers( index, val, textVal, setFieldValue )
                                                                    }}
                                                                />
                                                            </Box>
                                                            {index > 1 && <IconButton onClick={() => removeOption( index, setFieldValue )} size='small' color="error">{Icons.default.RemoveCircleIcon}</IconButton>}
                                                        </Box>
                                                        {options[index]?.text?.match( /\$.*\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' fontSize="12px !important" padding="10px" border={border[1]} borderRadius="10px">
                                                            <Latex delimiters={latexDelimiters} >{options[index].text}</Latex>
                                                        </Box>}
                                                    </Box> )
                                                } )}
                                            </Box>
                                            <Box display="flex" alignItems="flex-start" gap="5px" maxWidth="900px" onClick={() => addAnswerField( setFieldValue )} overflow='hidden' marginTop="20px" position="relative">
                                                <Box position="absolute" borderRadius="5px" top="0" bottom="0" right="0" left="0" bgcolor="rgba(250,250,250,0.6)" sx={{ cursor: "pointer", zIndex: 10, '&:hover': { background: "rgba(250,250,250,0.2)" } }}></Box>
                                                <Box textAlign="right">
                                                    {values.selectionType === 'single' ? <Radio checked={false} />
                                                        : <Checkbox checked={false} />}
                                                </Box>
                                                <CustomWysiwyg
                                                    styles={{ position: "relative", zIndex: 1 }}
                                                    key={"read-only-adder"}
                                                    placeholder="Add new option"
                                                    height='50px'
                                                    value=""
                                                    readOnly
                                                />
                                                {values.answers?.length > 1 && <IconButton color="error">{Icons.default.RemoveCircleIcon}</IconButton>}
                                            </Box>
                                            <Typography fontSize="12px" marginTop="10px" variant='subtitle2' sx={{ color: "errorMessage.main" }}><ErrorMessage name='answers' /></Typography>
                                            <Box display="flex" flexWrap="wrap" marginTop="40px" gap="20px">
                                                {/* {values.questionType === 'MCQ' && <Button variant='contained' onClick={addAnswerField} sx={{ textTransform: "capitalize", marginTop: "10px" }} color='warning' >Add another option</Button>} */}
                                                <Button variant='contained' disableElevation startIcon={isAdding ? <CircularProgress size={14} /> : undefined} disabled={( !exam || isAdding )} type="submit" sx={{ marginTop: "10px", textTransform: "capitalize" }}>{isAdding ? "Adding question..." : "Add question"}</Button>
                                                <Button variant='contained' color="error" disableElevation disabled={( !exam || isAdding )} onClick={() => navigate( `/faculty/exams/${id}` )} sx={{ marginTop: "10px", textTransform: "capitalize" }}>Back to exam</Button>
                                            </Box>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </Box>
                </>}
                {inProgress &&
                    <DataLoadingSpinner waitingMessage="Loading please wait..." />
                }
            </Paper>

        </Box >
    )
}

export default AddQuestions