import { Box, Collapse, MenuItem, Select, TextField, Typography, Button, IconButton, Drawer, Popover, useTheme, CircularProgress, Tooltip, Card } from '@mui/material'
import React, { useState, useRef } from 'react'
import { Icons, attachmentThumbnails, convertToCDNLink, getFileSizeFromBytes } from '../../../utils/utilities'
import { observer } from 'mobx-react'
import api from '../../../service/api'
import { toast } from 'react-toastify'
import { CopyAllOutlined, Edit, ExpandMore } from '@mui/icons-material'
import EditSetting from './EditSetting'
import CustomSwitch from '../../common/CustomSwitch'
import DocumentViewer from '../../common/DocumentViewer'
import DataLoadingSpinner from '../../common/DataLoadingSpinner'

const validateAsNumber = ( val ) => {
    try {
        if ( val !== '' )
            return !Number.isNaN( parseInt( val ) )
        else
            return true
    } catch ( err ) {
        return false
    }
}

const getNumericValue = ( val ) => {
    return parseInt( val )
}

const ControlPanelSettingBox = observer( ( { isParent = true, setting, getSelectedSettings } ) => {
    const [isSettingActive, setIsSettingActive] = useState( setting.setting_data_type === 'boolean' ? setting.value === "true" : Boolean( setting.value ) )
    const [settingValue, setSettingValue] = useState( setting.setting_data_type === 'boolean' ? setting.value === "true" : setting.setting_data_type === 'file' ? ( setting.value ? JSON.parse( setting.value ) : [] ) : setting.value )
    const [settingForEdit, setSettingForEdit] = useState( null )
    const fileInputRef = useRef( null )
    const [updating, setUpdating] = useState( false )
    const [previewFile, setPreviewFile] = useState( null )
    const [previewingFile, setPreviewingFile] = useState( null )
    const [anchorEl, setAnchorEl] = useState( null )
    const [deletingFile, setDeletingFile] = useState( {} )

    const { palette, border } = useTheme()

    const handleSettingChange = async ( value, setting, type = 'textual' ) => {
        try {
            const { data } = await api.updateSetting( setting.setting_data_id, type === 'textual' ? { value } : value )
            console.log( data.data )
            setSettingValue( type !== "textual" ? JSON.parse( data.data ) : data.data )
            toast( `${setting.setting_label} updated successfully` )
        } catch ( err ) {
            console.log( err )
            toast( err?.response?.data?.message || "An error occured while updating the setting! please try again." )
        }
    }

    const handleDeleteAttachment = async ( key ) => {
        const fileIndex = settingValue.findIndex( f => f.key === key )
        setDeletingFile( prev => ( { ...prev, [fileIndex]: true } ) )
        try {
            const fileToDelete = settingValue[fileIndex]
            const { data } = await api.deleteSettingAttachment( setting.setting_data_id, { key: fileToDelete.key } )
            toast( `${fileToDelete.name} : Attachment deleted successfully` )
            setSettingValue( data.data )
        } catch ( err ) {
            console.error( err )
            toast( `Failed to delete attachment` )
        } finally {
            setDeletingFile( prev => ( { ...prev, [fileIndex]: false } ) )
        }
    }

    const copySettingKey = ( key ) => {
        navigator.clipboard.writeText( key )
        toast( "Copied key to clipboard" )
    }

    const handleFileChange = async ( event, setting ) => {
        setUpdating( true )
        const files = Array.from( event.target.files )
        const acceptedExtensions = ['pdf', 'docx']
        const existingfiles = []
        let newFilesExists = false
        const formData = new FormData()
        for ( const file of files ) {
            const fileExtension = file.name.split( "." ).pop()
            if ( !acceptedExtensions.includes( fileExtension ) ) {
                toast( 'Only PDF and DOCX files are accepted' )
                fileInputRef.current.value = null
                continue
            }
            const exists = settingValue.some( existingfile => existingfile.name === file.name )
            if ( exists ) {
                existingfiles.push( file.name )
                continue
            }
            newFilesExists = true
            formData.append( 'attachments[]', file, file.name )
        }
        if ( existingfiles.length > 0 ) {
            toast( "The following files already exist: " + existingfiles.join( ", " ) )
        }
        if ( newFilesExists ) {
            try {
                await handleSettingChange( formData, setting, 'file' )
            } catch ( err ) {
                console.error( err )
                toast( 'Failed to upload file' )
            } finally {
                event.target.value = ""
            }
        }
        setUpdating( false )
    }

    const handleViewFile = ( file ) => {
        const cdnLink = convertToCDNLink( file.location )
        setPreviewFile( cdnLink )
        setPreviewingFile( { sample_material_format: file.name, type: file.type } )
    }

    const handleDownloadFile = ( file ) => {
        const link = document.createElement( 'a' )
        link.href = file.location
        link.download = file.name
        link.click()
        link.remove()
    }

    const viewUploadedFiles = ( event, setting ) => {
        setAnchorEl( event.currentTarget )
    }

    const handlePopClose = () => {
        setAnchorEl( null )
    }


    return (
        <Card elevation={1} sx={{ minWidth: "400px", display: "flex", border: border[1] + "99", height: "fit-content", alignItems: 'stretch' }}>
            {settingForEdit && <EditSetting setState={setSettingForEdit} getSelectedSettings={getSelectedSettings} dialogState={settingForEdit ? true : false} setting={settingForEdit} />}
            <Box flexGrow={1}>
                <Box bgcolor={!isParent ? palette.contentBg : "none"} minHeight="60px" flexWrap="wrap" gap="10px" flexGrow={1} padding="10px" display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Box display="flex" flexWrap="wrap" gap={{ md: "10px", xs: "5px" }}>
                            <Typography display="inline-block" fontSize="16px" fontWeight="500">{setting.setting_label} </Typography>
                            <Typography display="inline-flex" alignItems="center" variant="subtitle2" color="secondary" sx={{ cursor: "pointer" }} onClick={() => { setSettingForEdit( setting ) }} > <Edit sx={{ fontSize: "14px" }} /> Edit</Typography>
                            <Typography display="inline-flex" alignItems="center" variant="subtitle2" color="primary" sx={{ cursor: "pointer" }} onClick={() => { copySettingKey( setting.setting_key ) }} > <CopyAllOutlined sx={{ fontSize: "14px" }} /> Copy Setting Key</Typography>
                        </Box>
                        <Typography fontSize="10px">{setting.setting_description}</Typography>
                    </Box>
                    <Box>
                        {setting.setting_data_type === 'boolean' && <CustomSwitch checked={isSettingActive} onChange={( e ) => { handleSettingChange( e.target.checked, setting ); setIsSettingActive( !isSettingActive ) }} />}
                        {( setting.setting_data_type === 'text' || setting.setting_data_type === 'multiple' ) && <TextField InputProps={{ endAdornment: <Typography fontSize="10px" fontWeight="600" color="GrayText">{setting.unit}</Typography> }} size="small" value={settingValue} onBlur={( e ) => handleSettingChange( e.target.value, setting )} onChange={( e ) => setSettingValue( e.target.value )} />}
                        {setting.setting_data_type === 'number' && <TextField type='number' InputProps={{ endAdornment: <Typography fontSize="10px" fontWeight="600" color="GrayText">{setting.unit}</Typography> }} size="small" value={settingValue} onBlur={( e ) => { if ( validateAsNumber( e.target.value ) ); handleSettingChange( getNumericValue( e.target.value ), setting ) }} onChange={( e ) => { if ( validateAsNumber( e.target.value ) ) setSettingValue( parseInt( e.target.value ) ) }} />}
                        {setting.setting_data_type === 'select' && <Select size="small" value={settingValue} onChange={( e ) => { handleSettingChange( e.target.value, setting ); setSettingValue( e.target.value ) }}>
                            {setting.setting_options.map( option => (
                                <MenuItem value={option} sx={{ fontSize: "14px" }} dense key={option}>{option}</MenuItem>
                            ) )}
                        </Select>}
                        {setting.setting_data_type === 'file' && (
                            <Box>
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={( e ) => handleFileChange( e, setting )} multiple accept='.pdf,.docx' />

                                <Box display="flex" alignItems="center" gap="10px">
                                    {settingValue.length > 0 && (
                                        <Box onClick={viewUploadedFiles} gap="10px" color="secondary.main" width="fit-content" display="flex" alignItems="center" justifyContent="space-between" borderRadius="40px" bgcolor={palette.secondary.light + "22"} border={`1px solid ${palette.secondary.main}`} padding="10px 20px" sx={{ cursor: "pointer", "&:hover": { background: palette.secondary.light + "55" } }}>
                                            <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} >
                                                {settingValue.length} Formats uploaded
                                            </Typography>
                                            <ExpandMore />
                                        </Box>
                                    )}
                                    {Array.isArray( settingValue ) && settingValue.length > 0 ? (
                                        <Button sx={{ textTransform: "capitalize" }} component="span" color="greyed" disabled={updating} disableElevation startIcon={updating ? <CircularProgress size={14} /> : Icons.default.AddCircleIcon} onClick={() => fileInputRef.current.click()} >
                                            {updating ? "Uploading..." : "Add more"}
                                        </Button>
                                    ) : (
                                        <Button sx={{ textTransform: "capitalize" }} component="span" color="greyed" disabled={updating} disableElevation startIcon={updating ? <CircularProgress size={14} /> : Icons.default.AddIcon} onClick={() => fileInputRef.current.click()}>
                                            {updating ? "Uploading..." : "Upload Files"}
                                        </Button>
                                    )}
                                </Box>

                                <Popover open={Boolean( anchorEl )} anchorEl={anchorEl} onClose={handlePopClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }}>
                                    {Boolean( anchorEl ) && Array.isArray( settingValue ) && settingValue.map( ( file, index ) => (
                                        <Box borderBottom={border[1]} key={file.key} display="flex" alignItems="center" gap="10px" sx={{ padding: '10px' }}>
                                            <img style={{ objectFit: "cover" }} width="45px" src={attachmentThumbnails[file.name?.split( "." ).pop()] ? attachmentThumbnails[file.name?.split( "." ).pop()] : attachmentThumbnails["default"]} alt={file.type} />
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant='subtitle2' fontSize="12px" sx={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {file.name}
                                                </Typography>
                                                <Typography variant='subtitle2' fontSize="10px">
                                                    {getFileSizeFromBytes( file.size )}
                                                </Typography>
                                            </Box>
                                            <IconButton size='small' onClick={() => handleDownloadFile( file )}>
                                                {Icons.default.DownloadIcon}
                                            </IconButton>
                                            <IconButton size='small' onClick={() => handleViewFile( file )}>
                                                {Icons.default.VisibilityIcon}
                                            </IconButton>
                                            <Tooltip title="Remove this file">
                                                <IconButton color='error' size='small' onClick={() => handleDeleteAttachment( file.key )}>
                                                    {deletingFile[index] ? <DataLoadingSpinner padding='0' size={20} /> : Icons.default.CloseIcon}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    ) )}
                                </Popover>
                            </Box>
                        )}
                        {setting.setting_data_type === 'multiple' && <Typography color="GrayText" fontSize="12px" textAlign="right" variant='subtitle2'>Enter as comma separated values</Typography>}
                    </Box>
                </Box>
                <Collapse in={isSettingActive === true && setting.children && setting.children.length > 0}>
                    <Box display="flex" gap="5px" flexDirection="column" padding="0 5px 10px 0" marginLeft="5px">
                        {setting?.children?.map( child => {
                            return (
                                <ControlPanelSettingBox isParent={false} key={child.setting_data_id} setting={child} getSelectedSettings={getSelectedSettings} />
                            )
                        } )}
                    </Box>
                </Collapse>
            </Box>
            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={Boolean( previewFile )} >
                <Box bgcolor="background.paper" flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={1} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{previewingFile?.sample_material_format}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={() => { setPreviewFile( null ) }}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer type={previewingFile?.sample_material_format?.split( "." ).pop()} loadingText="Loading material..." setFile={setPreviewFile} file={previewFile} />
                    </Box>
                </Box>
            </Drawer>
        </Card>
    )
} )

export default ControlPanelSettingBox