import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { Icons, latexDelimiters } from '../../utils/utilities'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { convert } from 'html-to-text'
import { useQuizAndQuestionbankStore } from '../../store/quiz.store'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { RefreshOutlined } from '@mui/icons-material'
import Latex from 'react-latex-next'
import CustomWysiwyg from '../common/CustomWysiwyg'
import PropTypes from 'prop-types'
import CodeButton from '../common/CodeButton'


const AddOrEditQuestionBankQuestionDialog = observer( ( { setSelectedTopicId, setAddTopicDialogState, title = "Add question", state, setState, fromComponent, bankId, topicId, mode = "add", questionDetails } ) => {

    const [options, setOptions] = useState( questionDetails?.options || [] )
    const [newOptionAdded, setNewOptionAdded] = useState( false )
    const [activeOption, setActiveOption] = useState( "" )
    const [adding, setAdding] = useState( false )

    const QuizQuestionbankStore = useQuizAndQuestionbankStore()
    const { bank_id } = useParams()

    const { palette, border, theme } = useTheme()


    const initialValues = {
        question: questionDetails?.question_text || "",
        marks: questionDetails?.points || "",
        questionType: questionDetails?.question_type || "MCQ",
        selectionType: questionDetails?.selection_type || "single",
        options: questionDetails?.options || [],
        answersStatus: questionDetails?.options_status || [],
        correctAnswer: questionDetails?.correct_answer >= 0 ? questionDetails?.correct_answer : '',
        topic_id: questionDetails?.topic_id || topicId || "-",
        question_bank_id: questionDetails?.question_bank_id || +bank_id
    }

    const validationSchema = Yup.object().shape( {
        question: Yup.string().required( "Question is required!" ),
        marks: Yup.number().required( "Marks is required." ).test( "VALID_MARKS", "Enter a valid marks", ( val, { parent } ) => {
            if ( val && !isNaN( parseInt( val ) ) && parseInt( val ) > 0 )
                return true
            return false
        } ),
        options: Yup.mixed().required( "Options are required" ).test( "MINIMUM_TWO_OPTIONS", "A question should has minimum of 2 options.", ( val ) => {
            if ( val.length > 1 )
                return true
            return false
        } ).test( "NO_EMPTY_OPTIONS", "Please fill all the option fields or remove the empty option fields.", ( val ) => {
            if ( val.length > 1 ) {

                return !val.some( o => convert( o.option_text || "" )?.trim() === "" )
            }
            return false
        } ),
        answersStatus: Yup.mixed().test( "ATLEAST_TWO_CORRECT_ANSWERS", "Please select at least two correct answers.", ( val, { parent } ) => {
            if ( parent.selectionType === "single" ) return true
            if ( val.filter( i => i === true ).length >= 2 )
                return true
            return false
        } ),
        correctAnswer: Yup.number().test( "CORRECT_ANSWER", "Please select a correct answer.", ( val, { parent } ) => {
            if ( parent.selectionType !== "single" ) return true
            if ( val >= 0 && val < parent.options.length )
                return true
            return false
        } ),
        topic_id: Yup.string().test( "CHECK_FOR_TOPIC", "Please select a topic.", ( val, { parent } ) => {
            if ( fromComponent !== "questionbank" ) return true
            if ( val !== "-" )
                return true
            return false
        } )
    } )

    const handleEditorValueChange = ( val, setValue, prevVal ) => {
        setValue( 'question', val )
    }

    const handleSelectionTypeChange = ( val, setValue ) => {
        setValue( 'correctAnswer', "" )
        setValue( 'selectionType', val )
    }

    const handleCorrectAnswerChange = ( index, values, val, setValue ) => {
        if ( values.selectionType === 'single' )
            setValue( 'correctAnswer', index )
        else {
            let newStatus = [...values.answersStatus]
            newStatus[index] = val
            setValue( 'answersStatus', newStatus )
        }
    }

    const addOptionField = ( setFieldValue ) => {
        setActiveOption( options.length )
        setOptions( prev => {
            const updated = [...prev, { option_text: `<i>Option ${prev.length + 1}</i>` }]
            setFieldValue( 'options', updated )
            return updated
        } )
        setNewOptionAdded( true )
    }

    const setOptionValue = useCallback( ( index, val, setFieldValue ) => {
        setNewOptionAdded( false )
        setOptions( prev => {
            const updated = [...prev]
            updated[index].option_text = val
            setFieldValue( 'options', updated )
            return updated
        } )
    }, [] )

    const removeOption = ( index, values, setValue ) => {
        setOptions( prev => {
            const newOptions = [...prev]
            let newStatus = [...values.answersStatus]
            newStatus.splice( index, 1 )
            newOptions.splice( index, 1 )
            setValue( 'answersStatus', newStatus )
            setValue( 'options', newOptions )
            if ( values.correctAnswer === index )
                setValue( 'correctAnswer', "" )
            return newOptions
        } )
        if ( index === activeOption )
            setActiveOption( "" )

    }

    const handleClose = ( resetForm ) => {
        if ( mode === 'add' ) {
            resetForm()
            setSelectedTopicId( null )
        }
        setState( false )
    }

    const fetchUpdatedList = async ( e ) => {
        e.target.style.transition = "250ms"
        e.target.style.transform = "rotate(360deg)"
    }

    const addQuestion = async ( values, { resetForm } ) => {
        setAdding( true )
        if ( fromComponent === "questionbank" ) {
            const response = mode === 'add' ? await QuizQuestionbankStore.addQuestions( bank_id, topicId, [values] ) : await QuizQuestionbankStore.updateQuestion( bank_id, questionDetails.topic_id, questionDetails.id, values )
            if ( response ) {
                if ( mode === 'add' ) {
                    setOptions( [] )
                    handleClose( resetForm )
                } else {
                    handleClose( resetForm )
                }

            }
        }
        setAdding( false )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "950px", background: palette.form.formCardBg } }} open={state}>
            <Formik onSubmit={addQuestion} initialValues={initialValues} validationSchema={validationSchema}>
                {( { values, setFieldValue, resetForm } ) => (
                    <Box>
                        <Box borderBottom={border[1]} position="sticky" sx={{ zIndex: 10, bgcolor: palette.form.formCardBg }} top="0" display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="10px 20px">
                            <Box fontSize="18px">{title}</Box>
                            <IconButton onClick={() => handleClose( resetForm )} size="small">{Icons.default.CloseIcon}</IconButton>
                        </Box>
                        <DialogContent sx={{ overflow: "auto" }}>
                            <Form>
                                <Box border={border[1]} padding="20px" borderRadius="5px" bgcolor={palette.contentBg} >
                                    {fromComponent === "questionbank" && <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor}>
                                            {mode !== 'add' ? 'Move to different topic?' : "Select the topic for this question:"} *
                                            <Tooltip title="Refresh list" placement='top'>
                                                <IconButton onClick={fetchUpdatedList} sx={{ marginLeft: "10px" }} size='small'>
                                                    <RefreshOutlined sx={{ fontSize: "18px" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                        <Box>
                                            <Field id="topics-input" disabled={( !QuizQuestionbankStore?.getQuestionBank?.topics || QuizQuestionbankStore?.getQuestionBank?.topics?.length === 0 )} fullWidth size="small" sx={{ color: values.topic_id === "-" ? "#cbcbcb" : "inherit" }} as={Select} name="topic_id" value={values.topic_id}>
                                                <MenuItem dense disabled value={"-"}>Select topic Eg: Chapter 1.1</MenuItem>
                                                {QuizQuestionbankStore?.getQuestionBank?.topics?.map( topic => (
                                                    <MenuItem sx={{ fontSize: "14px" }} dense key={topic.id} value={topic.id}>{topic.name}</MenuItem>
                                                ) )}
                                            </Field>
                                        </Box>
                                        <Box fontSize="12px" color="GrayText">
                                            {( !QuizQuestionbankStore?.getQuestionBank?.topics || QuizQuestionbankStore?.getQuestionBank?.topics?.length === 0 ) ?
                                                "This question bank does not have any topics, please add topics to insert questions. " :
                                                "Need a new topic? fret not. "
                                            }
                                            <Typography onClick={() => setAddTopicDialogState( true )} display="inline" color="secondary" fontSize="12px" fontStyle="italic" fontWeight="500" sx={{ cursor: "pointer", textDecoration: "underline" }} >Add one here</Typography>
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='topic_id' /></Typography>
                                    </FormControl>}
                                    <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>{mode === 'add' ? "Enter the question you'd like to add:" : "Update the question you want to ask:"} *</Typography>
                                        <Box>
                                            <Box bgcolor={palette.form.inputBg}>
                                                <CustomWysiwyg
                                                    id="question-input"
                                                    placeholder={'Type here...'}
                                                    value={values.question}
                                                    onChange={val =>
                                                        handleEditorValueChange( val, setFieldValue, values.question )
                                                    }
                                                />
                                            </Box>
                                            <Typography margin="5px 0 0 2px" fontSize="12px" color='textSecondary'>To Add mathematical formula, use latex with-in dollar symbols, <strong> <em>${"{LATEX}"}$</em> </strong> <CodeButton> Eg: What is c in ${"e=mc^2"}$?</CodeButton></Typography>
                                            {values.question?.match( /\$\$?(.)+\$\$?/ ) && <Box bgcolor={palette.greyedOut} marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} sx={{ borderStyle: "dotted", borderWidth: "2px" }} borderRadius="10px">
                                                <Latex delimiters={latexDelimiters} >{values.question}</Latex>
                                            </Box>}
                                        </Box>
                                        <Typography fontSize="12px" color='errorMessage.main'>
                                            <ErrorMessage name='question' />{' '}
                                        </Typography>

                                    </FormControl>
                                    <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>How many marks for this question? *</Typography>
                                        <Field size="small" as={TextField} placeholder="Specify question's points" type="number" id="marks-input" name="marks" onWheel={e => { e.target.blur() }} />
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='marks' />{' '}</Typography>
                                    </FormControl>
                                    <FormControl margin="dense" fullWidth>
                                        <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Should they pick single or multiple answers?</Typography>
                                        <RadioGroup id="selection-type-input" sx={{ marginTop: "-5px" }} name='selectionType' value={values.selectionType} color='primaryDark' onChange={( e ) => ( handleSelectionTypeChange( e.target.value, setFieldValue ) )} row>
                                            <FormControlLabel value="single" control={<Radio color='primaryDark' />} label="Single" />
                                            <FormControlLabel value="multiple" control={<Radio color='primaryDark' />} label="Multiple" />
                                        </RadioGroup>
                                    </FormControl>
                                    {values.questionType === 'MCQ' && options.length > 0 && <Box>
                                        <Typography gutterBottom variant="subtitle2">Options</Typography>
                                        {options.map( ( option, optionIndex ) => (
                                            <Box marginBottom="5px" key={optionIndex}>
                                                <Box borderRadius="5px" display="flex" gap="5px" alignItems="flex-start">
                                                    <Tooltip placement='right' title={( optionIndex === values.correctAnswer || values.answersStatus[optionIndex] ) ? values.selectionType === "single" ? "Marked as correct" : "mark as incorrect" : "Mark as correct answer"}>
                                                        <span>
                                                            {values.selectionType !== "single" && <Checkbox onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={values.answersStatus[optionIndex] || false} />}
                                                            {values.selectionType === "single" && <Radio onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={optionIndex === values.correctAnswer || values.answersStatus[optionIndex] || false} />}
                                                        </span>
                                                    </Tooltip>
                                                    <Box flexGrow={1} onClick={() => setActiveOption( optionIndex )} bgcolor={theme !== 'dark' && palette.form.inputBg}>
                                                        <CustomWysiwyg
                                                            hideTools={optionIndex !== activeOption}
                                                            id={`option-input-${optionIndex + 1}`}
                                                            key={`option-${optionIndex + 1}`}
                                                            placeholder="Option goes here..."
                                                            autoFocus={newOptionAdded}
                                                            onFocusSelectAll={newOptionAdded}
                                                            height='45px'
                                                            value={option.option_text || ""}
                                                            onChange={( val, textVal ) => {
                                                                setOptionValue( optionIndex, val, setFieldValue )
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Tooltip title="Remove option">
                                                            <IconButton id={`option-remover-${optionIndex + 1}`} size="small" color="errorMessage" onClick={() => removeOption( optionIndex, values, setFieldValue )}>{Icons.default.RemoveCircleIcon}</IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                                {option.option_text?.match( /\$\$?(.)+\$\$?/ ) && <Box bgcolor={palette.greyedOut} marginX="40px" marginTop="5px" marginBottom="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} sx={{ borderStyle: "dotted", borderWidth: "2px" }} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{option.option_text}</Latex>
                                                </Box>}
                                            </Box>
                                        ) )}
                                    </Box>}
                                    <Typography variant='subtitle2' fontSize="12px" color='errorMessage.main'><ErrorMessage name='options' /></Typography>
                                    <Typography variant='subtitle2' fontSize="12px" color='errorMessage.main'><ErrorMessage name='correctAnswer' /></Typography>
                                    <Typography variant='subtitle2' fontSize="12px" color='errorMessage.main'><ErrorMessage name='answersStatus' /></Typography>
                                    <Box id={`option-adder`} padding="5px 5px 5px 0" display="flex" alignItems="flex-start" gap="5px" maxWidth="900px" onClick={() => addOptionField( setFieldValue )} overflow='hidden' marginTop="20px" position="relative">
                                        <Box position="absolute" borderRadius="5px" top="0" bottom="0" right="0" left="0" bgcolor={theme === 'dark' ? "rgba(255, 255, 255, 0)" : "rgba(250,250,250,0.6)"} sx={{ cursor: "pointer", zIndex: 10, '&:hover': { background: "none" } }}></Box>
                                        <Box>
                                            {values.selectionType !== "single" && <Checkbox size='small' color="success" checked={false} />}
                                            {values.selectionType === "single" && <Radio size='small' color="success" checked={false} />}
                                        </Box>
                                        <CustomWysiwyg
                                            styles={{ position: "relative", zIndex: 1 }}
                                            key={"greyed-read-only-adder"}
                                            placeholder="Add new option"
                                            height='50px'
                                            value=""
                                            readOnly
                                        />
                                        {options?.length > 1 && <IconButton size="small" color="errorMessage">{Icons.default.RemoveCircleIcon}</IconButton>}
                                    </Box>
                                    <Box marginTop="40px" display="flex" gap="10px" flexWrap="wrap">
                                        <Button id={`add-edit-submit-btn`} type="submit" disabled={adding} startIcon={adding ? <CircularProgress size={14} /> : mode === 'add' ? Icons.default.AddIcon : Icons.default.CheckIcon} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation >{mode === 'update' ? "Save changes" : "Add Question"}</Button>
                                        <Button id="add-edit-cancel-btn" disableElevation onClick={() => handleClose( resetForm )} sx={{ textTransform: "capitalize" }} variant="contained" color="error" >Cancel</Button>
                                    </Box>
                                </Box>
                            </Form>
                        </DialogContent>
                    </Box>
                )}
            </Formik>
        </Dialog>
    )
} )

AddOrEditQuestionBankQuestionDialog.propTypes = {
    fromComponent: PropTypes.oneOf( ['questionbank', 'admissionexams'] ),
    mode: PropTypes.oneOf( ['add', 'update'] ),
    state: PropTypes.bool,
}

export default AddOrEditQuestionBankQuestionDialog
