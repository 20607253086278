import { useTheme } from '@mui/material'
import React from 'react'

const CodeButton = ( { children } ) => {

    const { theme } = useTheme()

    return (
        <code className={`code-button ${theme}`}>{children}</code>
    )
}

export default CodeButton
