import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'
import { Box, Button, Card, Chip, Typography, useTheme } from '@mui/material'
import CustomSearchBar from '../../components/common/CustomSearchBar'
import { useExamStore } from '../../store/exam.store'
import noExamsImg from '../../assets/noexams.svg'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { formatDateTimeForDisplay } from '../../utils/exam-utilities'
import { useSubjectStore } from '../../store/subject.store'
import { chipColors, Icons } from '../../utils/utilities'
import { Link, useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'

const SubjectExams = observer( () => {

    const [loading, setLoading] = useState( true )
    const [searchText, setSearchText] = useState( "" )

    const UserStore = useUserStore()
    const ExamStore = useExamStore()
    const SubjectStore = useSubjectStore()


    const { palette, border, theme } = useTheme()
    const navigate = useNavigate()

    useEffect( () => {
        const getData = async () => {
            await ExamStore.fetchExamsBySubject( SubjectStore.getSubject.subject_id )
            setLoading( false )
        }
        getData()
    }, [ExamStore, SubjectStore] )

    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            {!loading && ExamStore.getExams.length > 0 &&
                <Box>
                    <Box alignItems="center" gap="20px" borderBottom="1px solid #d3d3d3" justifyContent="space-between" padding="10px 20px" display="flex">
                        <Typography variant="h6">Exams</Typography>
                        <CustomSearchBar border={`1px solid ${palette.borderColor}`} value={searchText} placeholder='Search exams...' onChange={( e ) => setSearchText( e.target.value )} />
                    </Box>
                    <Box padding="20px" sx={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start", gridTemplateColumns: { lg: "repeat(4,1fr)", md: "repeat(3,1fr)", sm: "repeat(2,1fr)", xs: "1fr" }, flexWrap: "wrap", gap: "20px" }}>
                        {ExamStore.getExams.filter( e => e.exam_name?.toLowerCase()?.match( searchText ) ).map( ( exam, eindex ) => {

                            return (
                                <Card sx={{ maxWidth: "300px", borderRadius: "8px", border: theme === 'dark' && border[1] }} key={exam.exam_id}>
                                    <Link target={"_blank"} to={UserStore.getUser.user_role === "STUDENT" ? `/student/exams/${exam.exam_id}` : `/faculty/exams/${exam.exam_id}`} style={{ margin: "10px 0" }}>
                                        <Box overflow="hidden" borderRadius="5px" sx={{ cursor: "pointer", transition: "ease 200ms" }} key={exam.exam_id}>
                                            <Box bgcolor="#EFF1F3" padding="10px" display="flex" alignItems="flex-start" gap="10px">
                                                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="13" cy="7" r="7" fill="#FF8A58" />
                                                    <path d="M10 2L18.6603 17H1.33975L10 2Z" fill="#0F1368" fillOpacity="0.76" />
                                                </svg>
                                                <Box flexGrow={1} overflow="hidden">
                                                    <Typography color="black" noWrap textOverflow="ellipsis" width="100%" marginTop="-5px" fontSize="16px" variant="h6">{exam.exam_name}</Typography>
                                                    <Typography color="black" fontSize="12px">{formatDateTimeForDisplay( exam.exam_start_date, exam.exam_start_time )} - {formatDateTimeForDisplay( exam.exam_end_date, exam.exam_end_time )}</Typography>
                                                </Box>
                                            </Box>
                                            <Box bgcolor={palette.background.bg} padding="10px" overflow="hidden">
                                                <Box overflow="auto" className="no-scrollbar" gap="5px" display="flex">
                                                    {exam?.exam_topics?.map( ( topic, index ) => {
                                                        const color = chipColors[Math.floor( Math.random() * chipColors.length )]
                                                        return (
                                                            <Chip sx={{ fontWeight: 500, borderRadius: "5px", background: color, color: "#fff", border: `1px solid ${color}` }} size='small' key={topic} label={topic} />
                                                        )
                                                    } )}
                                                </Box>
                                                <Typography
                                                    marginTop="10px"
                                                    variant='body2'
                                                    noWrap
                                                    width="250px"
                                                    fontSize="14px"
                                                    color={palette.shadedText}
                                                    fontWeight="500"
                                                >
                                                    {exam.exam_description?.substring( 0, 100 )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Card>
                            )
                        } )}
                    </Box>
                </Box>}
            {!loading && ( ExamStore.getExams || [] ).filter( e => e.exam_name?.toLowerCase()?.match( searchText ) ).length === 0 && <Box display="flex" padding="20px" flexGrow={1} gap="10px" alignItems="center" flexDirection="column" justifyContent="center">
                <img width="200px" src={noExamsImg} alt="No Exams" />
                <Typography variant="subtitle2">No Exams {searchText ? `for search: ${searchText}` : "for this subject exists!"}</Typography>
                {UserStore.getUser.user_role !== 'STUDENT' && <Button onClick={() => navigate( `/faculty/addexam?redirectTo=${encodeURI( window.location.pathname )}` )} color='greyed' startIcon={Icons.default.AddIcon} variant="outlined" >Create one</Button>}

            </Box>}
            {loading && <DataLoadingSpinner waitingMessage="Loading exams pleas wait..." />}
        </Box>
    )
} )

export default SubjectExams
