import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'
import { useRoomStore } from '../../../src/store/room.store'
import { useOrganizationStore } from '../../../src/store/organization.store'
import SelectInstitute from '../../components/admin/SelectInstitute'
import noClassesImg from '../../assets/noclasses.svg'
import selectInstituteImg from '../../assets/selectinstitute.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUserStore } from '../../store/user.store'
import { observer } from 'mobx-react'
import BlockMessage from '../../components/common/BlockMessage'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'

const user = 'admin'

const LiveClass = observer( () => {
    const RoomStore = useRoomStore()
    const OrganizationStore = useOrganizationStore()
    const UserStore = useUserStore()
    const navigate = useNavigate()

    const { palette } = useTheme()

    const [isLoading, setIsLoading] = useState( false )
    const [hasAccess, setHasAccess] = useState( true )

    const [searchParams, setSearchParams] = useSearchParams( { instituteCode: "0" } )
    const instituteCode = searchParams.get( "instituteCode" )

    const getAuditData = useCallback( async ( code ) => {
        setIsLoading( true )
        setSearchParams( prev => {
            prev.set( 'instituteCode', code )
            return prev
        }, { replace: true } )
        await RoomStore.fetchAuditRoom( code )
        setIsLoading( false )
    }, [RoomStore, setSearchParams] )

    const joinSession = async ( data ) => {
        try {
            let res = await RoomStore.joinSession( data )
            //console.log(res)
            if ( res["status"] === true ) {
                //window.open(res["data"],'Acharya Live','height=720,width=1280');
                RoomStore.setStreamDetails( true, res["data"], data )
                navigate( '/admin/stream' )
            }
        } catch ( err ) {
            console.log( err )

        }
    }
    const endSession = async ( data ) => {
        try {
            let res = await RoomStore.endSessionByAdmin( data )
            if ( res === true ) {
                RoomStore.fetchAuditRoom( instituteCode )
                //setInstituteCode(res)
            }
        } catch ( err ) {

        }
    }

    useEffect( () => {
        const getData = async () => {
            if ( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) {
                if ( UserStore.getUser.user_role === 'subadmin' ) {
                    if ( UserStore.getUser.user_permissions.live_class_audit )
                        await OrganizationStore.fetchOrganization()
                    if ( instituteCode !== "0" )
                        await getAuditData( instituteCode )
                    else {
                        setHasAccess( false )
                    }
                } else
                    await OrganizationStore.fetchOrganization()
            } else {
                await RoomStore.fetchAuditRoom()
            }
        }
        getData()
    }, [OrganizationStore, RoomStore, UserStore, instituteCode, getAuditData] )


    return (
        hasAccess ? <Box flexGrow={1} padding="20px" overflow={isLoading || ( !isLoading && RoomStore.getAuditRoom.length === 0 ) ? "auto" : "hidden"}>
            <Paper sx={{ height: ( instituteCode === "0" || isLoading || ( RoomStore.getAuditRoom.length === 0 && ( instituteCode !== "0" ) ) ) ? "fit-content" : "100%", display: "flex", flexDirection: "column" }}>
                {( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) && <Box padding="20px" borderBottom="1px solid #d3d3d3" display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="space-between" gap="20px" alignItems={{ sm: "center", xs: "stretch" }}>
                    <Box flexGrow="1">
                        <Typography variant='h5'>Live classes</Typography>
                        <Typography color="textSecondary" variant='subtitle2'>Check all the currently running sessions.</Typography>
                    </Box>
                    <SelectInstitute fullWidth styles={{ display: "inline-block", justifyContent: "flex-end" }} padding="0" variant="outlined" onSelect={getAuditData} />
                </Box>}
                {!isLoading && RoomStore.getAuditRoom.length > 0 && <Box overflow="hidden" flexGrow={1}>
                    <TableContainer className="custom-scrollbar" sx={{ borderRadius: "0 0 5px 5px", height: "100%", overflow: "auto" }}>
                        <Table sx={{ minWidth: "650px" }} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ padding: "10px 20px", background: palette.primary.main, color: palette.primary.contrastText }}>Course Details</TableCell>
                                    <TableCell sx={{ padding: "10px 20px", background: palette.primary.main, color: palette.primary.contrastText }} align="right">Branch</TableCell>
                                    <TableCell sx={{ padding: "10px 20px", background: palette.primary.main, color: palette.primary.contrastText }} align="right">Presenter</TableCell>
                                    <TableCell sx={{ padding: "10px 20px", background: palette.primary.main, color: palette.primary.contrastText }} align="right">Subject</TableCell>
                                    <TableCell sx={{ padding: "10px 20px", background: palette.primary.main, color: palette.primary.contrastText }} align="right">Session Time</TableCell>
                                    <TableCell sx={{ padding: "10px 20px", background: palette.primary.main, color: palette.primary.contrastText }} align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {RoomStore.getAuditRoom.map( ( row, i ) => (
                                    <TableRow key={'row_' + i}>
                                        <TableCell sx={{ padding: "10px 20px" }} scope="row">
                                            Course : {row.room_session_data["course_short_name"]} <br></br>
                                            <small>

                                                Semester {row.room_session_data["current_sem"]} -
                                                Section {row.room_session_data["section_short_name"]}</small>
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "12px", padding: "10px 20px" }} align="right">{row.room_session_data["branch_name"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px", padding: "10px 20px" }} align="right">{row.room_session_data["presenter_name"] ? row.room_session_data["presenter_name"] : "Not Available"}</TableCell>
                                        <TableCell sx={{ fontSize: "12px", padding: "10px 20px" }} align="right">{row.room_session_data["subject_name_short"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px", padding: "10px 20px" }} align="right">{row.room_session_data["interval"]}</TableCell>
                                        <TableCell sx={{ fontSize: "12px", padding: "10px 20px" }} align="right">
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap", gap: "10px" }}>
                                                {
                                                    user === "admin" ?
                                                        <Button variant="contained" color="secondary"
                                                            onClick={() => endSession( row.room_session_data )}
                                                            disableElevation className="mr-2">
                                                            END
                                                        </Button> : ''
                                                }
                                                <Button variant="contained" color="primary"
                                                    onClick={() => joinSession( row.room_session_data )}
                                                    disableElevation>
                                                    Join
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {!isLoading && RoomStore.getAuditRoom.length === 0 && ( instituteCode !== "0" || !( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) ) && !isLoading && <Box sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold", variant: 'h5', padding: "20px", borderRadius: "0 0 5px 5px" }}>
                    <img src={noClassesImg} width="200px" alt="No live classes going on!" />
                    <Typography variant='subtitle2' color="textSecondary" marginTop="10px">No live classes going on!</Typography>
                </Box>}
                {RoomStore.getAuditRoom.length === 0 && instituteCode === "0" && ( UserStore.getUser.user_auditor || UserStore.getUser.user_role === "admin" || UserStore.getUser.user_role === "subadmin" ) && <Box sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold", variant: 'h5', padding: "20px", borderRadius: "0 0 5px 5px" }}>
                    <img src={selectInstituteImg} style={{ maxHeight: "180px" }} height="100%" alt="Select an institute" />
                    <Typography marginTop="10px" color="textSecondary" variant='subtitle2'>Select an institute</Typography>
                </Box>}
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", textAlign: "center" }}>
                    <DataLoadingSpinner waitingMessage="Loading rooms..." />
                </Box>}
            </Paper>
        </Box> : <Box padding="20px">
            <BlockMessage message={<span>You doesn't have permission to access this page! please contact <i>Alive co-ordinators</i> for further information.</span>} type="error" />
        </Box>
    )
} )

export default LiveClass
