import React, { useState } from 'react'
import { Icons, showDateInIndianDateFormat, time24to12 } from '../../../utils/utilities'
import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from '@mui/material'
import { QuizBlub } from '../../../assets/custom-icons'
import { AccessTimeFilled, CopyAllOutlined, Delete, MoreVert } from '@mui/icons-material'
import { toast } from 'react-toastify'
import ConfirmDialog from '../../common/ConfirmDialog'
import api from '../../../service/api'
import CreateOrEditQuizInstanceDialog from './CreateQuizInstanceDialog'

const InstanceBox = ( { isSelected, title, instance, viewScores, refetchQuizInstances } ) => {

    const { palette, shadows, border } = useTheme()

    const [deleting, setDeleting] = useState( false )
    const [editDialogState, setEditDialogState] = useState( false )
    const [optionsAnchorEl, setOptionsAnchorEl] = useState( null )
    const [deleteConfirmDialogState, setDeleteConfirmDialogState] = useState( false )

    const copyQuizLinkForStudent = details => {
        setOptionsAnchorEl( null )
        const link = `${window.location.origin}/student/quiz?quiz_id=${details.quiz_id}&instance_id=${details.id}`
        navigator.clipboard.writeText( link )
        toast( <span>Link copied <strong> <em>{link}</em> </strong> </span> )
    }

    const deleteInstance = async () => {
        setDeleting( true )
        try {
            await api.quiz.deleteInstance( instance.quiz_id, instance.id )
            setDeleteConfirmDialogState( false )
            await refetchQuizInstances()
        } catch ( err ) {
            toast( err?.response?.data?.message || "Something went wrong while deleting the instance" )
        } finally {
            setDeleting( false )
        }
    }


    return (
        <Box borderRadius="5px" minWidth="0" justifyContent="space-between" alignItems="center" boxShadow={isSelected ? shadows[2] : shadows[1]} border={border[1]} borderColor={isSelected && palette.primary.main} sx={{ borderWidth: isSelected && "2px", borderStyle: "dotted", cursor: "pointer", transition: "200ms", "&:hover": { boxShadow: shadows[2] } }} bgcolor={palette.background.paper} key={instance.id}>
            <ConfirmDialog status={deleteConfirmDialogState} confirmAction={deleteInstance} actionName="Yes, delete it!" cancelAction={() => setDeleteConfirmDialogState( false )} message="All related data will be deleted." waiting={deleting} />

            {editDialogState && <CreateOrEditQuizInstanceDialog setState={setEditDialogState} state={editDialogState} instance={instance} mode='edit' />}

            <Box overflow="hidden" display="flex" padding="20px" gap="20px" alignItems="flex-start">
                <img src={QuizBlub} alt="Quiz Instance" />
                <Box>
                    <Tooltip title={title}>
                        <Typography noWrap width="75%" fontSize="14px" variant="subtitle1"><strong><font color={palette.primary.main}>Quiz for</font> <em>{title}</em> </strong> </Typography>
                    </Tooltip>
                    <Typography fontSize="12px" variant="subtitle2" color="GrayText">
                        {showDateInIndianDateFormat( instance.start_date )}
                    </Typography>
                    <Typography fontSize="12px" variant="subtitle2" color="GrayText">
                        {time24to12( instance.start_time )} - {time24to12( instance.end_time )}
                    </Typography>
                </Box>
            </Box>
            <Box padding="5px 20px" justifyContent="space-between" borderTop={`1px solid ${palette.borderColor}`} display="flex" gap="10px" alignItems="center">
                <Button onClick={() => viewScores( instance )} startIcon={Icons.default.AnalyticsIconOutlined} color="customThemeColor" sx={{ textTransform: "capitalize" }} >View Scores</Button>
                <Tooltip title="Options">
                    <IconButton size='small' onClick={( e ) => setOptionsAnchorEl( e.currentTarget )}>
                        <MoreVert />
                    </IconButton>
                </Tooltip>
                <Menu anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "left" }} anchorEl={optionsAnchorEl} open={Boolean( optionsAnchorEl )} onClose={() => { setOptionsAnchorEl( null ) }} >
                    <MenuItem sx={{ padding: "0", width: "200px" }}>
                        <Button size='small' color='secondary' onClick={() => copyQuizLinkForStudent( instance )} startIcon={<CopyAllOutlined />} sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>Copy link</Button>
                    </MenuItem>
                    {new Date() < new Date( `${instance.start_date?.split( "T" )[0]} ${instance.start_time}` ) && <MenuItem sx={{ padding: "0", width: "200px" }}>
                        <Button size='small' onClick={() => { setEditDialogState( true ); setOptionsAnchorEl( null ) }} startIcon={<AccessTimeFilled />} color='primary' sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>Reschedule</Button>
                    </MenuItem>}
                    <MenuItem sx={{ padding: "0", width: "200px" }}>
                        <Button size='small' onClick={() => { setDeleteConfirmDialogState( true ); setOptionsAnchorEl( null ) }} startIcon={<Delete />} color='errorMessage' sx={{ textTransform: "capitalize", width: "100%", margin: 0, padding: "10px 20px", justifyContent: "flex-start" }}>Delete</Button>
                    </MenuItem>
                </Menu>

            </Box>
        </Box>
    )
}

export default InstanceBox
