import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { Icons, latexDelimiters } from '../../utils/utilities'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import Latex from 'react-latex-next'
import CustomWysiwyg from '../common/CustomWysiwyg'
import { convert } from 'html-to-text'
import { toast } from 'react-toastify'

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
            ['code-block'],
        ],
        // handlers: {
        //     image: async () => await handleFileInput( 'image' ),
        //     video: async () => await handleFileInput( 'video' ),
        // },
    },
}

const AddQuestionDialog = observer( ( { setSelectedSection, title = "Add question", state, setState, section, setQuestions } ) => {

    const [options, setOptions] = useState( [] )
    const [adding, setAdding] = useState( false )

    const { palette, border, theme } = useTheme()


    const initialValues = {
        question: "",
        questionPlainText: "",
        marks: "",
        questionType: "MCQ",
        selectionType: "single",
        options: [],
        answersStatus: [],
        correctAnswer: "",
        section: section || "",
        keyAnswer: "",
        keyAnswerPlainText: "",
    }

    const validationSchema = Yup.object().shape( {
        questionPlainText: Yup.string().required( "Question is required!" ),
        marks: Yup.number().required( "Marks is required." ).test( "VALID_MARKS", "Enter a valid marks", ( val, { parent } ) => {
            if ( val && !isNaN( parseInt( val ) ) && parseInt( val ) > 0 )
                return true
            return false
        } ),
        options: Yup.mixed().required( "Options are required" ).test( "MINIMUM_TWO_OPTIONS", "A question should has minimum of 2 options.", ( val, { parent } ) => {
            if ( val.length > 1 || parent?.questionType !== "MCQ" )
                return true
            return false
        } ).test( "NO_EMPTY_OPTIONS", "Please fill all the option fields or remove the empty option fields.", ( val ) => {
            if ( val.length > 1 ) {
                return !val.some( o => convert( o.option_text || "" )?.trim() === "" )
            }
            return false
        } ),
        answersStatus: Yup.mixed().test( "ATLEAST_TWO_CORRECT_ANSWERS", "Please select at least two correct answers.", ( val, { parent } ) => {
            if ( parent.selectionType === "single" || parent?.questionType !== 'MCQ' ) return true
            if ( val.filter( i => i === true ).length >= 2 )
                return true
            return false
        } ),
        correctAnswer: Yup.number().test( "CORRECT_ANSWER", "Please select a correct answer.", ( val, { parent } ) => {
            if ( parent.selectionType !== "single" || parent?.questionType !== 'MCQ' ) return true
            if ( val >= 0 && val < parent.options.length )
                return true
            return false
        } ),
        keyAnswer: Yup.string().test( "KEY_ANSWER_TEST", "Please provide a key answer.", ( val, { parent } ) => {
            if ( parent?.questionType === 'MCQ' ) return true
            if ( !parent?.keyAnswerPlainText || parent?.keyAnswerPlainText?.length === 0 ) return false
            return true
        } )
    } )

    const handleEditorValueChange = ( val, text, setValue ) => {
        setValue( 'question', val )
        setValue( 'questionPlainText', text?.trim() )
    }

    const handleKeyAnswerChange = ( val, text, setValue ) => {
        setValue( 'keyAnswer', val )
        setValue( 'keyAnswerPlainText', text?.trim() )
    }

    const handleSelectionTypeChange = ( val, setValue ) => {
        setValue( 'correctAnswer', "" )
        setValue( 'selectionType', val )
    }

    const handleQuestionTypeChange = ( val, setValue ) => {
        setValue( 'questionType', val )
        setValue( 'options', [] )
    }

    const handleCorrectAnswerChange = ( index, values, val, setValue ) => {
        if ( values.selectionType === 'single' )
            setValue( 'correctAnswer', index )
        else {
            let newStatus = [...values.answersStatus]
            newStatus[index] = val
            setValue( 'answersStatus', newStatus )
        }
    }

    const addOptionField = ( setFieldValue ) => {
        setOptions( prev => {
            const updated = [...prev, { option_text: "" }]
            setFieldValue( 'options', updated )
            return updated
        } )
    }

    const setOptionValue = useCallback( ( index, val, setFieldValue ) => {
        setOptions( prev => {
            const updated = [...prev]
            updated[index].option_text = val
            setFieldValue( 'options', updated )
            return updated
        } )
    }, [] )

    const removeOption = ( index, values, setValue ) => {
        setOptions( prev => {
            const newOptions = [...prev]
            let newStatus = [...values.answersStatus]
            newStatus.splice( index, 1 )
            newOptions.splice( index, 1 )
            setValue( 'answersStatus', newStatus )
            setValue( 'options', newOptions )
            if ( values.correctAnswer === index )
                setValue( 'correctAnswer', "" )
            return newOptions
        } )
    }

    const handleClose = ( resetForm ) => {
        resetForm()
        setSelectedSection( null )
        setState( false )
    }

    const addQuestion = async ( values, { resetForm } ) => {
        values.question_section = section
        values.id = Date.now()
        setAdding( true )
        setQuestions( prev => [...prev, values] )
        setAdding( false )
        toast( "New question added successfully" )
        resetForm()
        setOptions( [] )
    }

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "950px", background: palette.form.formCardBg } }} open={state}>
            <Formik onSubmit={addQuestion} initialValues={initialValues} validationSchema={validationSchema}>
                {( { values, setFieldValue, resetForm } ) => {

                    return (
                        <Box>
                            <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center" padding="20px">
                                <Typography variant="h6" fontSize="18px">{title}</Typography>
                                <IconButton onClick={() => handleClose( resetForm )} size="small">{Icons.default.CloseIcon}</IconButton>
                            </Box>
                            <DialogContent sx={{ paddingTop: "0", overflow: "auto" }}>
                                <Form>
                                    <Box border={border[1]} padding="20px" borderRadius="5px" bgcolor={palette.contentBg} >
                                        {values.section && values.section !== "Primary" && <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor}>
                                                Question Section
                                            </Typography>
                                            <Field disabled={true} fullWidth size="small" as={TextField} name="section" value={values.section} />
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='section' /></Typography>
                                        </FormControl>}
                                        <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question Text *</Typography>
                                            <Box bgcolor={palette.form.inputBg}>
                                                <CustomWysiwyg modules={modules} onChange={( val, text ) => handleEditorValueChange( val, text, setFieldValue )} value={values.question?.replace( /$/g, "" )} placeholder={'Type here...'} />
                                                <Typography fontSize="12px" color='textSecondary'>To Add mathematical formula, use latex, Eg: <strong> <em>${"{LATEX}"}$</em> </strong></Typography>
                                                {values.question?.match( /\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{values.question}</Latex>
                                                </Box>}

                                            </Box>
                                            <Typography fontSize="12px" color='errorMessage.main'>
                                                <ErrorMessage name='questionPlainText' />{' '}
                                            </Typography>
                                        </FormControl>
                                        <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question marks *</Typography>
                                            <Field size="small" as={TextField} placeholder="Specify question's points" type="number" name="marks" onWheel={e => { e.target.blur() }} />
                                            <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='marks' />{' '}</Typography>
                                        </FormControl>
                                        <FormControl margin='normal' fullWidth sx={{ maxWidth: "900px" }} >
                                            <Typography gutterBottom color={palette.labelColor} variant='subtitle2'>Question type</Typography>
                                            <RadioGroup name='questionType' value={values.questionType} color='primaryDark' onChange={e => handleQuestionTypeChange( e.target.value, setFieldValue )} row>
                                                <FormControlLabel value="MCQ" control={<Radio color="primaryDark" />} label="MCQ" />
                                                <FormControlLabel value="descriptive" control={<Radio color="primaryDark" />} label="Descriptive" />
                                            </RadioGroup>
                                        </FormControl>
                                        {values?.questionType === 'MCQ' && <FormControl margin="dense" fullWidth>
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Selection type</Typography>
                                            <RadioGroup sx={{ marginTop: "-5px" }} name='selectionType' value={values.selectionType} color='primaryDark' onChange={( e ) => ( handleSelectionTypeChange( e.target.value, setFieldValue ) )} row>
                                                <FormControlLabel value="single" control={<Radio color='primaryDark' />} label="Single" />
                                                <FormControlLabel value="multiple" control={<Radio color='primaryDark' />} label="Multiple" />
                                            </RadioGroup>
                                        </FormControl>}
                                        {values.questionType === 'MCQ' && options.length > 0 && <Box>
                                            <Typography gutterBottom variant="subtitle2">Options</Typography>
                                            {options.map( ( option, optionIndex ) => (
                                                <Box marginBottom="5px" key={optionIndex} borderRadius="5px" display="flex" gap="5px" alignItems="flex-start">
                                                    <Tooltip placement='right' title={( optionIndex === values.correctAnswer || values.answersStatus[optionIndex] ) ? values.selectionType === "single" ? "Marked as correct" : "mark as incorrect" : "Mark as correct answer"}>
                                                        <span>
                                                            {values.selectionType !== "single" && <Checkbox onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={values.answersStatus[optionIndex] || false} />}
                                                            {values.selectionType === "single" && <Radio onChange={( e ) => handleCorrectAnswerChange( optionIndex, values, e.target.checked, setFieldValue )} size='small' color="success" checked={optionIndex === values.correctAnswer} />}
                                                        </span>
                                                    </Tooltip>
                                                    <Box flexGrow={1} bgcolor={theme !== 'dark' && palette.form.inputBg}>
                                                        <CustomWysiwyg
                                                            key={`option-${optionIndex + 1}`}
                                                            placeholder="Option goes here..."
                                                            height='45px'
                                                            autoFocus
                                                            value={option.option_text || ""}
                                                            onChange={( val, textVal ) => {
                                                                setOptionValue( optionIndex, val, setFieldValue )
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Tooltip title="Remove option">
                                                            <IconButton size="small" color="errorMessage" onClick={() => removeOption( optionIndex, values, setFieldValue )}>{Icons.default.RemoveCircleIcon}</IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            ) )}
                                        </Box>}
                                        {values.questionType !== 'MCQ' && <Box marginTop="10px" display="flex" flexDirection="column">
                                            <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Specify key answer for evaluation *</Typography>
                                            <Box display="flex" alignItems="flex-start" gap="10px" flexDirection="column">
                                                <Box bgcolor={palette.form.inputBg} color={palette.common.font} width="100%">
                                                    <CustomWysiwyg placeholder='Type the answer here...' className='custom-quill' modules={modules} value={values.keyAnswer} onChange={( val, text ) => handleKeyAnswerChange( val, text, setFieldValue )} />
                                                </Box>
                                                {values?.keyAnswer?.includes( "$" ) && <Box marginTop="10px" className='discussion-wysiwyg-container' width="calc(100% - 20px)" padding="10px" border={border[1]} borderRadius="10px">
                                                    <Latex delimiters={latexDelimiters} >{values?.keyAnswer}</Latex>
                                                </Box>}
                                            </Box>
                                            <Typography fontSize="12px" color='errorMessage.main'>
                                                <ErrorMessage name='keyAnswer' />{' '}
                                            </Typography>
                                        </Box>}
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='options' /></Typography>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='correctAnswer' /></Typography>
                                        <Typography fontSize="12px" color='errorMessage.main'><ErrorMessage name='answersStatus' /></Typography>

                                        <Box padding="5px 5px 5px 0" display="flex" alignItems="flex-start" gap="5px" maxWidth="900px" onClick={() => addOptionField( setFieldValue )} overflow='hidden' marginTop="20px" position="relative">
                                            <Box position="absolute" borderRadius="5px" top="0" bottom="0" right="0" left="0" bgcolor={theme === 'dark' ? "rgba(255, 255, 255, 0)" : "rgba(250,250,250,0.6)"} sx={{ cursor: "pointer", zIndex: 10, '&:hover': { background: "none" } }}></Box>
                                            <Box>
                                                {values.selectionType !== "single" && <Checkbox size='small' color="success" checked={false} />}
                                                {values.selectionType === "single" && <Radio size='small' color="success" checked={false} />}
                                            </Box>
                                            <CustomWysiwyg
                                                styles={{ position: "relative", zIndex: 1 }}
                                                key={"greyed-read-only-adder"}
                                                placeholder="Add new option"
                                                height='50px'
                                                value=""
                                                readOnly
                                            />
                                            {options?.length > 1 && <IconButton size="small" color="errorMessage">{Icons.default.RemoveCircleIcon}</IconButton>}
                                        </Box>
                                        <Box marginTop="40px" display="flex" gap="10px" flexWrap="wrap">
                                            <Button type="submit" disabled={adding} startIcon={adding ? <CircularProgress size={14} /> : Icons.default.AddIcon} sx={{ textTransform: "capitalize" }} variant="contained" disableElevation >Add Question</Button>
                                            <Button disableElevation onClick={() => handleClose( resetForm )} sx={{ textTransform: "capitalize" }} variant="contained" color="error" >Close</Button>
                                        </Box>
                                    </Box>
                                </Form>
                            </DialogContent>
                        </Box>
                    )
                }}
            </Formik>
        </Dialog>
    )
} )

export default AddQuestionDialog
