import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useUserStore } from '../../store/user.store'
import { observer } from "mobx-react"
import { useEffect, useState } from "react"
import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material"
import warningImg from '../../assets/waiting.svg'
import alivelogo from '../../assets/alivelogo.png'
import noConnectionImg from '../../assets/no-connection.svg'
import serverDownImg from '../../assets/server-down.svg'
import { useSettingsStore } from "../../store/settings.store"
import { useLayoutContext } from "../../contexts/layout.context"
import { LoginOutlined } from "@mui/icons-material"
import { useAuthStore } from "../../store/auth.store"

const RequireAuth = observer( ( { allowedRoles } ) => {

    const [errorCode, setErrorCode] = useState( null )
    const [loading, setLoading] = useState( true )

    const { setActive } = useLayoutContext()

    const SettingsStore = useSettingsStore()
    const AuthStore = useAuthStore()
    const UserStore = useUserStore()
    const navigate = useNavigate()
    const location = useLocation()

    const navigateToSignin = () => {
        const redirectValue = encodeURIComponent( `${window.location.pathname}${window.location.search}` )
        if ( location.pathname.includes( 'admin' ) )
            navigate( `/admin?redirect=${redirectValue}` )
        else
            navigate( `/?redirect=${redirectValue}` )
    }

    const handleConnectionStatusChange = () => {
        if ( !window.navigator.onLine ) {
            setErrorCode( "noInternet" )
        }
    }

    useEffect( () => {
        setActive( location.pathname )
    }, [location, setActive] )

    useEffect( () => {
        const getUser = async () => {
            const { netError } = await UserStore.fetchUser()
            if ( netError ) {
                const internetConnected = window.navigator.onLine
                if ( !internetConnected )
                    setErrorCode( "noInternet" )
                else
                    setErrorCode( "serverConnectionError" )
            } else
                await SettingsStore.fetchSettings( { filter: 'foruse' } )
            setLoading( false )
        }
        getUser()
        window.addEventListener( 'online', handleConnectionStatusChange )
        window.addEventListener( 'offline', handleConnectionStatusChange )
        return () => {
            window.removeEventListener( 'online', handleConnectionStatusChange )
            window.removeEventListener( 'offline', handleConnectionStatusChange )
        }
    }, [UserStore, SettingsStore, allowedRoles] )

    return (
        loading ? <Box sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" height="100%" alignItems="center" justifyContent="center">
            <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                <img width="70px" src={alivelogo} alt="Alive" />
                <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px"> <CircularProgress size={14} /> Loading Alive...</Typography>
            </Paper>
        </Box>
            : errorCode ? <Box sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" height="100%" alignItems="center" justifyContent="center">
                {errorCode === "noInternet" && <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                    <img width="70px" src={noConnectionImg} alt="Alive" />
                    <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px"> Check your intenet connection</Typography>
                </Paper>}
                {errorCode === "serverConnectionError" && <Paper sx={{ minWidth: "220px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                    <img width="150px" src={serverDownImg} alt="Alive" />
                    <Typography variant="subtitle2" display="flex" alignItems="center" gap="10px"> Server cannot be reached! try again later</Typography>
                </Paper>}
            </Box> :
                allowedRoles.includes( UserStore.getUser['user_role'].toUpperCase() )
                    ? <Outlet />
                    : <Box padding="20px" height="calc(100% - 40px)" sx={{ background: `url('/imgs/bg-pattern1.png')` }} display="flex" alignItems="center" justifyContent="center">
                        <Paper sx={{ display: "flex", padding: "20px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", flexDirection: "column", justifyContent: "center", gap: "20px", alignItems: "center", borderRadius: "5px" }}>
                            <img width="250px" src={warningImg} alt="session ended" />
                            {AuthStore.getErpSessionState !== "ended" ? <Typography variant='subtitle2' textAlign="center" >
                                You are not allowed to access this page. this pages if only available for <em>{allowedRoles.join( ", " )}.</em>
                            </Typography> : <Typography variant='subtitle2' textAlign="center" >
                                Your session has ended please log in again!
                            </Typography>}
                            <Button variant="outlined" startIcon={<LoginOutlined />} color="primary" onClick={navigateToSignin} sx={{ textTransform: "capitalize" }}>Sign in</Button>
                        </Paper>
                    </Box>
    )
} )

export default RequireAuth
