import { ThemeProvider } from '@mui/material/styles'
import AllRoutes from './components/routes/AllRoutes'
import { ToastContainer } from 'react-toastify'
// import { Quill } from 'react-quill'
import Quill from 'quill'
import 'react-toastify/dist/ReactToastify.css'
import useInterval from '@use-it/interval'
import api from '../src/service/api'


// Stores
import DownloadStatusListStore, { DownloadStatusListProvider } from './store/downloadstatuslistdialog.store'
import ResumeBuilderStore, { ResumeBuilderStoreProvider } from './store/resumebuilder.store'
import StudyMaterialStore, { StudyMaterialStoreProvider } from "./store/studymaterial.store"
import OrganizationStore, { OrganizationStoreProvider } from "./store/organization.store"
import NotificationStore, { NotificationStoreProvider } from "./store/notification.store"
import DiscussionForumStore, { DiscussionForumProvider } from './store/discussionforum.store'
import OnlineCourseStore, { OnlineCourseProvider } from './store/onlinecourse.store'
import AssignmentStore, { AssignmentStoreProvider } from "./store/assignment.store"
import SettingsStore, { SettingsStoreProvider } from './store/settings.store'
import RoomStore, { RoomStoreProvider } from "./store/room.store"
import AuthStore, { AuthStoreProvider } from "./store/auth.store"
import UserStore, { UserStoreProvider } from "./store/user.store"
import ExamStore, { ExamStoreProvider } from './store/exam.store'
import ChatStore, { ChatStoreProvider } from './store/chat.store'
import QuizStore, { QuizAndQuestionbankStoreProvider } from './store/quiz.store'

import LayoutContextProvider from './contexts/layout.context'
import ThemeContextProvider, { useThemeContext } from './contexts/theme.context'
import themes from './themes/themes'
import LiveSessionStore, { LiveSessionStoreProvider } from './store/livesessions.store'

// 10 mins poll //60000 //600000
const AutoAttendence = ( { delay = 60000 } ) => {

  useInterval( () => {
    //setCount((currentCount) => currentCount + 1);
    try {
      if ( localStorage.getItem( "poll_session" ) !== null && localStorage.getItem( "poll_session" ) !== undefined ) {
        api.getSessionClassAttendence( JSON.parse( localStorage.getItem( "poll_session" ) ) ).then( ( res ) => {
          console.log( `Res Auto attendence`, res )
        } ).catch( ( err ) => {
          console.log( `Err Auto attendence`, err )
        } )
      }
    } catch ( err ) { }
  }, delay )

  return ''
}


const Video = Quill.import( 'formats/video' )
const Link = Quill.import( 'formats/link' )

class CoustomVideo extends Video {
  static create( value ) {
    const node = super.create( value )

    const video = document.createElement( 'video' )
    video.setAttribute( 'controls', true )
    video.setAttribute( 'type', 'video/*' )
    video.setAttribute( 'style', 'max-height: 200px;object-fit: contain;' )
    video.setAttribute( 'src', this.sanitize( value ) )
    node.appendChild( video )

    return node
  }

  static sanitize( url ) {
    return Link.sanitize( url )
  }
}
CoustomVideo.blotName = 'video'
CoustomVideo.className = 'ql-video-custom'
CoustomVideo.tagName = 'DIV'

Quill.register( 'formats/video', CoustomVideo )

const AppProvider = () => {

  const { theme } = useThemeContext()

  return (
    <ThemeProvider theme={themes[theme]}>
      <AutoAttendence />
      <AuthStoreProvider store={AuthStore}>
        <UserStoreProvider store={UserStore}>
          <OrganizationStoreProvider store={OrganizationStore}>
            <RoomStoreProvider store={RoomStore}>
              <StudyMaterialStoreProvider store={StudyMaterialStore}>
                <OnlineCourseProvider store={OnlineCourseStore}>
                  <AssignmentStoreProvider store={AssignmentStore}>
                    <NotificationStoreProvider store={NotificationStore}>
                      <ExamStoreProvider store={ExamStore}>
                        <ResumeBuilderStoreProvider store={ResumeBuilderStore}>
                          <ChatStoreProvider store={ChatStore}>
                            <DownloadStatusListProvider store={DownloadStatusListStore}>
                              <SettingsStoreProvider store={SettingsStore}>
                                <DiscussionForumProvider store={DiscussionForumStore}>
                                  <QuizAndQuestionbankStoreProvider store={QuizStore}>
                                    <LiveSessionStoreProvider store={LiveSessionStore}>
                                      <LayoutContextProvider>
                                        <AllRoutes />
                                        <ToastContainer position='bottom-left' autoClose={20000} hideProgressBar closeOnClick theme={theme === 'dark' ? 'light' : 'dark'} style={{ fontSize: "12px", zIndex: 100000000 }} />
                                      </LayoutContextProvider>
                                    </LiveSessionStoreProvider>
                                  </QuizAndQuestionbankStoreProvider>
                                </DiscussionForumProvider>
                              </SettingsStoreProvider>
                            </DownloadStatusListProvider>
                          </ChatStoreProvider>
                        </ResumeBuilderStoreProvider>
                      </ExamStoreProvider>
                    </NotificationStoreProvider>
                  </AssignmentStoreProvider>
                </OnlineCourseProvider>
              </StudyMaterialStoreProvider>
            </RoomStoreProvider>
          </OrganizationStoreProvider>
        </UserStoreProvider>
      </AuthStoreProvider>
    </ThemeProvider>
  )
}


function App() {

  return (
    <ThemeContextProvider>
      <AppProvider />
    </ThemeContextProvider>
  )
}

export default App

