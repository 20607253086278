import { Typography, Box, CircularProgress, Paper, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import EditQuestionList from '../../../components/exams-components/EditQuestionList'
import QuestionDetail from '../../../components/exams-components/QuestionDetail'
import api from '../../../service/api'
import { useExamStore } from '../../../store/exam.store'
import { Icons } from '../../../utils/utilities'
import DataLoadingSpinner from '../../../components/common/DataLoadingSpinner'

const EditQuestions = () => {
    const [questions, setQuestions] = useState( null )
    const [exam, setExam] = useState( null )
    const [inProgress, setInProgress] = useState( true )
    const [selectedQuestion, setSelectedQuestion] = useState( null )
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState( 1 )

    const ExamStore = useExamStore()

    const { palette, border } = useTheme()

    const { id } = useParams()
    const navigate = useNavigate()

    const selectQuestion = useCallback( ( id = 0 ) => {
        if ( questions ) {
            const newSelectedQuestion = questions.find( question => question.question.question_id === id )
            setSelectedQuestion( { ...newSelectedQuestion } )
            const index = questions.indexOf( newSelectedQuestion ) + 1
            setCurrentQuestionNumber( index )
        }
    }, [questions] )

    const update = async ( data, qid ) => {
        try {
            const res = await api.updateQuestion( id, qid, data )
            toast( res.data.message )
            const { data: questionsData } = await api.fetchQuestions( id )
            setQuestions( questionsData.data )
            return { updatedOptions: res.data.updatedOptions, success: true }
        } catch ( e ) {
            console.log( e )
            if ( e.message === "Network Error" ) {
                navigate( '/networkerror' )
            } else toast( e?.response?.data?.message || "Something went wrong! try again later" )
            return { success: false }
        }
    }

    const deleteQuestion = async ( qid ) => {
        try {
            const res = await api.deleteQuestion( id, qid )
            const newQuestions = questions.filter( question => question.question.question_id !== qid )
            setQuestions( newQuestions )
            if ( newQuestions.length > 0 ) {
                if ( newQuestions.length > currentQuestionNumber ) {
                    setSelectedQuestion( newQuestions[currentQuestionNumber - 1] )
                } else {
                    setSelectedQuestion( newQuestions[newQuestions.length - 1] )
                    setCurrentQuestionNumber( newQuestions.length )
                }
            } else {
                setSelectedQuestion( null )
                setCurrentQuestionNumber( 0 )
            }
            toast( res.data.message )
        } catch ( e ) {
            if ( e.message === "Network Error" ) {
                navigate( '/networkerror' )
            } else toast( e?.response?.data?.message || "Something went wrong while trying to delete the question." )
        }
    }

    useEffect( () => {
        const getData = async () => {
            try {
                await ExamStore.fetchExam( id )
                const { data: questionsData } = await api.fetchQuestions( id )
                setQuestions( questionsData.data )
                setExam( ExamStore.getExam.exam )
                setSelectedQuestion( questionsData.data.length > 0 ? questionsData.data[0] : null )
            } catch ( e ) {
                toast( e?.response?.data?.message || "Something went wrong! try again later" )
            } finally {
                setInProgress( false )
            }
        }
        getData()
    }, [id, ExamStore, navigate] )

    return (
        // <Layout>
        <Box padding="20px" flexGrow={1} minWidth="0" overflow="auto">
            {exam && <Typography marginBottom="10px" fontSize="14px" fontWeight={500} display="flex" alignItems="center" color="textSecondary"> <Link to={`/faculty/exams`} className="breadcrumb-link">Exams</Link> {Icons.small.ChevronRightIcon} <Link to={`/faculty/exams/${id}`} className="breadcrumb-link">{exam ? exam.exam_name : <CircularProgress size={12} />}</Link> {Icons.small.ChevronRightIcon} Edit questions </Typography>}
            <Paper sx={{ display: "flex", overflow: "hidden", flexDirection: "column" }}>
                {exam &&
                    <Box padding="20px">
                        <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
                            <Box>
                                <Typography variant='h5'>Edit Questions</Typography>
                                <Typography variant='body2' color="textSecondary">{exam.exam_name}</Typography>
                            </Box>
                        </Box>
                        {( !questions || questions.length === 0 ) && <Box fontSize="20px" fontWeight="500" margin="20px 20px 0 0" border={border[1]} padding="20px" borderRadius="5px" bgcolor={palette.contentBg}>No questions! <Typography color="GrayText" display="inline-block" variant='subtitle2' sx={{ cursor: "pointer", textDecoration: "underline" }} fontSize="12px" onClick={() => navigate( `/faculty/exams/${id}` )}><em>Add or import questions</em></Typography> </Box>}
                    </Box>
                }
                {exam && selectedQuestion?.question && <Box flexGrow={1} display="flex" alignItems="flex-start" height="calc(100vh - 200px)">
                    <EditQuestionList questions={questions} selectedQuestion={selectedQuestion} selectQuestion={selectQuestion} />
                    <QuestionDetail selectedQuestion={selectedQuestion} exam={exam} questionNumber={currentQuestionNumber} update={update} deleteQuestion={deleteQuestion} />
                </Box>}
                {inProgress && <DataLoadingSpinner waitingMessage="Loading question..." />}
            </Paper>
        </Box>
        // </Layout>
    )
}

export default EditQuestions
