import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, FormLabel, IconButton, Radio, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { debounce, Icons, latexDelimiters } from '../../utils/utilities'
import AudioInput from '../exams-components/AudioInput'
import api from '../../service/api'
import { useParams } from 'react-router-dom'
import CustomWysiwyg from '../common/CustomWysiwyg'
import { toast } from 'react-toastify'
import { convert } from 'html-to-text'
import Latex from 'react-latex-next'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { CancelOutlined, Mic } from '@mui/icons-material'
import AudioPlayer from '../exams-components/AudioPlayer'

const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['normal', 'small', 'large'] }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
            ['code-block'],
        ],
        // handlers: {
        //     image: async () => await handleFileInput( 'image' ),
        //     video: async () => await handleFileInput( 'video' ),
        // },
    },
}

const OptionComponent = ( { newOptionAdded, setNewOptionAdded, correctAnswers = [], optionsCount = 0, setCorrectAnswers, editingOptionId, setEditingOptionId, getQuestionDetails, optionDetails, option, values, handleCorrectAnswerChange, module = "admission-exam" } ) => {

    const [saving, setSaving] = useState( false )
    const [editMode, setEditMode] = useState( false )
    const [removing, setRemoving] = useState( false )
    const [editValue, setEditValue] = useState( option.answer_text || '' )

    const isCorrectAnswer = correctAnswers.includes( option.answer_id )

    const { id } = useParams()
    const { palette, shadows, border } = useTheme()

    const valuesSetter = ( val, text ) => {
        setEditValue( val )
    }

    const updateOptionDetails = async ( { value, text, update_column, updated_status } ) => {
        if ( update_column === 'answer_text' ) {
            if ( convert( value ) === convert( option.answer_text ) ) {
                return
            }
            setSaving( true )
            try {
                if ( text ) {
                    module === "admission-exam" ? await api.admissionExams.updateExamQuestionOption( id, values.question_id, option?.answer_id, { answer_text: value, update_column, question_selection_type: values.selectionType } ) : await api.updateExamQuestionOption( id, values.question_id, option?.answer_id, { answer_text: value, update_column, question_selection_type: values.selectionType } )
                    setEditMode( false )
                }
            } catch ( err ) {
                console.log( err )
                toast( err?.response?.data?.message || "Couldn't save changes! try again." )
            } finally {
                setSaving( false )
            }
        } else {
            setSaving( true )
            try {
                module === "admission-exam" ? await api.admissionExams.updateExamQuestionOption( id, values.question_id, option?.answer_id, { update_column, question_selection_type: values.selectionType, status: updated_status } ) : await api.updateExamQuestionOption( id, values.question_id, option?.answer_id, { update_column, question_selection_type: values.selectionType, status: updated_status } )
                if ( values.selectionType === 'single' )
                    setCorrectAnswers( [option.answer_id] )
                else {
                    if ( correctAnswers.includes( option.answer_id ) ) {
                        setCorrectAnswers( prev => {
                            const newList = [...prev]
                            newList.splice( newList.indexOf( option.answer_id ), 1 )
                            return newList
                        } )
                    } else {
                        setCorrectAnswers( prev => {
                            const newList = [...prev]
                            newList.push( option.answer_id )
                            return newList
                        } )
                    }
                }
                setEditMode( false )
                setEditingOptionId( "" )
            } catch ( err ) {
                console.log( err )
                toast( err?.response?.data?.message || "Couldn't save changes! try again." )
            } finally {
                setSaving( false )
            }
        }

    }

    const saveOptionChanges = debounce( updateOptionDetails, 1000 )

    const hadleOptionValueChange = useCallback( ( val, text ) => {
        valuesSetter( val, text )
        saveOptionChanges( { value: val, text, update_column: 'answer_text' } )
    }, [saveOptionChanges] )

    const remove = async () => {
        try {
            setRemoving( true )
            module === "admission-exam" ? await api.admissionExams.removeExamQuestionOption( id, values.question_id, option?.answer_id ) : await api.removeExamQuestionOption( id, values.question_id, option?.answer_id )
            await getQuestionDetails()
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't remove the option! please try again." )
        } finally {
            setRemoving( false )
        }
    }

    const changeAnswerStatus = ( status ) => {
        updateOptionDetails( { update_column: 'answer_status', updated_status: status } )
    }


    return (
        <Box borderRadius="5px" sx={{ transition: "ease 200ms", background: "none", borderWidth: ( isCorrectAnswer || editingOptionId === option.answer_id ) ? "2px" : "1px", '&:hover': { borderColor: !editMode ? palette.common.font : palette.secondary.light, boxShadow: shadows[5] } }} marginY={editMode && "10px"} marginX={editMode && "-5px"} overflow="hidden" border="1px solid #d3d3d3" borderColor={editingOptionId === option.answer_id ? palette.secondary.light : isCorrectAnswer && "success.main"} >
            <Box bgcolor={palette.greyedOut} borderBottom={border[1]} padding="5px 10px" justifyContent="space-between" display="flex" gap="5px" alignItems="center">
                <Box display="flex" gap="10px" alignItems="center">
                    <Tooltip placement='right' title={option.answer_status ? values.selectionType === "single" ? "Marked as correct" : "Mark as incorrect" : "Mark as correct answer"}>
                        {isCorrectAnswer ?
                            values.selectionType === 'single' ? <span>
                                <Button sx={{ padding: "5px" }} disabled>
                                    <Typography sx={{ textTransform: "capitalize" }} variant='subtitle2' display="flex" alignItems="center" gap="5px" color="success.main"> {values.selectionType === 'single' ? Icons.default.CheckCircleIcon : Icons.default.CheckboxChecked} Marked as correct</Typography>
                                </Button>
                            </span> : <Button color='success' sx={{ textTransform: "capitalize" }} startIcon={values.selectionType === 'single' ? Icons.default.RadioUnchecked : Icons.default.CheckboxChecked} onClick={() => changeAnswerStatus( false )} >Marked as correct</Button> :
                            <Button color='greyed' sx={{ textTransform: "capitalize" }} startIcon={values.selectionType === 'single' ? Icons.default.RadioUnchecked : Icons.default.Checkbox} onClick={() => changeAnswerStatus( true )} >Mark as correct</Button>
                        }
                    </Tooltip>
                    {saving && <Typography fontSize="10px"><CircularProgress size={10} /> Saving...</Typography>}
                </Box>
                {optionsCount > 2 && <Box display="flex" justifyContent="flex-end" gap="20px">
                    <Button color="errorMessage" disabled={isCorrectAnswer || removing} size="small" startIcon={removing ? <CircularProgress size={14} /> : Icons.default.RemoveIcon} sx={{ textTransform: "capitalize" }} onClick={remove}>Remove option</Button>
                </Box>}
            </Box>
            <Box onClick={() => setEditingOptionId( option.answer_id )} bgcolor={palette.form.inputBg} color={palette.common.font} >
                {option && option.answer_text && <CustomWysiwyg autoFocus={newOptionAdded} onFocusSelectAll={newOptionAdded} hideTools={option.answer_id !== editingOptionId} borderless placeholder='Type the option text here...' modules={modules} value={editValue} onChange={( val, text ) => hadleOptionValueChange( val, text )} />}
            </Box>
            {option.answer_id === editingOptionId && editValue.match( /\$/ ) && <Box marginTop="10px" className='discussion-wysiwyg-container' bgcolor={palette.greyedOut} padding="10px" margin="5px" border={border[1]} borderRadius="10px">
                <Latex delimiters={latexDelimiters} >{editValue}</Latex>
            </Box>}
        </Box>
    )
}

const EditQuestionDialog = ( { module = "admission-exam", question, title, state, setState, getQuestions } ) => {

    const [error, setError] = useState( null )
    const [saving, setSaving] = useState( false )
    const [loading, setLoading] = useState( true )
    const [correctAnswers, setCorrectAnswers] = useState( [] )
    const [editingOptionId, setEditingOptionId] = useState( '' )
    const [isAudiosLoading, setIsAudiosLoading] = useState( true )
    const [newOptionAdded, setNewOptionAdded] = useState( false )
    const [uploadingAttachments, setUploadingAttachments] = useState( false )
    const [selectedAudioFiles, setSelectedAudioFiles] = useState( [] )
    const [audioFilesSignedUrls, setAudioFilesSignedUrls] = useState( [] )
    const [audioRecorderStatus, setAudioRecorderStatus] = useState( false )
    const [questionOptionsDetails, setQuestionOptionsDetails] = useState( [] )
    const [questionDetails, setQuestionDetails] = useState( { loaded: false } )

    const { palette, border } = useTheme()
    const { id } = useParams()

    const previousValues = useRef( { question_text: question.question_text } )


    const handleClose = async () => {
        getQuestions()
        setState( false )
    }

    const editQuestion = async ( values ) => {
        setSaving( true )
        try {
            module === 'admission-exam' ? await api.admissionExams.updateQuestion( id, question.question_id, values ) : await api.updateQuestion( id, question.question_id, values )
            previousValues.current = values
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't save changes! try again." )
        } finally {
            setSaving( false )
        }
    }

    const saveQuestionDetails = debounce( editQuestion, 1000 )

    const handleValueChange = async ( field, value, updateEligible ) => {
        if ( updateEligible ) {
            let newDetails
            setQuestionDetails( prev => {
                const newValues = { ...prev }
                newValues[field] = value
                newDetails = newValues
                saveQuestionDetails( newDetails )
                return newValues
            } )
        }
    }

    const addNewOption = async () => {
        try {
            const { data: { data } } = module === "admission-exam" ? await api.admissionExams.addNewOptionToExamQuestion( id, questionDetails.question_id, { option_text: `<i>Option ${questionOptionsDetails.length + 1}</i>` } ) : await api.addNewOptionToExamQuestion( id, questionDetails.question_id, { option_text: `<i>Option ${questionOptionsDetails.length + 1}</i>` } )
            await getQuestionDetails()
            setEditingOptionId( data.answer_id )
            setNewOptionAdded( true )
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't save changes! try again." )
        }
    }

    const removeAudioAttachment = async ( key ) => {
        try {
            setSaving( true )
            const updatedData = { updateType: "removeAttachment", attachmentKey: key }
            await api.updateQuestion( question.exam_id, question.question_id, updatedData )
            await getQuestionDetails()
        } catch ( err ) {
            toast( err?.response?.data?.message || "Couldn't save changes! try again." )
        } finally {
            setSaving( false )
        }
    }

    const getQuestionDetails = useCallback( async () => {
        try {
            const { data: { data } } = module !== "admission-exam" ? await api.fetchQuestionDetails( question.exam_id, question.question_id ) : await api.admissionExams.fetchQuestionDetails( question.exam_id, question.question_id )
            const details = {}
            details.loaded = true
            details.question = data.question_text
            details.audio_attachments = data.audio_attachments || []
            details.audio_attachments_keys = data.audio_attachments_keys || []
            details.question_id = data.question_id
            details.marks = data.marks || 1
            details.section = data.question_section
            details.question_type = data.question_type
            details.question_hint = data.question_hint
            details.question_answer_selection = data.question_answer_selection
            details.selectionType = data.question_answer_selection
            console.log( details )

            let signedUrlsList = []
            for ( let i = 0; i < details?.audio_attachments_keys.length; i++ ) {
                const key = details.audio_attachments_keys[i]
                const { data: signedUrl } = await api.getAudioPresignedUrl( details.exam_id, details.question_id, { key } )
                signedUrlsList.push( signedUrl )
            }
            setAudioFilesSignedUrls( signedUrlsList )

            setQuestionDetails( details )

            setQuestionOptionsDetails( data.options )
            setCorrectAnswers( data.options?.filter( o => o.answer_status === 1 ).map( o => o.answer_id ) )
        } catch ( err ) {
            console.log( err )
            setError( { message: err?.response?.data?.message || "Couldn't fetch question! try again.", code: err?.reponse?.status } )
        } finally {
            setLoading( false )
            setIsAudiosLoading( false )
        }
    }, [module, question] )

    useEffect( () => {
        const uploadAudioAttachment = async () => {
            try {
                setUploadingAttachments( true )
                const formData = new FormData()
                formData.append( 'updateType', 'addAttachment' )
                for ( const file of selectedAudioFiles ) {
                    formData.append( 'audioattachment[]', file )
                }
                await api.updateQuestion( question.exam_id, question.question_id, formData )
                await getQuestionDetails()
            } catch ( err ) {
                toast( err?.response?.data?.message || "Couldn't save changes! try again." )
            } finally {
                setUploadingAttachments( false )
                setSelectedAudioFiles( [] )
            }
        }
        if ( selectedAudioFiles.length > 0 )
            uploadAudioAttachment()
    }, [selectedAudioFiles, getQuestionDetails, question] )

    useEffect( () => {
        getQuestionDetails()
    }, [getQuestionDetails] )

    return (
        <Dialog PaperProps={{ sx: { width: "90%", maxWidth: "950px", background: palette.form.formCardBg } }} open={state}>
            <Box>
                <Box sx={{ zIndex: "10" }} borderBottom={border[1]} position="sticky" top="0" bgcolor={palette.form.formCardBg} display="flex" gap="20px" alignItems="center" padding="20px">
                    <Box flexGrow={1}>
                        <Typography variant="h6" fontSize="18px" noWrap width="50vw">{title || `Edit Question: ${convert( question.question_text )}`}</Typography>
                        {saving ? <Typography fontSize="12px">Saving...</Typography> : <Typography fontSize="12px">Saved</Typography>}
                    </Box>
                    <IconButton onClick={handleClose} size="small">{Icons.default.CloseIcon}</IconButton>
                </Box>
                <DialogContent sx={{ overflow: "auto" }}>
                    {!loading && !error && questionDetails.loaded &&
                        <Box border={border[1]} padding="20px" borderRadius="5px" bgcolor={palette.contentBg} >
                            <FormControl margin="dense" fullWidth>
                                <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question Text *</Typography>
                                <Box bgcolor={palette.form.inputBg}>
                                    <CustomWysiwyg
                                        modules={modules}
                                        placeholder={'Type here...'}
                                        value={questionDetails.question?.replace( /$/g, "" )}
                                        onChange={val => handleValueChange( 'question', val, convert( val ).trim() !== '' && convert( val ) !== convert( previousValues?.current?.question_text ) )}
                                    />
                                </Box>
                                <Typography fontSize="12px" color='textSecondary'>To Add mathematical formula, use latex, Eg: <strong> <em>${"{LATEX}"}$</em> </strong></Typography>
                                {questionDetails.question?.match( /\$/ ) && <Box bgcolor={palette.greyedOut} marginTop="10px" className='discussion-wysiwyg-container' padding="10px" border={border[1]} borderRadius="10px">
                                    <Latex delimiters={latexDelimiters} >{questionDetails.question}</Latex>
                                </Box>}
                            </FormControl>

                            {module !== 'admission-exam' && questionDetails?.audio_attachments.length > 0 && !isAudiosLoading && <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center" padding="10px 0">
                                {questionDetails?.audio_attachments.map( ( attachment, index ) => (
                                    <Box border="1px solid #d3d3d3" bgcolor={palette.common.bg} display="flex" alignItems="center" key={index}>
                                        <AudioPlayer audioName={attachment} index={index} audioSrc={audioFilesSignedUrls[index].data} />
                                        <Tooltip title="Remove this attachment">
                                            <IconButton onClick={() => removeAudioAttachment( questionDetails?.audio_attachments_keys[index] )} color="error" size="small">
                                                <CancelOutlined fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                ) )}
                            </Box>}
                            {module !== 'admission-exam' && questionDetails?.audio_attachments.length === 0 && !isAudiosLoading && !uploadingAttachments && <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center" padding="10px 0">
                                {!audioRecorderStatus && <Button color="secondary" onClick={() => setAudioRecorderStatus( !audioRecorderStatus )} sx={{ fontSize: "12px", textTransform: "capitalize" }} size="small" startIcon={<Mic sx={{ fontSize: "14px" }} />}>Add audio attachment</Button>}
                                {audioRecorderStatus &&
                                    <FormControl margin='normal' fullWidth>
                                        <FormLabel sx={{ fontSize: "14px", marginBottom: "10px" }}>Question audio attachment</FormLabel>
                                        <AudioInput maxSize={5} container="bordered-box" setRecordedCardStatus={setAudioRecorderStatus} selectedAudioFiles={selectedAudioFiles} setSelectedAudioFiles={setSelectedAudioFiles} maxLength={60} />
                                    </FormControl>
                                }
                            </Box>}
                            {uploadingAttachments && <DataLoadingSpinner waitingMessage="Uploading audio file..." />}

                            <FormControl margin="dense" fullWidth>
                                <Typography variant='subtitle2' color={palette.labelColor} gutterBottom>Question marks *</Typography>
                                <TextField onChange={e => handleValueChange( 'marks', e.target.value, true )} size="small" placeholder="Specify question's points" type="number" value={questionDetails.marks} name="marks" inputProps={{ min: 1, step: 1 }} onWheel={e => { e.target.blur() }} />
                            </FormControl>
                            {questionOptionsDetails.length > 0 && <Box>
                                <Box display="flex" gap="20px" marginTop="20px" marginBottom="5px" alignItems="center" color="secondary">
                                    <Typography gutterBottom sx={{ color: palette.labelColor }} variant="subtitle2">Options</Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" gap="10px">
                                    {correctAnswers && questionOptionsDetails.map( ( option, optionIndex ) => (
                                        <OptionComponent newOptionAdded={newOptionAdded} setNewOptionAdded={setNewOptionAdded} optionsCount={questionOptionsDetails.length} key={option.answer_id} setCorrectAnswers={setCorrectAnswers} correctAnswers={correctAnswers} editingOptionId={editingOptionId} setEditingOptionId={setEditingOptionId} values={questionDetails} module={module} getQuestionDetails={getQuestionDetails} option={option} />
                                    ) )}
                                </Box>
                            </Box>}

                            <Box display="flex" alignItems="flex-start" gap="5px" maxWidth="900px" onClick={addNewOption} overflow='hidden' marginTop="20px" position="relative">
                                <Box position="absolute" borderRadius="5px" top="0" bottom="0" right="0" left="0" bgcolor="rgba(250,250,250,0.6)" sx={{ cursor: "pointer", zIndex: 10, '&:hover': { background: "rgba(250,250,250,0.2)" } }}></Box>
                                <Box textAlign="right">
                                    {questionDetails.question_answer_selection === 'single' ? <Radio checked={false} />
                                        : <Checkbox checked={false} />}
                                </Box>
                                <CustomWysiwyg
                                    styles={{ position: "relative", zIndex: 1 }}
                                    key={"read-only-adder"}
                                    placeholder="Add new option"
                                    height='50px'
                                    value=""
                                    readOnly
                                />
                            </Box>
                        </Box>
                    }
                    {loading && !error && <DataLoadingSpinner waitingMessage="Loading question details..." />}
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default EditQuestionDialog
