import { Box, Drawer, Button, Chip, CircularProgress, Collapse, FormControl, FormHelperText, TextField, Typography, Paper, Select, MenuItem, useTheme, Popover, IconButton } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import FileInput from '../../components/study-material-components/FileInput'
import BreadcrumbList from '../../components/common/BreadcrumbList'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useStudyMaterialStore } from '../../store/studymaterial.store'
import { useUserStore } from '../../store/user.store'
import { Icons, flatColors, convertToCDNLink, attachmentThumbnails, getFileSizeFromBytes } from '../../utils/utilities'
import DocumentViewer from '../../components/common/DocumentViewer'
import { useSettingsStore } from '../../store/settings.store'
import { Download, Visibility } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import DataLoadingSpinner from '../../components/common/DataLoadingSpinner'

const AddMaterial = () => {

    const [tags, setTags] = useState( [] )
    const [selectedAttachments, setSelectedAttachments] = useState( [] )
    const [isAddingMaterial, setIsAddingMaterial] = useState( false )
    const [isLoadingSubjects, setIsLoadingSubjects] = useState( true )
    const [availableSubjects, setAvailableSubjects] = useState( [] )
    const [attachmentsNames, setAttachmentsNames] = useState( [] )
    const [attachmentsSizes, setAttachmentsSizes] = useState( [] )
    const [progress, setProgress] = useState( 0 )
    const [isDialogOpen, setIsDialogOpen] = useState( false )
    const [documentUrl, setDocumentUrl] = useState( null )
    const [documentType, setDocumentType] = useState( null )
    const [filename, setfilename] = useState( null )
    const [anchorEl, setAnchorEl] = useState( null )
    const [formatFiles, setFormatFiles] = useState( [] )
    const [subjectFetchError, setSubjectFetchError] = useState( false )

    const navigate = useNavigate()

    const StudyMaterialStore = useStudyMaterialStore()
    const SettingsStore = useSettingsStore()
    const UserStore = useUserStore()

    const { palette, border } = useTheme()

    const initialValues = {
        materialName: '',
        materialDesc: '',
        tags: '',
        subject: '-',
        attachments: null
    }

    const validationSchema = Yup.object().shape( {
        materialName: Yup.string().required( "Material name is required" ),
        subject: Yup.string().required( "Subject is required" ).notOneOf( ["-"], "Please select related subject" ),
        tags: Yup.mixed().required( "Tags is required" ).test( "TAGS", "Please add atleast one tag", ( val ) => {
            return val && val.length > 0
        } )
    } )

    const deleteTag = ( index ) => {
        const t = [...tags]
        t.splice( index, 1 )
        setTags( t )
    }

    const handleKeyDown = ( e, setFieldValue ) => {
        if ( e.code === "Enter" ) {
            e.preventDefault()
            if ( e.target.value.trim() !== '' ) {
                let newTags = e.target.value.split( "," )
                let filteredTags = []
                newTags.forEach( tag => {
                    if ( tag.trim() !== '' ) filteredTags.push( tag )
                } )
                setTags( [...tags, ...filteredTags] )
                setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
                e.target.value = ""
            }
        }
    }

    const progressHandler = ( event ) => {
        const { loaded, total } = event
        let percent = Math.round( ( loaded * 100 ) / total )
        console.log( percent )
        if ( percent <= 100 ) {
            setProgress( percent )
        }
    }

    const handleBlur = ( e, setFieldValue ) => {
        if ( e.target.value.trim() !== '' ) {
            let newTags = e.target.value.split( "," )
            let filteredTags = []
            newTags.forEach( tag => {
                if ( tag.trim() !== '' ) filteredTags.push( tag )
            } )
            setTags( [...tags, ...filteredTags] )
            setFieldValue( 'tags', [...tags, ...filteredTags].join( "," ) )
            e.target.value = ""
        }
    }

    const addMaterial = async ( values, { resetForm } ) => {
        setIsAddingMaterial( true )
        try {
            const formData = new FormData()
            formData.append( 'name', values.materialName )
            formData.append( 'description', values.materialDesc )
            formData.append( 'tags', values.tags )
            formData.append( 'subject', JSON.stringify( UserStore.getUserSubjects.find( sub => sub.subject_assignment_id === values.subject ) ) )
            let emptyName = false
            for ( let i = 0; i < attachmentsNames.length; i++ ) {
                const name = attachmentsNames[i]
                if ( name === "" ) {
                    emptyName = true
                }
            }
            if ( !emptyName ) {
                if ( values.attachments ) {
                    values.attachments.forEach( ( file, i ) => {
                        const ext = file.name.split( "." ).pop()
                        let fileName = encodeURIComponent( `${file.name.split( ext )[0]}-${Date.now()}${i}.${ext}`.replace( /\s/g, '-' ) )
                        formData.append( 'attachments[]', file, fileName )
                    } )
                }
                formData.append( 'attachmentNames[]', JSON.stringify( attachmentsNames ) )
                formData.append( 'attachmentsSizes[]', JSON.stringify( attachmentsSizes ) )
                await StudyMaterialStore.createMaterial( formData, progressHandler )
                navigate( '/faculty/material' )
            } else {
                toast( "Please provide names for all the attachments" )
            }
        } catch ( e ) {
            console.log( e )
            if ( e?.response?.data?.data?.unuploaded?.length > 0 ) {
                setSelectedAttachments( [] )
                setAttachmentsNames( [] )
                let unuploadedFiles = []
                for ( let i = 0; i < e?.response?.data?.data?.unuploaded.length; i++ ) {
                    const file = e?.response?.data?.data?.unuploaded[i]
                    unuploadedFiles.push( file.originalname.replace( /-(\d)+/g, "" ) )
                }
                const toastMsg = <p>Failed to upload these files: <b><i>{unuploadedFiles.join( ", " )}</i></b> due to unsupported format. </p>
                toast( toastMsg )
            } else
                toast.error( e.response && e.response.data && e.response.data.message )
        } finally {
            setIsAddingMaterial( false )
        }
    }

    const handleOpenPopover = ( event ) => {
        const fileUrlString = SettingsStore.getUseSettings['sample_study_material_format']
        if ( fileUrlString ) {
            const parsedFiles = JSON.parse( fileUrlString )
            setFormatFiles( parsedFiles )
            setAnchorEl( event.currentTarget )
        } else {
            console.error( 'Cannot Retrieve Files' )
        }
    }

    const handleClosePopover = () => {
        setAnchorEl( null )
    }

    const handleViewFile = ( file ) => {
        const cdnLink = convertToCDNLink( file.location )
        setDocumentUrl( cdnLink )
        setDocumentType( file.name.split( '.' ).pop() )
        setfilename( file.name )
        setIsDialogOpen( true )
    }

    const handleDownloadFile = ( file ) => {
        const link = document.createElement( 'a' )
        link.href = file.location
        link.download = file.name
        link.click()
        link.remove()
    }

    const handleCloseDialog = () => {
        setIsDialogOpen( false )
        setDocumentUrl( null )
        setDocumentType( null )
    }

    const getSubjects = useCallback( async () => {
        setIsLoadingSubjects( true )
        const subjectFetched = await UserStore.fetchUserSubjects()
        setSubjectFetchError( !subjectFetched )
        if ( subjectFetched ) {
            await StudyMaterialStore.fetchMaterials()
            const facultyMaterials = StudyMaterialStore.getMaterials["materials"]
            let availableSubjects = []
            for ( let i = 0; i < UserStore.getUserSubjects.length; i++ ) {
                const sub = UserStore.getUserSubjects[i]
                if ( facultyMaterials.findIndex( material => String( material.subject_id ) === String( sub.subject_id ) ) < 0 ) {
                    availableSubjects.push( sub )
                }
            }
            setAvailableSubjects( availableSubjects )
        }
        setIsLoadingSubjects( false )
    }, [UserStore, StudyMaterialStore] )

    useEffect( () => {
        getSubjects()
    }, [getSubjects] )

    return (
        <Box padding="20px" overflow="auto" flexGrow={1}>

            <BreadcrumbList items={[{ link: UserStore.getUser['user_role'] === "STUDENT" ? '/student/material' : '/faculty/material', label: "Study materials" }, "Add new"]} />

            <Paper sx={{ paddingBottom: "1px", overflow: "hidden" }}>
                <Box display="flex" gap="10px" sx={{
                    background: `linear-gradient(233.69deg, #6A6790 -93.75%, #1F1E2A 145.73%)`
                }} alignItems="center" padding="20px">
                    <Box>
                        <Typography variant='h5' color="white" fontWeight="normal">Add new material</Typography>
                        <Typography variant='body2' color="white" >Provide all the required information to create a material.</Typography>
                    </Box>
                </Box>
                <Box margin="2  0px" borderRadius="5px" bgcolor={palette.form.formBg} border={border[1]} padding="20px">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={addMaterial}>
                        {( { setFieldValue, resetForm, values } ) => (
                            <Form>
                                <Box display="flex" sx={{ borderRadius: "5px", background: palette.errorMessage.main, padding: "10px", marginBottom: "20px", alignItems: "flex-start" }}>
                                    <InfoIcon sx={{ marginRight: "5px", marginTop: "5px", color: "white", fontSize: "20px" }} />
                                    <Box padding="5px 0" display="flex" gap="10px" flexDirection="column">
                                        <Typography variant="subtitle2" color="white" sx={{ fontWeight: "bold" }}>
                                            Note: {SettingsStore.getUseSettings['sample_study_material_format_note'] || "The institute has recommended a specific format for creating and uploading Study material, we recommend to do the same"}
                                        </Typography>
                                        {SettingsStore.getUseSettings['sample_study_material_format'] && Array.isArray( JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ) ) && JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ).length > 0 && (
                                            <Box display="flex" gap="5px" alignItems="center" flexWrap="wrap">
                                                {JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ).slice( 0, 2 ).map( ( file, index ) => (
                                                    <Box borderRadius="20px" bgcolor={"white"} key={index} gap="10px" padding='5px' sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <img style={{ objectFit: "cover" }} width="35px" src={attachmentThumbnails[file.name?.split( "." ).pop()] ? attachmentThumbnails[file.name?.split( "." ).pop()] : attachmentThumbnails["default"]} alt={file.type} />
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <Typography variant="subtitle2" color="black" fontSize="12px">{file.name}</Typography>
                                                            <Typography variant="subtitle2" color="black" fontSize="10px">
                                                                {getFileSizeFromBytes( file.size )}
                                                            </Typography>
                                                        </Box>
                                                        <IconButton color="black" sx={{ color: "black" }} size='small' onClick={() => handleViewFile( file )}>
                                                            <Visibility sx={{ fontSize: "18px" }} />
                                                        </IconButton>
                                                        <IconButton color="black" sx={{ color: "black" }} size='small' onClick={() => handleDownloadFile( file )}>
                                                            <Download sx={{ fontSize: "18px" }} />
                                                        </IconButton>
                                                    </Box>
                                                ) )}
                                                {JSON.parse( SettingsStore.getUseSettings['sample_study_material_format'] ).length > 2 && <Button endIcon={Icons.default.KeyboardArrowDownIcon} sx={{ width: "fit-content", color: "white", fontWeight: "400", textTransform: "capitalize" }}
                                                    onClick={handleOpenPopover}>
                                                    View more
                                                </Button>}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <FormControl fullWidth margin="normal" sx={{ marginTop: "1px" }}>

                                    <label htmlFor="name">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Material name *</Typography>
                                    </label>

                                    <Field size="small" placeholder="Eg: New material for DS" id="name" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} fullWidth type="text" name="materialName" />
                                    <FormHelperText sx={{ color: "errorMessage.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="materialName" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal" >
                                    <label htmlFor="desc">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Description *</Typography>
                                    </label>
                                    <Field size="small" placeholder="Description goes here..." id="desc" as={TextField} InputLabelProps={{ sx: { fontSize: "14px !important" } }} InputProps={{ sx: { fontSize: "14px !important" } }} multiline rows={5} fullWidth type="text" name="materialDesc" />
                                    <FormHelperText> <ErrorMessage name="materialDesc" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <label htmlFor="subject">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Material Subject *</Typography>
                                    </label>
                                    <Field size="small" as={Select} id="subject" disabled={isLoadingSubjects} name='subject' sx={{ color: values.subject === "-" && palette.form.placeholder, "&:disabled": { color: "rgba(0,0,0,0.4)" } }} >
                                        <MenuItem sx={{ fontSize: "14px" }} value="-" disabled>{isLoadingSubjects ? <Typography display="flex" alignItems="center" gap="5px" fontSize="14px" > <CircularProgress size={14} />Loading subjects... </Typography> : "Select subject"}</MenuItem>
                                        {!isLoadingSubjects && availableSubjects.map( sub => (
                                            <MenuItem key={sub.subject_assignment_id} sx={{ fontSize: "14px" }} value={sub.subject_assignment_id}>{`${sub.subject_name}-${sub.subject_code} (${sub.ac_year})`}</MenuItem>
                                        ) )}
                                    </Field>
                                    {subjectFetchError && <Box bgcolor={palette.errorMessage.light + "22"} marginTop="10px" borderRadius="5px" padding="5px 10px" border={`1px dotted ${palette.errorMessage.main}`} display="flex" alignItems="center" gap="5px" fontSize="12px" color="textSecondary">
                                        <Typography color="errorMessage.main" variant='subtitle2'>Failed to fetch <strong><em>subjects</em></strong> from ERP...</Typography>
                                        <Button color="errorMessage" sx={{ textTransform: "capitalize" }} onClick={() => getSubjects()} endIcon={isLoadingSubjects ? <CircularProgress size={14} /> : Icons.default.Replay}>Retry</Button>
                                    </Box>}
                                    {isLoadingSubjects && !subjectFetchError && <Typography marginTop="5px" display="flex" alignItems="center" gap="5px" fontSize="12px" color="textSecondary"> <DataLoadingSpinner padding='0' size={14} /> {`Fetching your subjects from ERP...`} </Typography>}

                                    <Typography marginTop="5px" fontSize="12px">If subject not displayed in the dropdown then you already have a material for that subject</Typography>
                                    <FormHelperText sx={{ color: "errorMessage.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="subject" /> </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth>
                                    <label htmlFor="tags">
                                        <Typography variant="subtitle2" color={palette.labelColor} gutterBottom>Add Tags *{`(Type tags as comma seperated values)`}</Typography>
                                    </label>
                                    <Box >
                                        <TextField id="tags" size="small" type="text" placeholder='Add new tag' fullWidth onBlur={( e ) => { handleBlur( e, setFieldValue ) }} onKeyDown={e => { handleKeyDown( e, setFieldValue ) }} />
                                        <FormHelperText sx={{ color: "errorMessage.main", fontWeight: "bold", marginLeft: 0 }}> <ErrorMessage name="tags" /> </FormHelperText>
                                        <Collapse in={tags.length > 0}>
                                            <Typography marginTop="10px" variant='subtitle2' gutterBottom color="textSecondary">Added tags  </Typography>
                                            <Box display="flex" alignItems="center" gap="10px">
                                                {tags.length > 0 && tags.map( ( tag, index ) => {
                                                    const color = flatColors[index % flatColors.length]
                                                    return (
                                                        <Chip deleteIcon={Icons.default.CloseIcon} key={index} sx={{ borderRadius: "20px", border: `2px solid ${color}`, background: color + "22", color }} label={tag} onDelete={() => deleteTag( index, setFieldValue )} size="medium" />
                                                    )
                                                } )}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </FormControl>
                                <Box marginTop="20px">
                                    <FileInput attachmentsSizes={attachmentsSizes} setAttachmentsSizes={setAttachmentsSizes} progress={progress} size={60} isUploading={isAddingMaterial} selectedAttachments={selectedAttachments} setSelectedAttachments={setSelectedAttachments} setFieldValue={setFieldValue} attachmentsNames={attachmentsNames} setAttachmentsNames={setAttachmentsNames} />
                                </Box>
                                <Box marginTop="60px" alignItems="center" gap="20px" display="flex">
                                    <Button disabled={isAddingMaterial} type="submit" variant='contained' sx={{ textTransform: "capitalize" }} disableElevation>{isAddingMaterial ? <Typography gap="10px" display="flex" alignItems="center"> <CircularProgress size={14} /> Adding Material please wait...</Typography> : `Add new material`}</Button>
                                    <Button disableElevation variant='contained' onClick={() => navigate( '/faculty/material' )} color='error' sx={{ textTransform: "capitalize" }}>Cancel</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Paper>

            <Popover open={Boolean( anchorEl )} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }}>
                <Box sx={{ maxWidth: 400 }}>
                    {formatFiles.slice( 2 ).map( ( file, index ) => (
                        <Box borderBottom={border[1]} key={index} gap="10px" padding='5px' sx={{ display: 'flex', alignItems: 'center' }}>
                            <img style={{ objectFit: "cover" }} width="45px" src={attachmentThumbnails[file.name?.split( "." ).pop()] ? attachmentThumbnails[file.name?.split( "." ).pop()] : attachmentThumbnails["default"]} alt={file.type} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle2" fontSize="12px">{file.name}</Typography>
                                <Typography variant="subtitle2" fontSize="10px" color="text.secondary">
                                    {getFileSizeFromBytes( file.size )}
                                </Typography>
                            </Box>
                            <IconButton onClick={() => handleViewFile( file )}>
                                <Visibility />
                            </IconButton>
                            <IconButton onClick={() => handleDownloadFile( file )}>
                                <Download />
                            </IconButton>
                        </Box>
                    ) )}
                </Box>
            </Popover>

            <Drawer anchor='bottom' PaperProps={{ sx: { height: "calc(100% - 50px)" } }} sx={{ zIndex: 9000000 }} open={isDialogOpen}>
                <Box bgcolor="background.paper" flexGrow={1} display="flex" overflow="auto" flexDirection="column" borderRadius="20px 20px 0 0">
                    <Box borderBottom={1} alignItems="center" display="flex" justifyContent="space-between" gap="20px" padding="10px 20px">
                        <Typography variant='h6'>{filename}</Typography>
                        <Box display="flex" gap="10px" alignItems="center">
                            <IconButton onClick={handleCloseDialog}>
                                {Icons.default.CloseIcon}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" overflow="auto" flexGrow={1} padding="20px" height="300px">
                        <DocumentViewer type={documentType} loadingText="Loading material..." file={documentUrl} />
                    </Box>
                </Box>
            </Drawer>
        </Box>
    )
}

export default AddMaterial