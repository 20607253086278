import { Box, Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useRoomStore } from '../../store/room.store'
import { useUserStore } from '../../store/user.store'
import api from '../../service/api'
import DataLoadingSpinner from '../common/DataLoadingSpinner'
import { calendarColors } from '../../utils/utilities'

const timelineHeight = 300

const CalendarDateColumn = observer( ( { day, quizzesData, dateDetails, setClassDetailsAnchor, setSelectedRoom, isToday, isFuture } ) => {

    const [loading, setLoading] = useState( true )
    const [classes, setClasses] = useState( [] )

    const RoomStore = useRoomStore()
    const UserStore = useUserStore()

    const { palette, theme, shadows } = useTheme()

    const dateBoxDesigns = {
        bg: isToday ? calendarColors.todayCalendarDateColor : "none",
        border: `2px solid ${isToday ? calendarColors.todayCalendarDateColor : "#ADADAD"}`,
        color: isToday ? "#fff" : isFuture ? palette.common.font : theme === 'dark' ? "whitesmoke" : "GrayText"
    }

    const getClassBoxDesigns = c => {
        const designs = {}

        designs.border = isFuture ? c.online === '0' ? `1px solid ${calendarColors.offlineDark}` : `1px solid ${calendarColors.onlineDark}` : "none"
        designs.opacity = ( isFuture || isToday ) ? 1 : 0.4
        designs.boxShadow = ( isFuture || isToday ) ? shadows[3] : shadows[0]
        designs.boxBorderRadius = isFuture ? "0" : "0 0 10px 10px"
        designs.bg = isFuture ? palette.common.bg : isToday ? c.online === '0' ? calendarColors.offlineDark : calendarColors.onlineDark : "grey"
        designs.color = isFuture ? c.online === '0' ? calendarColors.offlineDark : calendarColors.onlineDark : isToday ? "#fff" : "#333"
        designs.timeBg = ( isFuture || isToday ) ? c.online === '0' ? calendarColors.offlineLight : calendarColors.onlineLight : "#ccc"

        return designs
    }

    useEffect( () => {
        const getData = async () => {
            setLoading( true )
            setClasses( [] )
            try {
                let { data: { data } } = await api.getClassesByDate( dateDetails.erp_date )
                // if ( UserStore.getUser.user_role === 'STUDENT' )
                for ( let i = 0; i < data.length; i++ ) {
                    const room = data[i]
                    room.quizzes = quizzesData.filter( q => q.class_id === room.time_table_id )
                }
                setClasses( data )
            } catch ( err ) {
                console.log( "Error while fetching " + dateDetails.complete_date + " data" )
            } finally {
                setLoading( false )
            }
        }
        getData()
    }, [dateDetails, UserStore, quizzesData] )

    return (
        <Box overflow="auto" width="calc((100% - 60px) / 7)" display="flex" flexDirection="column" >
            <Box
                padding="5px 10px"
                borderRadius="10px 10px 0 0"
                bgcolor={dateBoxDesigns.bg}
                border={dateBoxDesigns.border}
            >
                <Typography fontSize="22px" fontWeight="bold" color={dateBoxDesigns.color}>{dateDetails?.date}</Typography>
                <Typography fontSize="12px" color={dateBoxDesigns.color} fontWeight="500">{day}</Typography>
                <Typography color={dateBoxDesigns.color} fontSize="12px" noWrap fontWeight="400">{( classes?.length || 0 )} classes</Typography>
            </Box>
            <Box className="slim-custom-scrollbar" paddingTop="10px" paddingX="4px" height={timelineHeight + 100} width="calc(100% - 10px)" borderRight="1px solid #E0E0E080" gap="10px" overflow="auto" borderLeft="1px solid #E0E0E080">
                {!loading && classes && classes.map( ( c, tindex ) => {

                    const classBoxDesigns = getClassBoxDesigns( c )
                    return <Box
                        marginBottom="10px"
                        onClick={e => {
                            let does_exists = RoomStore.getActiveRoom.filter( ( item, k ) => {
                                return item.room_session_id === c.time_table_id
                            } ).length

                            setSelectedRoom( { ...c, date: dateDetails?.tense, does_exists } )
                            setClassDetailsAnchor( e.currentTarget )
                        }}
                        position="relative"
                        sx={{
                            cursor: "pointer",
                            opacity: classBoxDesigns.opacity,
                            "&:hover": {
                                boxShadow: classBoxDesigns.boxShadow
                            }
                        }}
                        borderRadius="10px"
                        bgcolor={classBoxDesigns.timeBg}
                        border={classBoxDesigns.border}
                        key={tindex}
                        overflow="hidden"
                    >
                        <Box padding="10px" borderRadius={classBoxDesigns.boxBorderRadius} bgcolor={classBoxDesigns.bg}>
                            <Typography fontWeight="600" fontSize="14px" sx={{ color: classBoxDesigns.color }}>{c.subject_name_short}</Typography>
                            {( c?.presenter_name?.toLowerCase() || c?.empID ) && <Typography fontWeight="600" maxWidth="60px" noWrap fontSize="12px" sx={{ color: classBoxDesigns.color }} textTransform="capitalize">By <em>{c?.presenter_name?.toLowerCase() || c?.empID}</em></Typography>}
                        </Box>
                        <Box padding="5px" bgcolor={classBoxDesigns.timeBg}>
                            <Typography textAlign="center" fontSize="10px" fontWeight="bold" color="black">{c.sTime?.split( /:\d{2}\./ )[0]} - {c.eTime?.split( /:\d{2}\./ )[0]}</Typography>
                        </Box>
                    </Box>
                } )}
                {loading && <Box display="flex" justifyContent="center"> <DataLoadingSpinner size={14} /></Box>}
                {!loading && classes?.length < 1 && <Typography variant="body2" fontSize="10px" textAlign="center">No classes</Typography>}
            </Box>
        </Box>
    )
} )

export default CalendarDateColumn
