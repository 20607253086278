import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import decode from 'jwt-decode'
import api from '../service/api'
import NotificationStore from "./notification.store"
import React from 'react'
// import UserStore from "./user.store";
const notification = NotificationStore



class AuthStore {

    auth // Logged In Json Info
    erp_session_state = 'loggedout'

    constructor () {
        this.auth = ( this.getToken === null ) ? false : !( this.isTokenExpired( this.getToken ) )
        this.api = api
        makeObservable( this, {
            auth: observable,
            getAuth: computed,
            erp_session_state: observable,
            setAuth: action,
            doAuth: action,
            setToken: action,
            getToken: computed,
            doLogout: action,
            isTokenExpired: action,
            getErpSessionState: computed,
            setERPSessionState: action
        } )
    }

    // get compute_func()

    get getAuth() {
        //console.log(this.auth)
        this.auth = ( this.getToken === null ) ? false : !( this.isTokenExpired( this.getToken ) )
        return toJS( this.auth )
    }

    //signInERP
    async doAuth( payload ) {
        try {
            const res = await this.api.signIn( payload )
            console.log( res )
            //this.auth = true;
            this.setToken( res["data"]["token"] )
            if ( res["data"]["success"] === true )
                notification.setNotification( true, "Login Success!" )
            else
                notification.setNotification( true, "Invalid Login Credentials!" )
            //console.log(res["data"]["success"])

            return res["data"]["success"]
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, "Invalid Login Credentials!!!" )
            return false
        }
    }

    async doAuthViaERP( payload, showSuccessToast = true ) {

        try {
            const res = await this.api.signInERP( payload )

            //this.auth = true;
            if ( res["data"]["status"] === true ) {
                this.setToken( res["data"]["token"] )
                if ( showSuccessToast )
                    notification.setNotification( true, res["data"]["message"] )
                this.setERPSessionState( 'active' )
                return res["data"]["status"]
            } else if ( res["data"]["status"] === false ) {
                notification.setNotification( true, res["data"]["message"] )
                return res["data"]["status"]
            } else {
                notification.setNotification( true, res["data"]["message"] )
                return res["data"]["status"]
            }
        } catch ( err ) {
            console.log( err )
            if ( !window.navigator.onLine )
                return notification.setNotification( true, "Please connect to internet!" )
            if ( err.response )
                notification.setNotification( true, err?.response?.data?.message || "Something went wrong. Try again!" )
            else if ( err.message === "Network Error" && !window.navigator.onLine )
                notification.setNotification( true, "Check your internet connection!" )
            else if ( err.message === "Network Error" && window.navigator.onLine )
                notification.setNotification( true, "Server seems to be facing some trouble! please try again." )
            else
                notification.setNotification( true, "Server seems to be facing some problems! Please try again later." )
            return false
        }
    }

    async doStudyMaterialLogin( payload ) {
        try {
            const res = await this.api.signInStudyMaterial( payload )
            localStorage.setItem( "aliveMaterialAPIAK", res.data.token )
            return true
        } catch ( e ) {
            console.log( "ERR1", e )
            notification.setNotification( true, ( e.response && e.response.data ) ? e.response.data : "Something went wrong! try again later" )
            return false
        }
    }
    async doExamLogin( payload ) {
        try {
            const res = await this.api.signInExam( payload )
            localStorage.setItem( "aliveAPIAK", res.data.token )
            return true
        } catch ( e ) {
            console.log( "ERR2", e )
            notification.setNotification( true, ( e.response && e.response.data && e.response.data.message ) ? e.response.data.message : "Something went wrong! try again later" )
            return false
        }
    }

    setAuth( auth ) {
        this.auth = auth
    }

    setToken( token ) {
        localStorage.setItem( 'id_token', token )
    }

    setERPSessionState( state ) {
        runInAction( () => { this.erp_session_state = state } )
    }

    get getToken() {
        return localStorage.getItem( 'id_token' )
    }

    async doLogout() {
        localStorage.removeItem( 'id_token' )
        localStorage.removeItem( 'poll_session' )
        localStorage.removeItem( 'active_session' )
        this.setAuth( false )
        try {
            await api.logout()
        } catch ( err ) {
            console.log( err )
        }
        return true
    }

    get getErpSessionState() {
        return this.erp_session_state
    }

    isTokenExpired( token ) {
        try {
            const decoded = decode( token )
            if ( decoded.exp < Date.now() / 1000 ) { // Checking if token is expired. N
                this.setAuth( false )
                return true
            }
            else {
                this.setAuth( true )
                return false
            }
            //return false;
        }
        catch ( err ) {
            return false
        }
    }
}

// decorate(AuthStore, {
//     auth:observable,
//     getAuth:computed,
//     setAuth:action,
//     doAuth:action,
//     setToken:action,
//     getToken:computed,
//     doLogout:action,
//     isTokenExpired:action
// })

const AuthStoreInstance = new AuthStore()

export default AuthStoreInstance

const AuthStoreContext = React.createContext()

export const AuthStoreProvider = ( { children, store } ) => {
    return (
        <AuthStoreContext.Provider value={store}>{children}</AuthStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useAuthStore = () => React.useContext( AuthStoreContext )